
  import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const LEAD_GET_URL = BASE_URL + "/lead";
const LEAD_DELETE_URL = BASE_URL +"/lead/";
const LEAD_ADD_URL = BASE_URL +"/leads/";
const LEAD_EDIT_URL = BASE_URL +"/leads/";


export const getLead = (searchParams?: string) => {
  return axios.get(LEAD_GET_URL + "?" + searchParams);
};

export const deleteLead = (Id: string) => {
  return axios.delete(LEAD_DELETE_URL +Id);
};

// export const addLead = (Id: string) => {
//     return axios.post(LEAD_ADD_URL + Id);
//   };

//   export const editLead = (payload:any,Id: string) => {
//     return axios.patch(LEAD_EDIT_URL + Id,payload);
//   };


  