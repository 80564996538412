
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import SettingPage from "../generatedTablePage/SettingPage";
  import ModalComponent from "../components/Modal";
  import AddSetting from "./AddSettings";
import EditSetting from "./EditSettings";

  
  
  const SettingRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<SettingPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditSetting/>
    }
  />
  <Route
    path="/add"
    element={
      <AddSetting/>
      
    }
  />      
         
      </Routes>
    );
  };
  export default SettingRoutes
