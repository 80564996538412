import React, { useCallback, useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import "./CustomStepper.css";
import { getQuiz } from "../../generatedRequest/quiz";
import { toast } from "react-toastify";
import AccessoryType from "./Steps/AccessoryType";
import { Formik, useFormik, useFormikContext } from "formik";
import CompatibleProducts from "./Steps/CompatibleProducts";
import QuestionsComponent from "./Steps/QuestionsComponent";

const initialValues = {
  accessoryType: "",
};

const CustomStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(3);
  const [loading, setLoading] = useState(false);
  const [accessoryType, setAccessoryType] = useState([]);
  const [stepContent, setStepContent] = useState<any>([]);

  const handleSteps = (temp10: any) => {
    setCurrentStep(temp10 + 1);
  };

  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      try {
        const response = await getQuiz();
        if (response.data.status) {
          setAccessoryType(response?.data?.data?.data);
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setLoading(false);
      }
    };
    fn();
  }, []);

  useEffect(() => {
    setStepContent([
      <AccessoryType
        //formik={formik}
        accessoryType={accessoryType}
        submitStep={(index: any, questionList: any) =>
          submitStep(index, questionList)
        }
        stepNumber={0}
      />,
      <CompatibleProducts />,
    ]);
  }, [accessoryType]);

  // Initialize formik using useFormik hook
  const formik = useFormik({
    initialValues,
    //validationSchema: currentSchema,
    onSubmit: () => {},
  });

  const submitStep = (temp9: any, questionList?: any) => {
    if (temp9 === 0) {
      let temp = [
        <AccessoryType
          //formik={formik}
          accessoryType={accessoryType}
          submitStep={(index: any, questionList: any) =>
            submitStep(index, questionList)
          }
          stepNumber={0}
        />,
        <CompatibleProducts />,
      ];
      let temp2 = questionList?.map((question: any, index: any) => {
        return (
          <QuestionsComponent
            submitStep={submitStep}
            //formik={formik}
            questions={question}
            stepNumber={index + 1}
          />
        );
      });
      temp.splice(1, 0, ...temp2);
      setStepContent(temp);

      handleSteps(temp9);
    } else {
      handleSteps(temp9);
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      <div>
        <Stepper activeStep={currentStep}>
          {stepContent.map((step: any, index: number) => {
            let stepLabel = "";

            if (index === 0) {
              stepLabel = "Device Type";
            } else if (index === stepContent.length - 1) {
              stepLabel = "Results";
            } else {
              stepLabel = `${step?.props?.questions?.attributeName}`;
            }
            return <Step label={stepLabel}>Step {index + 1} </Step>;
          })}
        </Stepper>
        {stepContent[currentStep]}
        <button
          onClick={handlePrevious}
          className="btn btn-primary"
          disabled={currentStep === 0}
        >
          Back
        </button>
      </div>
    </Formik>
  );
};

export default CustomStepper;
