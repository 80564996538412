import axios from "axios";
import { TPayloadSubmit } from "../../types/Module/Unit/Unit";

const BASE_URL = process.env.REACT_APP_API_URL;
const UNIT_GET_URL = BASE_URL + "/units";
const DELETE_UNIT_URL = BASE_URL + "/units/";
const UNIT_ADD_URL = BASE_URL + "/units";
const UNIT_GET_ALL_URL = BASE_URL + "/units/all";

export const getUnit = (searchParams?: string) => {
  return axios.get(UNIT_GET_URL + `?` + searchParams);
};

export const getAllUnit = (searchParams?: string) => {
  return axios.get(UNIT_GET_ALL_URL);
};

export const getSingleUnit = (searchParams: string) => {
  return axios.get(UNIT_GET_URL + "/" + searchParams);
};

export const deleteUnit = (language: string) => {
  return axios.delete(DELETE_UNIT_URL + language);
};

export const updateStatus = (unit: { id: string; status: string }) => {
  const url = UNIT_ADD_URL + "/" + unit.id;
  const newStatus = unit.status === "active" ? "inactive" : "active";
  const payload = {
    status: newStatus,
  };

  if (unit.id) {
    return axios.patch(url, payload);
  }
};

export const createUnit = (payload: TPayloadSubmit) => {
  return axios.post(UNIT_ADD_URL, payload);
};

export const updateUnit = (payload: TPayloadSubmit, id: string) => {
  const url = UNIT_ADD_URL + "/" + id;
  return axios.patch(url, payload);
};
