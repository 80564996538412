import { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import StaticMap, { Marker } from "react-map-gl";
import maplibregl from "maplibre-gl";
import "./regionMap.css";

import "maplibre-gl/dist/maplibre-gl.css";

export const CustomMap = ({
  onMapClick,
  initialCoordinates,
  isEditMode,
}: {
  onMapClick: (coordinates: {
    latitude: string;
    longitude: string;
    zoom: number;
  }) => void;
  initialCoordinates?: {
    latitude: string | number;
    longitude: string | number;
  };
  isEditMode?: boolean;
}) => {
  const [viewState, setViewState] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 1,
  });
  const [marker, setMarker] = useState({
    longitude: 0,
    latitude: 0,
  });

  // Set initial coordinates when the component mounts
  useEffect(() => {
    if (initialCoordinates) {
      const initialZoom = isEditMode ? 3 : viewState.zoom ? viewState.zoom : 1 ;
      setViewState({
        longitude: Number(initialCoordinates?.longitude),
        latitude: Number(initialCoordinates?.latitude),
        zoom: initialZoom,
      });
      setMarker({
        longitude: Number(initialCoordinates?.longitude),
        latitude: Number(initialCoordinates?.latitude),
      });
    }
  }, [initialCoordinates, isEditMode]);

  const handleMapClick = (event: any) => {
    const coordinates = {
      longitude: event.coordinate[0],
      latitude: event.coordinate[1],
    };

    // Convert the coordinates to strings
    const latitudeString = coordinates.latitude.toString();
    const longitudeString = coordinates.longitude.toString();
    setMarker({
      longitude: event.coordinate[0],
      latitude: event.coordinate[1],
    });

    // Call the parent component's callback function with the coordinates
    onMapClick({
      latitude: latitudeString,
      longitude: longitudeString,
      zoom: event.viewport.zoom,
    });
  };

  return (
    <div style={{ height: "250px" }}>
      <DeckGL
        style={{ width: "100%", height: "100%" }}
        viewState={viewState}
        onViewStateChange={({ viewState }: any) => setViewState(viewState)}
        controller={true}
        layers={[]}
        onClick={handleMapClick} // Attach the click event handler
      >
        <StaticMap
          mapLib={maplibregl}
          mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        >
          {/* Marker */}
          <Marker
            longitude={marker.longitude}
            latitude={marker.latitude}
            offset={[0, -20]}
          >
            <div style={{ color: "red", fontSize: "20px" }}>📍</div>
          </Marker>
        </StaticMap>
      </DeckGL>
    </div>
  );
};