import { FC, useState } from "react";
import { useIntl } from "react-intl";
import FormView from "../../../components/Forms";
import { PageTitle } from "../../../_metronic/layout/core";

import FormView1 from "../../../components/Forms/index1";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

const Edit: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>Edit User</PageTitle> */}
      {/* <div className="d-flex align-items-center mb-5">
      <span
          className="d-flex cursor-pointer me-3"
          onClick={() => navigate("/users")}
        >
          <i
            style={{ color: "#181c32" }}
            className="fa-solid fa-arrow-left fs-2"
          ></i>
        </span>
        <h2 className="mb-0">Edit User</h2>
      </div> */}
      <FormView1 isEdit={true} url={""} />
    </>
  );
};

export { Edit };
