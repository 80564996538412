import { Modal } from "react-bootstrap";
import { TModalComponentProps } from "../../types/Modal";

const ModalComponent = ({
  role,
  show,
  dataBackdrop,
  id,
  onCancel,
  children,
}: TModalComponentProps) => {
  return (
    <Modal
      className="modal fade"
      id={id}
      data-backdrop={dataBackdrop}
      role={role}
      show={show}
      dialogClassName="modal-md modal-dialog-centered"
      aria-hidden="true"
      onHide={onCancel}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
