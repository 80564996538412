import axios from "axios";
import { TAttributeFormData } from "../types/Module/Attribute/Attribute";

const BASE_URL = process.env.REACT_APP_API_URL;
const ATTRIBUTE_GET_URL = BASE_URL + "/attributes";
const ATTRIBUTE_DELETE_URL = BASE_URL + "/attributes/";
const ATTRIBUTE_ADD_URL = BASE_URL + "/attributes";
const ATTRIBUTE_EDIT_URL = BASE_URL + "/attributes/";

export const getAttribute = (searchParams?: string) => {
  return axios.get(ATTRIBUTE_GET_URL + "?" + searchParams);
};

export const getSingleAttribute = (attributesid: string) => {
  return axios.get(ATTRIBUTE_GET_URL + "/" + attributesid);
};

export const deleteAttribute = (attributesId: string) => {
  return axios.delete(ATTRIBUTE_DELETE_URL + attributesId);
};

export const addAttribute = (payload: TAttributeFormData) => {
  return axios.post(ATTRIBUTE_ADD_URL, payload);
};

export const editAttribute = (
  payload: TAttributeFormData,
  attributesId: string
) => {
  return axios.patch(ATTRIBUTE_EDIT_URL + attributesId, payload);
};
