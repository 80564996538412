/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from 'react'
import {ILayout, useLayout} from '../../core'

const Footer = () => {
  const {config} = useLayout();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    updateDOM(config);
    setCurrentYear(new Date().getFullYear());
  }, [config])
  return (
    <>
      <p className='mb-0'>Copyright &copy; {currentYear} Targus. All Rights Reserved.</p>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
