
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import QuizPage from "../generatedTablePage/QuizPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddQuiz from "../generatedAddEdit/quiz/AddQuiz";
  import EditQuiz from "../generatedAddEdit/quiz/EditQuiz";
  

  
  
  const QuizRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<QuizPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditQuiz/>
    }
  />
  <Route
    path="/add"
    element={
      <AddQuiz/>
    }
  />      
         
      </Routes>
    );
  };
  export default QuizRoutes
