
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import SubCategoryPage from "../generatedTablePage/SubCategoryPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddSubCategory from "../generatedAddEdit/subcategory/AddSubCategory";
  import EditSubCategory from "../generatedAddEdit/subcategory/EditSubCategory";
  

  
  
  const SubCategoryRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<SubCategoryPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditSubCategory/>
    }
  />
  <Route
    path="/add"
    element={
      <AddSubCategory/>
    }
  />      
         
      </Routes>
    );
  };
  export default SubCategoryRoutes
