import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect } from "react";
import { addBulkLanguage } from "../app/request/languageRequest";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { languages } from "prismjs";
import MultipleCheckBoxSkeleton from "../components/Skeletons/MultipleCheckBox";
import TableView from "../components/TableView";
import Badge from "../components/Badge";
import moment from "moment";
import { initOnLoad } from "apexcharts";
import TableBody from "../components/TableView/TableBody";
import Multiselect from "multiselect-react-dropdown";
import Select from "../components/CustomSelect";
import SelectComponent from "../components/CustomSearchSelect";
import DropBox from "../components/DropBox";
import InputField from "../components/Input";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import "./UploadAccessoryForm.css";
import CustomTooltip1 from "../utils/CustomStyleComponent/TooltipNewComponent";

const columns = [
  {
    name: "ProductName",
    renderHeader: (rows: any) => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.searchResult?.modelNumber}</>,
  },
  {
    name: "Region",
    renderHeader: (rows: any) => (
      <>
        <b>Regions</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.regions?.[`0`]?.regionName}</>,
  },
  {
    name: "Source",
    renderHeader: (rows: any) => (
      <>
        <b>Source</b>
      </>
    ),
    renderCell: (row: any) => (
      <>
        {row?.searchResult?.addProductFrom == "I"
          ? "IceCat"
          : `${row?.searchResult?.brand} site`}
      </>
    ),
  },

  {
    name: "status",
    renderHeader: (rows: any) => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (row: any) => (
      <Badge
        variant={row?.isAdded == true ? "success" : "danger"}
        className="fs-7 fw-bolder"
      >
        {row?.message}
      </Badge>
    ),
  },
];

const UploadProductForm = ({
  category,
  regions,
  handleFileChange,
  setFieldValue,
  values,
  setFieldTouched,
  touched,
  displayUploadResults,
  uploadResults,
  errors,
  headers,
  isloading,
  handleGoBack,
  slideIn,
  slideOut,
}: {
  category: any;
  regions: any;
  displayUploadResults: any;
  uploadResults: any;
  setFieldValue: any;
  values: any;
  isloading: boolean;
  errors: any;
  touched: any;
  setFieldTouched: any;
  handleFileChange: any;
  headers: any;
  handleGoBack: () => void;
  slideIn: string;
  slideOut: string;
}) => {
  const style = {
    multiSelectContainer: {
      minheight: "44px",

      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontWeight: "500",
      minwidth: "40px",
      maxwidth: "40px",
      height: "44px",
      display: "flex",
      alignItems: "center",

      // styles for the search wrapper
    },
    chips: {
      // To change css chips(Selected options)
      marginBottom: "0",
    },
  };

  return (
    <>
      <div className="card p-5 pb-0">
        <div className="container p-0 m-0">
          <div className="row ">
            <div
              className="col-md-4 p-5 border shadow card "
              style={{ position: "sticky" }}
            >
              <div className="mb-5">
                <label className="form-label fs-6 fw-bolder text-dark required ">
                  Select File
                </label>
                <div>
                  <DropBox
                    onChange={(e: any) => handleFileChange(e)}
                    multiple={false}
                    maxFiles={1}
                  />
                  <input
                    value={values?.files?.[0]?.name}
                    disabled={true}
                    className={clsx("form-control bg-transparent", {
                      "is-invalid":
                        touched?.storeMappedProduct &&
                        errors?.storeMappedProduct,
                    })}
                    type="text"
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fs-6 fw-bolder text-dark required ">
                  Regions
                </label>
                <div>
                  <Multiselect
                    options={regions && regions}
                    selectionLimit={1}
                    disable={isloading}
                    displayValue={"regionName"}
                    style={style}
                    hidePlaceholder={true}
                    customCloseIcon={<CustomCrossButton />}
                    placeholder="Select Regions"
                    onSelect={async (e) => {
                      await setFieldValue("regions", e);
                      setFieldTouched("regions");
                    }}
                    onRemove={async (e) => {
                      await setFieldValue("regions", e);
                      setFieldTouched("regions");
                    }}
                    selectedValueDecorator={(value: string, option: any) => {
                      return <CustomTooltip value={value} option={option} />;
                    }}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fs-6 fw-bolder text-dark required ">
                  Category
                </label>
                <div>
                  <Multiselect
                    options={category && category}
                    selectionLimit={1}
                    disable={isloading}
                    displayValue={"categoryName"}
                    placeholder="Select category"
                    style={style}
                    hidePlaceholder={true}
                    customCloseIcon={<CustomCrossButton />}
                    onSelect={async (e) => {
                      await setFieldValue("category", e);
                      setFieldTouched("category");
                    }}
                    onRemove={async (e) => {
                      await setFieldValue("category", e);
                      setFieldTouched("category");
                    }}
                    selectedValueDecorator={(value: string, option: any) => {
                      return <CustomTooltip value={value} option={option} />;
                    }}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fs-6 fw-bolder text-dark required ">
                  Subcategory
                </label>
                <div>
                  <Multiselect
                    options={values?.category?.[`0`]?.subcategory}
                    selectionLimit={1}
                    disable={
                      values?.category?.length > 0 || isloading ? false : true
                    }
                    displayValue={"subcategoryName"}
                    placeholder={
                      values?.category?.length > 0
                        ? "Select Subcategory"
                        : "Select Category First"
                    }
                    style={style}
                    hidePlaceholder={true}
                    customCloseIcon={<CustomCrossButton />}
                    onSelect={async (e) => {
                      await setFieldValue("subcategory", e);
                      setFieldTouched("subcategory");
                      let attributesForSubCategory =
                        e?.[0]?.supportedAttributes.map((attributes: any) => {
                          return {
                            attributeName: attributes?.attributeName,
                            unit: attributes?.supportedUnits?.[0],
                            ...(attributes?.icecatName && {
                              icecatName: attributes?.icecatName,
                            }),
                          };
                        });
                      console.log(attributesForSubCategory);
                      setFieldValue(
                        "attributesArray",
                        attributesForSubCategory
                      );
                    }}
                    onRemove={async (e) => {
                      await setFieldValue("subcategory", e);
                      setFieldTouched("subcategory");
                    }}
                    selectedValueDecorator={(value: string, option: any) => {
                      return <CustomTooltip value={value} option={option} />;
                    }}
                  />
                </div>
              </div>
              {displayUploadResults && (
                <div
                  className="mt-7 d-flex justify-content-end"
                  onClick={handleGoBack}
                >
                  <span className="btn btn-primary">
                    <i className="fa-solid fa-arrow-left fs-2"></i>
                  </span>
                </div>
              )}
            </div>

            {
              displayUploadResults ? (
                <div className={`content col-md-8 ${slideIn}`}>
                  <div className="d-flex justify-content-center">
                    <Badge
                      variant="success"
                      className="fs-6 mb-5 me-5 fw-bolder"
                    >
                      {"Successfully Added: "}
                      {uploadResults?.newProductCount}{" "}
                    </Badge>
                    <Badge
                      variant="danger"
                      className="fs-6 mb-5 ms-5 fw-bolder"
                    >
                      {"Not Added:"}
                      {uploadResults?.duplicateProductCount}{" "}
                    </Badge>
                  </div>
                  <TableView
                    rows={uploadResults?.allProducts}
                    columns={columns}
                    showPagination={false}
                    showSearch={false}
                    showPerPage={false}
                    showFilter={false}
                  />
                </div>
              ) : null
              // (
              //   <div
              //     className={`content col-md-8`}
              //     style={{ maxHeight: "550px", overflowY: "auto" }}
              //   >
              //     <div className="col-md-12">
              //       <div className="d-flex justify-content-center">
              //         <h5 className="fw-bolder mb-5">Specifications</h5>
              //       </div>
              //       <div className="card p-5 shadow d-flex flex-row flex-wrap mb-3 d-flex">
              //         {staticAttributes.map((attribute: any) => {
              //           return (
              //             <div
              //               key={attribute._id}
              //               className="row me-2 mb-2"
              //               style={{ width: "50%" }}
              //             >
              //               <div className="w-100px">
              //                 <label className="col-form-label required fw-bold fs-6">
              //                   {attribute && attribute.name}
              //                 </label>
              //               </div>

              //               <div>
              //                 <Multiselect
              //                   options={headers}
              //                   isObject={false}
              //                   disable={isloading}
              //                   selectionLimit={1}
              //                   style={style}
              //                   hidePlaceholder={true}
              //                   customCloseIcon={<CustomCrossButton />}
              //                   onSelect={async (e) => {
              //                     let mapping = values.staticAttributesMapping;
              //                     let newobject = {
              //                       ...mapping,
              //                       [`${attribute.name}`]: e?.[0],
              //                     };
              //                     await setFieldValue(
              //                       "staticAttributesMapping",
              //                       newobject
              //                     );
              //                     setFieldTouched("staticAttributesMapping");
              //                   }}
              //                   selectedValueDecorator={(
              //                     value: string,
              //                     option: any
              //                   ) => {
              //                     return (
              //                       <CustomTooltip
              //                         value={value}
              //                         option={option}
              //                       />
              //                     );
              //                   }}
              //                 />
              //               </div>
              //             </div>
              //           );
              //         })}
              //       </div>

              //       {/* {values?.subcategory?.[`0`]?.supportedAttributes && (
              //         <div className="card p-5 shadow mb-5">
              //           {values?.subcategory?.[`0`]?.supportedAttributes.map(
              //             (attribute: any) => {
              //               let selectedunit = {};

              //               return (
              //                 <div key={attribute._id} className="row ">
              //                   <label className="col-lg-4 col-form-label required fw-bold fs-6 ">
              //                     {attribute && attribute.attributeName}
              //                   </label>

              //                   <div className="col-lg-4 mb-3">
              //                     <select
              //                       className="form-select form-select-white"
              //                       onChange={(e: any) => {
              //                         let temp = JSON.parse(e.target.value);
              //                         selectedunit = {
              //                           _id: temp._id,
              //                           name: temp.name,
              //                         };
              //                         console.log(selectedunit);
              //                       }}
              //                     >
              //                       <option>Please choose one option</option>
              //                       {attribute &&
              //                         attribute.supportedUnits &&
              //                         attribute.supportedUnits.map(
              //                           (option: any, index: any) => {
              //                             return (
              //                               <option
              //                                 key={index}
              //                                 value={JSON.stringify(option)}
              //                               >
              //                                 {option.name}
              //                               </option>
              //                             );
              //                           }
              //                         )}
              //                     </select>
              //                   </div>
              //                   <div className="col-lg-4">
              //                     <Multiselect
              //                       options={headers}
              //                       isObject={false}
              //                       disable={isloading}
              //                       selectionLimit={1}
              //                       style={style}
              //                       hidePlaceholder={true}
              //                       customArrow={<CustomArrow />}
              //                       customCloseIcon={<CustomCrossButton />}
              //                       singleSelect={true}
              //                       onSelect={async (e) => {
              //                         let mapping = values.attributesMapping;
              //                         let newobject = {
              //                           attributeName: attribute?.attributeName,
              //                           value: e?.[`0`],
              //                           unit: selectedunit,
              //                         };
              //                         mapping.push(newobject);

              //                         await setFieldValue(
              //                           "attributesMapping",
              //                           mapping
              //                         );
              //                         setFieldTouched("attributesMapping");
              //                       }}
              //                       selectedValueDecorator={(
              //                         value: string,
              //                         option: any
              //                       ) => {
              //                         return (
              //                           <CustomTooltip1
              //                             value={value}
              //                             option={option}
              //                           />
              //                         );
              //                       }}
              //                     />
              //                   </div>
              //                 </div>
              //               );
              //             }
              //           )}
              //         </div>
              //       )} */}
              //     </div>
              //   </div>
              // )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadProductForm;
