/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import { RootState } from "../redux/store";
import MappingFormFormModal from "../generatedFormModal/MappingFormModal";
import { toast } from "react-toastify";
import { getMapping } from "../generatedRequest/mapping";
import { CardForMapping } from "../components/CardForMapping/CardForMapping";
import "./MappingFormModal.css";
import { setRerender } from "../generatedSlice/mappingSlice";
import MappingSkeleton from "../components/MappingSkeleton";

const MappingList = () => {
  const [Mappings, setMapings] = useState([]);
  const { perPage, rerender1 } = useSelector(
    (state: RootState) => state.mapping
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initload, setInitload] = useState(true);
  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      try {
        // const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
        const res = await getMapping();
        if (res.data.status) {
          setMapings(res.data.data.data);
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };
    fn();
  }, [rerender1, dispatch]);

  return (
    <>
      {loading && initload ? (
        <MappingSkeleton numberOfInput={20} size={"650"} />
      ) : (
        <div className="listForMapping">
          {Mappings &&
            Mappings?.length > 0 &&
            Mappings?.map((item: any, index: number) => (
              <CardForMapping
                productSubcategory={item?.productSubcategory}
                accessorySubcategory={item?.accessorySubcategory}
                connections={item?.connections}
                key={
                  item?.productSubcategory?._id +
                  item?.accessorySubcategory?._id
                }
                mappingId={item?._id}
              />
            ))}
        </div>
      )}
    </>
  );
};

const MappingPage: FC = () => {
  const { rerender1 } = useSelector((state: RootState) => state.mapping);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleAddLanguage = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(setRerender(!rerender1));
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Mappings</h2>
        <Button
          variant="primary"
          handleButtonClick={handleAddLanguage}
          size="medium"
        >
          Add Mappings
        </Button>
      </div>

      <MappingList />

      {openModal && (
        <MappingFormFormModal
          currentRecord={{
            productSubcategory: {},
            accessorySubcategory: {},
            connections: [],
          }}
          isEdit={false}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default MappingPage;
