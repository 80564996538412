import React from 'react'
import { TEmptyStateProps } from '../../types/EmptyState';
import Button from '../Button';
import './style.css'


const EmptyState = ({ title, message, image, buttonTitle, onclick }: TEmptyStateProps) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='text-center py-5'>
          <div className='image-size'><img src={image ? image : "/empty-data.svg"} alt={title} className='img-fluid mb-4' /></div>
          <h2 className='mb-3'>{title}</h2>
          <p>{message}</p>
          {(buttonTitle && onclick )&& <Button handleButtonClick={onclick}>{buttonTitle}</Button>}
          
        </div>
      </div>
    </div>

  )
}

export default EmptyState