import { useState } from 'react'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { forgotPassword } from '../core/_requests'
import { ToastContainer, toast } from 'react-toastify';
import { forgotPasswordSchema } from '../../../../utils/validationSchema/forgotPasswordSchema'



const initialValues = {
  email: '',
}



export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("Enter your email to reset your password.")
  const navigate = useNavigate();


  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      setLoading(true)
      const payload = { email: values.email }
      try {
        const response = await forgotPassword(payload)
        if(response.data.status){
          toast.success(response.data.message);
          setTimeout(()=>{
            navigate('/');
          },2000)
        }
        else{
          toast.error(response.data.message);
        }
        setHasErrors(!response.data.status)
        setMessage(response.data.message)
        !response.data.status && response.data.error && setErrors(response.data.error);
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (


    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        {/* end::Title */}


      </div>

      {/* begin::Title */}
      {hasErrors === true ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {message}
          </div>
        </div>
      )
        : (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{message}</div>
          </div>
        )

      }





      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6 required'>Email</label>
        <div className="input-group mb-3">
          <input
            type='email'
            placeholder='Email'
            autoComplete='off'
            disabled={formik.isSubmitting}
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
        </div>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit' id='kt_password_reset_submit'
          disabled={formik.isSubmitting || !formik.isValid}
          className='btn btn-primary me-4'
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to='/auth'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-danger'

          >
            Cancel
          </button>
        </Link>

      </div>


      {/* end::Form group */}
    </form>



  )
}