import { useCallback, useEffect, useState } from "react";
import TableView from "../components/TableView";
import AccessoryTableAction from "../generatedTableActions/AccessoryTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setAccessory,
  setSearchTerm,
  setTotalPage,
} from "../generatedSlice/accessorySlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getAccessory } from "../generatedRequest/accessory";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import AccessoryList from "../generatedTableList/AccessoryList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import { TOptionType } from "../types/CustomSelect";
import AccessoryFilters from "../generatedFilters/AccessoryFilters";
import { getRegion } from "../app/request/regionRequest";
import UploadAccessoryForm from "../generatedForms/UploadAccessoryForm";
import UploadAccessoryFormModal from "../generatedFormModal/UploadAccessoryFormModal";
import { ActionCells } from "../components/ActionCells";

const actions = ["Manually", "Upload-Excel"];

const AccessoryPage = () => {
  const dispatch = useDispatch();
  const [statusFilter, setStatusFilter] = useState<TOptionType<string>>({
    label: "",
    value: "",
  });
  const [regionFilter, setRegionFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subcategoryFilter, setSubCategoryFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [allNewRegion, setAllNewRegion] = useState([]);
  const [initload, setInitload] = useState(true);
  const [filterloading, setFilterLoading] = useState(false);
  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
    rerender1,
  } = useSelector((state: RootState) => state.accessory);
  const [uploadExcel, setUploadExcel] = useState(false);

  const columns = [
    {
      name: "image",
      renderHeader: (rows: any) => (
        <>
          <b>Image</b>
        </>
      ),
      renderCell: (row: any) => (
        <a
          href={
            `${process.env.REACT_APP_API_URL_FOR_IMAGE}` +
            `${row && row.accessoryImages && row.accessoryImages[0]}`
          }
          target="_blank"
          style={{
            height: "50px",
            width: "50px",
            display: "block",
            margin: "0 auto",
          }}
        >
          <img
            src={
              row && row?.accessoryImages && row?.accessoryImages[0]
                ? `${process.env.REACT_APP_API_URL_FOR_IMAGE}${row?.accessoryImages[0]}`
                : row && row?.storeMappedProduct?.[`0`]?.images?.[`0`]
            }
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </a>
      ),
    },
    {
      name: "name",
      renderHeader: (rows: any) => (
        <>
          <b>Name</b>
        </>
      ),
      renderCell: (row: any) => <>{row && row.accessoryName}</>,
    },

    {
      name: "brand",
      renderHeader: (rows: any) => (
        <>
          <b>Brand</b>
        </>
      ),
      renderCell: (row: any) => <>{row && row.brand}</>,
    },

    {
      name: "status",
      renderHeader: (rows: any) => (
        <>
          <b>Status</b>
        </>
      ),
      renderCell: (row: any) => (
        <Badge
          variant={row.status == "active" ? "success" : "danger"}
          className="fs-7 fw-bolder"
        >
          {row.status}
        </Badge>
      ),
    },

    // {
    //   name: "Compatibility",
    //   renderHeader: (rows: any) => (
    //     <>
    //       <b>Compatibility</b>
    //     </>
    //   ),
    //   renderCell: (row: any) => (
    //     <Badge
    //       variant={row?.mappedPercentage >= "50" ? "success" : "danger"}
    //       className="fs-7 fw-bolder"
    //     >
    //       {`${row?.mappedPercentage}` + `%`}
    //     </Badge>
    //   ),
    // },
    {
      name: "subcategory",
      renderHeader: (rows: any) => (
        <>
          <b>Subcategory</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row?.subcategory && (
            <span>
              <a
                href={`/subcategory/edit?q=${row.subcategory._id}`} // Assuming 'row.subcategory' has a unique '_id' field
                style={{ textDecoration: "underline" }}
              >
                {row.subcategory.subcategoryName}
              </a>
            </span>
          )}
        </>
      ),
    },
    {
      name: "regions",
      renderHeader: (rows: any) => (
        <>
          <b>Region</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row?.regions &&
            Array.prototype.map.call(row && row.regions, function (item) {
              return (
                <span>
                  <a
                    href={"/region/edit?q=" + item._id}
                    style={{ textDecoration: "underline" }}
                  >
                    {item.regionName}
                  </a>
                  {"  "}
                </span>
              );
            })}
        </>
      ),
    },
    {
      name: "created_at",
      renderHeader: (rows: any) => (
        <>
          <b>Created At</b>
        </>
      ),
      renderCell: (row: any) => <>{row.createdAt}</>,
    },
    {
      name: "modified_at",
      renderHeader: (rows: any) => (
        <>
          <b>Modified At</b>
        </>
      ),
      renderCell: (row: any) => <>{row.updatedAt}</>,
    },
    {
      name: "Actions",
      renderHeader: (rows: any) => (
        <>
          <b>Actions</b>
        </>
      ),
      renderCell: (row: any) => (
        <AccessoryTableAction
          isAccessory={true}
          regions={allNewRegion}
          accessory={row}
        />
      ),
    },
  ];
  useEffect(() => {
    const fn = async () => {
      try {
        const response = await getRegion(`page=1&limit=100`);
        if (response.data.status) {
          setAllNewRegion(response.data.data.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
      }
    };
    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}&productId=${productFilter}`;
      dispatch(setLoading(true));
      try {
        const response = await getAccessory(searchQuery);
        if (response.data.status) {
          dispatch(setAccessory(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
        if (filterloading) {
          setFilterLoading(false);
        }
      }
    };
    fn();
  }, [
    rerender1,
    dispatch,
    categoryFilter,
    statusFilter,
    subcategoryFilter,
    regionFilter,
    productFilter,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(""));
    };
  }, []);

  function handleAfterSearch(isSuceess: boolean, data: any, value: any) {
    if (isSuceess) {
      dispatch(setAccessory(data.data.data.data));
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }
  const { doSearch, searchLoading } = useSearchApi(
    getAccessory,
    handleAfterSearch
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}&productId=${productFilter}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}&productId=${productFilter}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}&status=${
        statusFilter?.value
      }&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}&productId=${productFilter}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch]
  );

  const handleStatusFilter = useCallback(
    (data: TOptionType<string>) => {
      setStatusFilter(data);
      setFilterLoading(true);
    },
    [perPage, dispatch, doSearch]
  );

  const handleRegionFilter = useCallback(
    (data: any) => {
      let payload = data.map((value: any) => value.id).join(",");
      setRegionFilter(payload);
      setFilterLoading(true);
    },
    [perPage, dispatch, doSearch]
  );

  const handleCompatibiltyFilter = useCallback(
    (data: any) => {
      let payload = data.map((value: any) => value._id).join(",");
      setProductFilter(payload);
      setFilterLoading(true);
    },
    [perPage, dispatch, doSearch]
  );

  const handleCategoryFilter = useCallback(
    (data: any) => {
      let payload = data.map((value: any) => value._id).join(",");
      setCategoryFilter(payload);
      setFilterLoading(true);
    },
    [perPage, dispatch, doSearch]
  );
  const handleSubCategoryFilter = useCallback(
    (data: any) => {
      let payload = data.map((value: any) => value._id).join(",");
      setSubCategoryFilter(payload);
      setFilterLoading(true);
    },
    [perPage, dispatch, doSearch]
  );

  const handleUpload = useCallback(() => {
    setUploadExcel(!uploadExcel);
  }, [uploadExcel]);

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    showFilter: true,
    filters: (
      <AccessoryFilters
        onStatusFilterChange={handleStatusFilter}
        onRegionFilterChange={handleRegionFilter}
        currentRegionFilter={regionFilter}
        currentStatusFilter={statusFilter}
        onCategoryFilterChange={handleCategoryFilter}
        currentCategoryFilter={categoryFilter}
        onSubCategoryFilterChange={handleSubCategoryFilter}
        currentSubCategoryFilter={subcategoryFilter}
        onAccessoryCompatibiltyFilterChange={handleCompatibiltyFilter}
        currentAccessoryCompatibilityFilter={productFilter}
      />
    ),
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Accessories",
    searchLoading: searchLoading || filterloading,
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  const handleSelect = (selectedAction: string) => {
    switch (selectedAction) {
      case "Manually":
        navigate("add");
        break;
      case "Upload-Excel":
        handleUpload();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Accessories</h2>

        <ActionCells
          actions={actions}
          onSelect={handleSelect}
          title={"Add Accessory"}
          buttonStyle={`btn btn-light btn-primary btn-xl`}
          iconStyle={`svg-icon-4 ms-1`}
        />
      </div>
      <AccessoryList {...configuration} />

      {uploadExcel && (
        <UploadAccessoryFormModal onClose={() => handleUpload()} />
      )}
    </>
  );
};

export default AccessoryPage;
