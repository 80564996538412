import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  createRegion,
  getSingleRegion,
  updateRegion,
} from "../app/request/regionRequest";
import { toast } from "react-toastify";
import { getLanguage } from "../app/request/languageRequest";
import { useFormik } from "formik";
import { regionSchema } from "../utils/validationSchema/regionSchema";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import {
  TLanguage,
  TRegionConfig,
  TRegionFormData,
} from "../types/Module/Region/Region";
import Region from "../generatedForms/Region";
import "./RegionFormModal.css";
import modules from "../../src/ImportModules.json";
import { CustomMap } from "../components/Map/RegionMap";


const RegionFormModal = ({ isEdit }: TRegionConfig) => {
  const navigate = useNavigate();

  const [mapCoordinates, setMapCoordinates] = useState<{ latitude: string; longitude: string, zoom:number }>({
    latitude: "0",
    longitude:"0",
    zoom: 1,
  });

  const [queryParameters] = useSearchParams();
  const regionID = queryParameters.get("q") || "";

  const [loading, setLoading] = useState(false);
  const [alllanguage, setAllLanguage] = useState<TLanguage[]>([]);
  const [initload, setInitload] = useState(false);
  const [selectedModules, setSelectedModules] = useState(modules);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };


  const handleMapClick = (coordinates: { latitude: string; longitude: string; zoom:number }) => {
    setMapCoordinates(coordinates);
  };

  const formik = useFormik<TRegionFormData>({
    initialValues: {
      regionName: "",
      notes: [],
      language: [],
      regionUrl: "",
      status: { value: "active", label: "Active" },
      shopify_shop: "",
      shopify_api_access_token: "",
      isImport: false,
      importConfig: {
        types: [],
      },
      latitude:"",
      longitude: ""
    },
    validationSchema: regionSchema,
    onSubmit: async (values) => {
      let payload = {
        regionName: values.regionName,
        notes: values.notes || [],
        language: values.language.map((lang: string | TLanguage) =>
          typeof lang === "string" ? lang : lang.id || lang._id
        ),
        regionUrl: values.regionUrl,
        status:
          typeof values.status === "string"
            ? values.status
            : values.status.value,
        shopify_shop: values.shopify_shop,
        shopify_api_access_token: values.shopify_api_access_token,
        isImport: values.isImport,
        ...(values.isImport && { importConfig: values.importConfig }),
        latitude: mapCoordinates.latitude,
        longitude:mapCoordinates.longitude
      };
      console.log("payload", payload);
      handleSubmit(payload);
    },
  });
  useEffect(() => {
    const fetchRegionData = async () => {
      setLoading(true);
      try {
        const response = await getSingleRegion(regionID); // Call your API to get region data
        if (response.data.status) {
          formik.setValues({
            ...response.data.data,
            notes: response.data.data.notes,
            language: response.data.data.language,
            latitude:response.data.data.latitude,
            longitude:response.data.data.longitude,
          });
          formik.setFieldValue("status", {
            value: response.data.data.status,
            label: capitalizeFirstLetter(response.data.data.status),
          });
          setMapCoordinates({
            latitude: response?.data?.data?.latitude || "0",
            longitude: response?.data?.data?.longitude || "0",
            zoom: 3,
          })
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchRegionData();
    } else {
      setInitload(false);
    }
  }, [isEdit, regionID]);
  const handleSubmit = async (payload: TRegionFormData) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await updateRegion(payload, regionID)
        : await createRegion(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Region is edited Successfully")
          : toast.success("Region is Added Successfully");
      } else {
        if (typeof res.data.message == "string") {
          toast.error(res.data.message);
        } else if (typeof res.data.message == "object") {
          toast.error(res.data.message[0]);
        }
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getLanguage();
        if (resp.data.status) {
          setAllLanguage(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={10} />;
  }


  const handleModuleSelection = (moduleName: string) => {
    const updatedModules = selectedModules.map((module) =>
      module.name === moduleName
        ? { ...module, isSelected: !module.isSelected }
        : module
    );
    setSelectedModules(updatedModules);

    // Extract the values of selected modules
    const selectedTypes = updatedModules
      .filter((module) => module.isSelected)
      .map((module) => module.value);

    // Update the formik state with the selected types
    formik.setValues((prevValues) => ({
      ...prevValues,
      importConfig: {
        ...prevValues.importConfig,
        types: selectedTypes,
      },
      isImport: selectedTypes.length > 0,
    }));
  };



  
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card p-5">
          <div className="card-header p-0 border-1 cursor-pointer">
            <div className="card-title m-0">
              <div
                role={"button"}
                className="btn btn-sm btn-light me-5"
                onClick={() => navigate("/region")}
              >
                <i className="fa-solid fa-arrow-left fs-2"></i>
              </div>
              <h3 className="fw-bolder m-0">
                {isEdit ? "Edit" : "Add"} Region
              </h3>
            </div>
          </div>
          <Region alllanguage={alllanguage} {...formik} isloading={loading} isEdit={isEdit}/>
        </div>
      </div>
      
        <div className="col-md-4">
          {/* {!isEdit && <div className="card p-5 mb-5">
            <h5 className="fw-bolder mb-5">Import Modules</h5>
            {selectedModules?.map((moduleName: any) => {
              return (
                <div className="mb-3">
                  <label className="form-label fs-6 fw-bolder text-dark d-flex cursor-pointer">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      name="isProductImport"
                      checked={moduleName.isSelected}
                      onChange={() => handleModuleSelection(moduleName.name)}
                    />
                    <h6 className="mb-0 fs-6 fw-bolder">{moduleName.name}</h6>
                  </label>
                </div>
              );
            })}
          </div>} */}
          <div className="card p-5">
            <CustomMap onMapClick={handleMapClick} initialCoordinates={mapCoordinates} isEditMode={isEdit}/>
          </div>
        </div>
      

      <div>
        {/* begin::Action */}
        <div className="d-flex flex-column flex-md-row mt-5 mb-3 w-100 w-md-100 justify-content-end">
          <button
            onClick={() => formik.handleSubmit()}
            id="kt_sign_in_submit"
            className="btn btn-primary"
            disabled={loading ||!formik.isValid}
            type="submit"
          >
            {!loading && (
              <span className="indicator-label">
                {isEdit ? "Save" : "Save"}
              </span>
            )}
            {loading && (
              <span className="indicator-progress display-block">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </div>
    </div>
  );
};

export default RegionFormModal;