import AccessoryForm from "../generatedForms/Accessory";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { addAccessory, editAccessory, getSingleAccessory, getStoreAccessory } from "../generatedRequest/accessory";
import { AccessorySchema } from "../generatedValidations/AccessorySchema";
import { useSearchParams } from "react-router-dom";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import { getRegion } from "../app/request/regionRequest";
import { getCategory } from "../generatedRequest/category";
import axios from "axios";

type TAccessoryModalProps = {
  isEdit: boolean;
};

const AccessoryFormModal = ({ isEdit }: TAccessoryModalProps) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [allRegions, setAllRegions] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allStoreProducts,setAllStoreProducts] = useState([])
  const [initload, setInitload] = useState(true);
  const categoryID = queryParameters.get("q") || "";
  const [initialValues, setInitialValues] = useState({
    accessoryName: "",
    regions: [],
    category: [],
    subcategory: [],
    description: "",
    accessoryImages: [],
    attributes: [],
    brand:"",
    model:"",
    modelNumber:"",
    sku:"",
    storeMappedProduct:[],


  });

  

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editAccessory(payload, categoryID)
        : await addAccessory(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Accessory is edited Successfully")
          : toast.success("Accessory is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getRegion(`page=1&limit=100`);
        if (resp.data.status) {
          setAllRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getCategory();
        if (resp.data.status) {
          setAllCategory(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);


  // useEffect(() => {
  //   const fn = async () => {
  //     try {
  //       const resp = await getStoreAccessory("647f04841fc4f60a85a52125");
  //       console.log(resp)
  //       if (resp.data.status) {
  //         setAllStoreProducts(resp.data.data);
  //       }
  //     } catch (error) {
  //       toast.error("Network error");
  //     }
  //   };

  //   fn();
  // }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: AccessorySchema,
    onSubmit: (values, { setSubmitting }) => {
      console.log("values", values);
      let payload = {
        accessoryName: values.accessoryName,
        attributes:values.attributes,
        // reduce((result:any, obj) => {
        //   const key = Object.keys(obj)[0]; // Assuming each object contains only one key-value pair
        //   const value = obj[key];
        //   return { ...result, [key]: value };
        // }, {}),
        accessoryImages: values.accessoryImages,
        description: values.description,
        subcategory: values.subcategory.map((data:any) => data._id),
        regions: values.regions.map((region:any) => region.id || region._id),
        category: values.category.map((data:any) => data._id)[0],
        brand:values.brand,
        model:values.model,
        modelNumber:values.modelNumber,
        sku:values.sku,
        storeMappedProduct:values.storeMappedProduct
      };
      // for(let i=0;i<50;i++) {
        handleSubmit(payload);
      // }
  
    },
  });

  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      
      try {
        const response = await getSingleAccessory(categoryID); // Call your API to get user data
        if (response.data.status) {
    
          formik.setValues({
            ...response.data.data,
            category: [
              response.data.data?.category
            ],
            subcategory: [
              response.data.data?.subcategory
            ],
          });
        
          
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchCategoryData();
    }
    else {
      setInitload(false)
    }
  }, [isEdit, categoryID]);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }

  return (
    <>
      <AccessoryForm
        allCategory={allCategory}
        isloading={loading}
        allRegions={allRegions}
        allStoreProducts={allStoreProducts}
        {...formik}
        isEdit={isEdit}
      />

      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default AccessoryFormModal;
