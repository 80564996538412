import { Modal } from "react-bootstrap";
import { KTSVG } from "../_metronic/helpers";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  FileUpload, uploadProductExcel,
} from "../generatedRequest/product";
import MappedAccessoryForm from "../generatedForms/MappedAccessoryForm";
import Search from "../components/Search";
import {
 
  setRerender,
} from "../generatedSlice/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getSubCategory } from "../generatedRequest/subcategory";
import UploadProductForm from "../generatedForms/UploadProductForm";
import { getCategory } from "../generatedRequest/category";
import { getRegion } from "../app/request/regionRequest";
import axios from "axios";

type TMappedProductModalProps = {
  onClose?: () => void;
};

const UploadProductFormModal = ({ onClose }: TMappedProductModalProps) => {
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [category, setCategory] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [initload, setInitload] = useState(true);
  const [displayUploadResults, setDisplayUploadResults] = useState(false);
  const [uploadResults, setuploadResults] = useState({});

  const dispatch = useDispatch();
  const { rerender1 } = useSelector(
    (state: RootState) => state.product
  );
  const [fadeIn, setFadeIn] = useState("");
  const [fadeOut, setFadeOut] = useState("");
  const [initialValues, setInitialValues] = useState({
    subcategory: [],
    regions: [],
    category: [],
    attributes: [],
    files: [],
    attributesArray:[]
  });

  const handleSubmit = async (data: any) => {
    try {
      const res = await uploadProductExcel(data);
      if (res.data.status) {
        toast.success("Successfully uploaded");
        dispatch(setRerender(!rerender1));
        setDisplayUploadResults(true);
        console.log(res.data.data)
        let temp = {allProducts:res?.data?.data?.allProducts?.concat(res?.data?.data?.notFountProducts),
          newProductCount:res?.data?.data?.newProductCount,
          duplicateProductCount:res?.data?.data?.duplicateProductCount
        
        }
        setuploadResults(temp);
        setFadeOut("fade-out");
      } else {
      }
    } catch (error) {
      toast.error("Network error");
    } finally {
      setFadeIn("fade-in");
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    setDisplayUploadResults(false);
  };

  useEffect(() => {
    const fn = async () => {
      try {
        const res = await getCategory();
        if (res.data.status) {
          setCategory(res.data.data.data);
        } else {
          setCategory([]);
        }
      } catch (error) {
        toast.error("Network Error");
        typeof onClose === "function" && onClose();
      } finally {
      }
    };
    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        const res = await getRegion(`page=1&limit=100`);
        if (res.data.status) {
          setRegions(res.data.data.data);
        } else {
          setRegions([]);
        }
      } catch (error) {
        toast.error("Network Error");
        typeof onClose === "function" && onClose();
      } finally {
        setInitload(false);
      }
    };
    fn();
  }, []);

  const handledisplayResults = () => {};

  const handleFileUpload = async (payload: any) => {
    try {
      formik.setFieldValue(`files`, payload);
      const res = await FileUpload(payload);

      if (res.data.status) {
        setHeaders(res.data.data);
      } else {
        //   setRegions([]);
      }
    } catch (error) {
      toast.error("Network Error");
      typeof onClose === "function" && onClose();
    } finally {
    }
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      let payload = {
        subcategory: values.subcategory.map((data: any) => data._id),
        regions: values.regions.map((region: any) => region.id || region._id),
        category: values.category.map((data: any) => data._id)[0],
        attributesArray: values.attributesArray,
        files: values.files,
      };

      handleSubmit(payload);
    },
  });

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      size="xl"
      show={true}
      dialogClassName="modal-md modal-dialog-centered w-100"
      aria-hidden="true"
      onHide={onClose}
    >
      <div className="modal-header">
        {/* <Search
          placeholder={"Search Products"}
          handleSearchParams={(value: string) =>
            dispatch(setMappedAccessorySearchTerm(value))
          }
        /> */}
        <h2 className="modal-title fw-bolder">Manage Upload</h2>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onClose}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div
        className="modal-body scroll-y"
        style={{ maxHeight: "75vh", minHeight: "60vh" }}
      >
        <UploadProductForm
          headers={headers}
          isloading={loading}
          handleFileChange={handleFileUpload}
          category={category}
          regions={regions}
          {...formik}
          displayUploadResults={displayUploadResults}
          uploadResults={uploadResults}
          handleGoBack={handleGoBack}
          slideIn={fadeIn}
          slideOut={fadeOut}
        />
      </div>

      <div className="modal-footer d-flex justify-content-end">
        <button type="button" className="btn btn-danger me-4" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default UploadProductFormModal;
