
 import * as Yup from 'yup'

export const PermissionSchema = Yup.object().shape({
      
      
  id: Yup.string()
    .required('id is required.')
  
  ,
      
  name: Yup.string()
    .required('name is required.')
  
  
});
      