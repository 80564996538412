import React, { useCallback } from 'react'
import { TOptionType, TSelectProps } from '../../types/CustomSelect';


export const defaultGetOptionValue = (option: any) => "" + option.value;
export const defaultGetOptionLabel = (option: any) => option.label;

const Select = ({
    options, className, value, onChange, size, error,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultGetOptionValue,
    ...props }: TSelectProps) => {

    let _className = `form-select form-select-white ${className} ${size}`;

    let selectedOption: TOptionType<any> | undefined;
    if (value) {
        let selectedValue = getOptionValue(value);

        selectedOption = options.find(
            (option) => getOptionValue(option) === selectedValue
        ) as TOptionType<any>;
    }

    switch (size) {
        case "small": size = " form-select-sm "
            break;
        case "large": size = " form-select-lg "
            break;

    }

    const handleOnChange = useCallback((event:any) => {
        if (onChange) {
            const selected = options.find(
                (o) => getOptionValue(o) === event.target.value
            );
            onChange(selected);
         
        }
    }, [getOptionValue,options,onChange])
    
    return (
        <>
        
            <select
                {...props}
                className={error ? ` border-danger  ${_className} ` : ` ${_className} `}
                value={selectedOption ? getOptionValue(selectedOption) : ""}
                onChange={handleOnChange}
            >
                {options.map((option, i) => (
                    <option value={getOptionValue(option)} key={i}>
                        {getOptionLabel(option)}
                    </option>
                ))}
            </select>
            {error && <span className='mt-2 text-danger'>{error}</span>}
        </>

    )
}

export default Select