import { FC, createContext, useMemo, useCallback, useState } from "react";
import { useAuth } from "../app/modules/auth";
import { TPermissionProvider } from "../types/PermissionProvider";
import { useLocation } from "react-router-dom";
import { requestAccess } from "../request/modulName/moduleRequest";
import { TPermissionContextProps } from "../types/PermissionProvider/PermissionProviderProps";
import { toast } from "react-toastify";
import {
  isAccessible,
  can,
  getCurrentModule,
} from "../utils/PermissionUtils/PermissionUtils";
import { PemissionDenied } from "../components/Error/PemissionDenied";

const initPermissionContextPropsState = {
  isAllowed: false,
  currentModule: {},
  hasAccessTo: () => false,
  hasAccess: () => false,
  requestAccess: () => undefined,
  canDo: () => false,
  currentActivity: undefined,

};
const PermissionContext = createContext<TPermissionContextProps>(
  initPermissionContextPropsState
);

const PermissionProvider: FC<TPermissionProvider> = ({
  children,
  fallback = true,
}) => {
  const { currentUser } = useAuth();
  console.log(currentUser.permissions)
  const location = useLocation();
  const [currentAction, setCurrentAction] = useState<string>("");

  const sendAccessRequest = useCallback((payload: object) => {
    const fn = async () => {
      try {
        const response = await requestAccess(payload);
        if (response.data.status) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Network error");
      }
      setCurrentAction("");
    };
    fn();
  }, []);

  let contextValue = useMemo(() => {

    return {
      currentActivity: currentAction,
      currentModule: getCurrentModule(location.pathname.split("/")[1]), // give current module
      isAllowed:
        // Check if current module is allowed
        currentUser && currentUser.permissions
          ? isAccessible(
              `${location.pathname.split("/")[1].toLocaleLowerCase().replace(/\//g, "")}`,
              currentUser.permissions
            )
          : false,

      hasAccessTo: (moduleName: string, activity: string) => {
        // Check if user has access to given module name and given module Activity
        if (currentUser && currentUser.permissions) {
          return can(moduleName, activity, currentUser.permissions);
        }
      },

      canDo:(moduleName: string, activity: string) => {
 
        if (contextValue.hasAccessTo(moduleName, activity)) {
          return true;
        } else {
          setCurrentAction(activity);
        }
      },

      hasAccess: (moduleName: string) => {
        // Check if user has access to given module name
        if (currentUser && currentUser.permissions) {
          return isAccessible(moduleName, currentUser.permissions);
        }
      },

      requestAccess: (module: object) => {
        // Send a grand access request for a given module name
        sendAccessRequest(module);
      },
    };
  }, [
    currentUser,
    location.pathname,
    sendAccessRequest,
    currentAction,
    isAccessible

  ]);



  if (fallback && !contextValue.isAllowed) {

    return (
      <PemissionDenied
        title="Access Denied"
        text="You do not have permission to use this module."
        buttonText="Request Permission"
        image="/media/auth/permission-error-light.svg"
        handleButtonClick={() => {
          if (contextValue.currentModule) {
            contextValue.requestAccess(contextValue.currentModule);
          } else {
            toast.error("Module does not exist");
          }
        }}
      />
    );
  }

  return (
    <PermissionContext.Provider value={contextValue}>
      {children}
    </PermissionContext.Provider>
  );
};

export { PermissionProvider, PermissionContext };
