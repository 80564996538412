import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect } from "react";
import { addBulkLanguage } from "../app/request/languageRequest";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { languages } from "prismjs";
import MultipleCheckBoxSkeleton from "../components/Skeletons/MultipleCheckBox";
import TableView from "../components/TableView";
import Badge from "../components/Badge";
import moment from "moment";
import { initOnLoad } from "apexcharts";
import TableBody from "../components/TableView/TableBody";

const MappedAccessoryForm = ({
  MapAccessories,
  isLoadingMappedAccessories,
  handleSearchParams,
  currentMappedAccessory,
  initload,
  onChange,
}: {
  MapAccessories: any;
  handleSearchParams: any;
  isLoadingMappedAccessories: any;
  currentMappedAccessory: any;
  initload: Boolean;
  onChange: any;
}) => {
  const { searchMappedAccessories } = useSelector(
    (state: RootState) => state.accessory
  );

  if (isLoadingMappedAccessories && initload) {
    return <MultipleCheckBoxSkeleton checkbox={60} />;
  }

  const columns = [
    {
      name: "image",
      renderHeader: (rows: any) => (
        <>
          <b>Image</b>
        </>
      ),
      renderCell: (row: any) => (
        <a href={`${row?.images?.[`0`]}`} target="_blank">
          <img src={row?.images?.[`0`]} height="50px" />
        </a>
      ),
    },
    {
      name: "name",
      renderHeader: (rows: any) => (
        <>
          <b>Name</b>
        </>
      ),
      renderCell: (row: any) => <>{row?.title}</>,
    },

    {
      name: "category",
      renderHeader: (rows: any) => (
        <>
          <b>Category</b>
        </>
      ),
      renderCell: (row: any) => <>{row?.productType}</>,
    },

    {
      name: "status",
      renderHeader: (rows: any) => (
        <>
          <b>Status</b>
        </>
      ),
      renderCell: (row: any) => (
        <Badge
          variant={
            `${row?.status}`.toLocaleLowerCase() == "active"
              ? "success"
              : "danger"
          }
          className="fs-7 fw-bolder"
        >
          {row.status}
        </Badge>
      ),
    },

    {
      name: "created_at",
      renderHeader: (rows: any) => (
        <>
          <b>Created At</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row && row.createdAt && moment(row?.createdAt).format("DD-MM-YYYY")}
        </>
      ),
    },
    {
      name: "modified_at",
      renderHeader: (rows: any) => (
        <>
          <b>Modified At</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row && row.updatedAt && moment(row?.updatedAt).format("DD-MM-YYYY")}
        </>
      ),
    },
    {
      name: "Actions",
      renderHeader: (rows: any) => (
        <>
          <b>Actions</b>
        </>
      ),
      renderCell: (row: any) => (
        <button onClick={() => onChange(row)} className="btn btn-primary">
          {"Select"}
        </button>
      ),
    },
  ];

  const ColumnForSelected = [
    {
      name: "image",
      renderHeader: (rows: any) => (
        <>
          <b>Image</b>
        </>
      ),
      renderCell: (row: any) => (
        <a href={`${row?.images?.[`0`]}`} target="_blank">
          <img src={row?.images?.[`0`]} height="50px" />
        </a>
      ),
    },
    {
      name: "name",
      renderHeader: (rows: any) => (
        <>
          <b>Name</b>
        </>
      ),
      renderCell: (row: any) => <>{row?.title}</>,
    },

    {
      name: "category",
      renderHeader: (rows: any) => (
        <>
          <b>Category</b>
        </>
      ),
      renderCell: (row: any) => <>{row?.productType}</>,
    },

    {
      name: "status",
      renderHeader: (rows: any) => (
        <>
          <b>Status</b>
        </>
      ),
      renderCell: (row: any) => (
        <Badge
          variant={
            `${row?.status}`.toLocaleLowerCase() == "active"
              ? "success"
              : "danger"
          }
          className="fs-7 fw-bolder"
        >
          {row.status}
        </Badge>
      ),
    },

    {
      name: "created_at",
      renderHeader: (rows: any) => (
        <>
          <b>Created At</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row && row.createdAt && moment(row?.createdAt).format("DD-MM-YYYY")}
        </>
      ),
    },
    {
      name: "modified_at",
      renderHeader: (rows: any) => (
        <>
          <b>Modified At</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row && row.updatedAt && moment(row?.updatedAt).format("DD-MM-YYYY")}
        </>
      ),
    },
  ];

  return (
    <>
      <table className="table align-middle table-striped table-hover gs-0 gy-5">
        <h6>Selected Product</h6>
        <TableBody rows={currentMappedAccessory} columns={ColumnForSelected} />
      </table>

      <TableView
        rows={MapAccessories}
        columns={columns}
        showPagination={false}
        showSearch={true}
        searchPlaceholder={"Search Products"}
        showPerPage={false}
        showFilter={false}
        handleSearchParams={handleSearchParams}
      />
    </>
  );
};

export default MappedAccessoryForm;
