import React from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../components/Input";
import Multiselect from "multiselect-react-dropdown";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import clsx from "clsx";
import SelectComponent from "../components/CustomSearchSelect";
import { toAbsoluteUrl } from "../_metronic/helpers";
import DropBox from "../components/DropBox";
import RetailerList from "../generatedTableList/RetailerList";
import RetailerPage from "../generatedTablePage/RetailerPage";
import RetatilerdataPage from "../generatedTablePage/RetatilerdataPage";
import ExcelDownloadComponent from "../components/ExcelExport";
import { toast } from "react-toastify";

type TRetailerFormProp = {
  errors: any;
  isSubmitting: boolean;
  touched: any;
  getFieldProps: Function;
  setFieldValue: Function;
  setFieldTouched: Function;
  values: any;
  regions: any;
  isEdit: boolean;
  handleFileUpload: Function;
  excelHeaders: any;
  searchUrlLoading: boolean;
  getSearchUrlWithScrap: Function;
  retailerid: string;
};

const RetailerForm = ({
  errors,
  isSubmitting,
  touched,
  getFieldProps,
  setFieldValue,
  setFieldTouched,
  values,
  regions,
  isEdit,
  handleFileUpload,
  excelHeaders,
  searchUrlLoading,
  getSearchUrlWithScrap,
  retailerid,
}: TRetailerFormProp) => {
  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };

  const navigate = useNavigate();

  const staticoptions: any = [
    { value: "accessoryName" },
    { value: "modelNumber" },
    { value: "sku" },
  ];

  const BASE_URL = process.env.REACT_APP_FRONTEND_URL;

  console.log(values, "dada");
  return (
    <div className="container ms-1">
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-1 cursor-pointer">
              <div className="card-title m-0">
                <div
                  role={"button"}
                  className="btn btn-sm btn-light me-5"
                  onClick={() => navigate("/retailer")}
                >
                  <i className="fa-solid fa-arrow-left fs-2"></i>
                </div>
                <h3 className="fw-bolder m-0">
                  {isEdit ? "Edit" : "Add"} Retailer
                </h3>
              </div>
            </div>
            <div className="card-body p-9">
              <div className="d-flex flex-column flex-md-row  mb-3 w-100">
                <div className="d-flex flex-column flex-md-column w-md-50 w-100 mb-4 me-10">
                  <label className="form-label fs-6 fw-bolder text-dark required">
                    Retailer Name
                  </label>
                  <input
                    placeholder="Enter Retailer Name"
                    // disabled={isloading}
                    {...getFieldProps("retailerName")}
                    className={clsx("form-control bg-transparent ", {
                      "is-invalid": touched.retailerName && errors.retailerName,
                    })}
                    type="text"
                  />
                  {touched.retailerName && errors.retailerName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.retailerName}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column flex-md-column w-md-50 w-100">
                  <label className="form-label fs-6 fw-bolder text-dark required">
                    Retailer Url
                  </label>
                  <input
                    placeholder="Enter Retailer Url"
                    // disabled={isloading}
                    {...getFieldProps("retailerFrontendUrl")}
                    className={clsx("form-control bg-transparent ", {
                      "is-invalid":
                        touched.retailerFrontendUrl &&
                        errors.retailerFrontendUrl,
                    })}
                    type="text"
                  />
                  {touched.retailerFrontendUrl && errors.retailerFrontendUrl && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.retailerFrontendUrl}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row w-md-100 mb-3 w-100">
                <div className="d-flex flex-column flex-md-column w-md-50 w-100 mb-4 me-10">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Regions
                  </label>
                  <div>
                    <Multiselect
                      options={regions}
                      style={style}
                      customArrow={<CustomArrow />}
                      selectionLimit={1}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="regionName"
                      placeholder="Select Region"
                      selectedValues={regions?.filter(
                        (region: any) =>
                          region.id == values?.retailerRegion?.[`0`]?._id
                      )}
                      onSelect={async (e) => {
                        await setFieldValue("retailerRegion", e);
                        setFieldTouched("retailerRegion");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("retailerRegion", e);
                        setFieldTouched("retailerRegion");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {touched.retailerRegion && errors.retailerRegion && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.retailerRegion}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* {touched?.subcategory && errors?.subcategory && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{errors?.subcategory}</span>
                    </div>
                  </div>
                )} */}
                {isEdit && (
                  <div className="d-flex flex-column flex-md-column w-md-50 w-100">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Iframe / Banner URL
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        placeholder=""
                        disabled={true}
                        value={`${BASE_URL}?regionId=${
                          values?.retailerRegion?.[`0`]?._id
                        }&isretailer=true&rid=${values?._id}`}
                        className={clsx("form-control bg-transparent")}
                      />
                      {
                        <span
                          className="input-group-text p-2"
                          id="basic-addon1"
                          style={{ cursor: "pointer", height: "45px" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${BASE_URL}?regionId=${
                                values?.retailerRegion?.[`0`]?._id
                              }&isretailer=true&rid=${values?._id}`
                            );
                            toast.success("URL Copied");
                          }}
                        >
                          {" "}
                          COPY
                        </span>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex mt-10  justify-content-between align-items-center">
              <div>
                <h4 className="mb-4">Choose Method For Mapping</h4>
              </div>
            </div>
            <div className="card mb-5 mb-xl-10 p-5">
              <div className="row ">
                <div className="d-flex flex-row mb-8">
                  <label className="form-check form-check-custom form-check-solid form-label fs-7 cursor-pointer">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      name={"isElasticSearch"}
                      disabled={isSubmitting}
                      checked={values?.isElasticSearch}
                      onChange={() => {
                        setFieldValue(
                          "isElasticSearch",
                          !values?.isElasticSearch
                        );
                        if (!values?.isElasticSearch) {
                          setFieldValue("isFeed", false);
                        }
                      }}
                    />
                    <h6 className="mb-0" style={{ fontSize: "16px" }}>
                      Elastic Search
                    </h6>
                  </label>
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="name"
                    className="form-label fs-6 fw-bolder text-dark required"
                    style={{ width: "100px" }}
                  >
                    {"Search Url"}
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      placeholder="Enter Search Url"
                      disabled={isSubmitting || !values?.isElasticSearch}
                      style={{ height: "45px" }}
                      value={values?.searchConfiguration?.searchUrl}
                      onChange={(e) =>
                        setFieldValue(
                          "searchConfiguration.searchUrl",
                          e?.target?.value
                        )
                      }
                      className={clsx("form-control bg-transparent w-75")}
                    />

                    {
                      <span
                        className="input-group-text p-2"
                        id="basic-addon1"
                        style={{ cursor: "pointer", height: "45px" }}
                        onClick={() => getSearchUrlWithScrap()}
                      >
                        {!searchUrlLoading && (
                          // <span>
                          //   <img
                          //     alt="google translate"
                          //     src={toAbsoluteUrl(
                          //       "/media/icons/duotune/art/google-translate.svg"
                          //     )}
                          //     className="h-30px"
                          //   />
                          // </span>
                          <span className="svg-icon svg-icon-1">
                            <i
                              className="bi bi-search"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </span>
                        )}
                        {searchUrlLoading && (
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        )}
                      </span>
                    }
                  </div>
                  {values?.isElasticSearch &&
                    touched.searchConfiguration?.searchUrl &&
                    errors.searchConfiguration?.searchUrl && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {errors?.searchConfiguration?.searchUrl}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div className="col-lg-6 mb-4">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Search By
                  </label>
                  <div>
                    <Multiselect
                      options={staticoptions}
                      style={style}
                      disable={!values?.isElasticSearch}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="value"
                      selectionLimit={1}
                      placeholder="Select Attribute"
                      selectedValues={staticoptions?.filter(
                        (temp: any) =>
                          temp.value ==
                          values?.searchConfiguration?.searchBy?.value
                      )}
                      onSelect={async (e) => {
                        await setFieldValue(
                          "searchConfiguration.searchBy",
                          e?.[`0`]
                        );
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("searchConfiguration.searchBy", "");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                    {values?.isElasticSearch &&
                      touched.searchConfiguration?.searchBy &&
                      errors.searchConfiguration?.searchBy && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {errors?.searchConfiguration?.searchBy}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-5 mb-xl-10 p-5">
              <div className="row">
                <div className="d-flex flex-row mb-8">
                  <label className="form-check form-check-custom form-check-solid form-label fs-7 cursor-pointer">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      name={"isFeed"}
                      disabled={isSubmitting}
                      checked={values?.isFeed}
                      onChange={() => {
                        setFieldValue("isFeed", !values?.isFeed);

                        if (!values?.Feed) {
                          setFieldValue("isElasticSearch", false);
                        }
                      }}
                    />
                    <h6 className="mb-0" style={{ fontSize: "16px" }}>
                      Accessory Feed
                    </h6>
                  </label>
                </div>
                <div className="col-lg-6">
                  <div className="">
                    <div className="mb-5">
                      <div className="d-flex position-relative">
                        <label className="form-label fs-6 me-2 fw-bolder text-dark required ">
                          Select File
                        </label>
                        <ExcelDownloadComponent />
                      </div>

                      <div>
                        <DropBox
                          onChange={(e: any) => handleFileUpload(e)}
                          multiple={false}
                          maxFiles={1}
                        />
                        <input
                          value={values?.feedConfiguration?.file?.[0]?.name}
                          disabled={true}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              touched?.storeMappedProduct &&
                              errors?.storeMappedProduct,
                          })}
                          type="text"
                        />
                      </div>
                      {values?.isFeed &&
                        touched.feedConfiguration?.file &&
                        errors.feedConfiguration?.file && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {errors?.feedConfiguration?.file}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="form-check form-check-custom form-check-solid form-label fs-7 cursor-pointer">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      name={"price"}
                      disabled={isSubmitting}
                      checked={values?.price}
                      onChange={() => {
                        setFieldValue("price", !values?.price);
                      }}
                    />
                    <h6 className="mb-0 fs-6 fw-bolder">
                      Show Retailer Pricing (Optional)
                    </h6>
                  </label>
                </div>
              </div>
              {/* <div className="row">
                <label className="form-check form-check-custom form-check-solid form-label fs-7 cursor-pointer">
                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    name={"price"}
                    disabled={isSubmitting}
                    checked={values?.price}
                    onChange={() => {
                      setFieldValue("price", !values?.price);
                    }}
                  />
                  <h6 className="mb-0 fs-6 fw-bolder">
                    Show Retailer Pricing (Optional)
                  </h6>
                </label>
              </div> */}
              {/* <div className="row">
                <div className="col-lg-6 mb-4">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Map by
                  </label>
                  <div>
                    <Multiselect
                      options={staticoptions}
                      style={style}
                      disable={!values?.isFeed}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="value"
                      selectionLimit={1}
                      placeholder="Select Attribute"
                      selectedValues={staticoptions?.filter(
                        (temp: any) =>
                          temp?.value == values?.feedConfiguration?.mapBy?.value
                      )}
                      onSelect={async (e) => {
                        await setFieldValue(
                          "feedConfiguration.mapBy",
                          e?.[`0`]
                        );
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("feedConfiguration.mapBy", "");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {values?.isFeed && (touched.feedConfiguration?.mapBy &&
                    errors.feedConfiguration?.mapBy && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {errors?.feedConfiguration?.mapBy}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="col-lg-6 mb-4">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Redirect Field
                  </label>
                  <div>
                    <Multiselect
                      options={excelHeaders}
                      style={style}
                      disable={!values?.isFeed}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      isObject={false}
                      selectionLimit={1}
                      placeholder="Select Attribute"
                      selectedValues={excelHeaders?.filter(
                        (temp: any) =>
                          temp == values?.feedConfiguration.redirectFieldName
                      )}
                      onSelect={async (e) => {
                        await setFieldValue(
                          "feedConfiguration.redirectFieldName",
                          e?.[`0`]
                        );
                      }}
                      onRemove={async (e) => {
                        await setFieldValue(
                          "feedConfiguration.redirectFieldName",
                          ""
                        );
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {values?.isFeed && (touched.feedConfiguration?.redirectFieldName &&
                    errors.feedConfiguration?.redirectFieldName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {errors?.feedConfiguration?.redirectFieldName}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div> */}
              {isEdit && (
                <RetatilerdataPage
                  retailerId={retailerid}
                  regionId={values?.retailerRegion?.[`0`]?._id}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailerForm;
