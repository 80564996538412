import { useCallback, useEffect, useState } from "react";
import UnitsTableAction from "../generatedTableActions/UnitsTableAction";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setUnit,
  setTotalPage,
  setSearchTerm,
} from "../generatedSlice/unitSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getUnit } from "../app/request/unitRequest";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import UnitsList from "./UnitsList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import UnitsFilters from "../generatedFilters/UnitsFilters";
import { TOptionType } from "../types/CustomSelect";
import {
  TColumn,
  TDataObjectType,
  ThandleAfterSearchData,
} from "../types/Module/Unit/UnitPage";

const columns: TColumn[] = [
  {
    name: "name",
    renderHeader: () => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row) => <span>{`${row.name}`.toUpperCase()}</span>,
  },
  {
    name: "symbol",
    renderHeader: () => (
      <>
        <b>Symbol</b>
      </>
    ),
    renderCell: (row) => <span>{row.symbol}</span>,
  },
  {
    name: "regions",
    renderHeader: () => (
      <>
        <b>Regions</b>
      </>
    ),
    renderCell: (row) => (
      <>
        {Array.prototype.map.call(row && row.regions, function (item) {
          return (
            <span>
              <a
                href={"/region/edit?q=" + item.id}
                style={{ textDecoration: "underline" }}
              >
                {item.regionName}
              </a>
              {"  "}
            </span>
          );
        })}
      </>
    ),
  },
  {
    name: "created_at",
    renderHeader: () => (
      <>
        <b>Created At</b>
      </>
    ),
    renderCell: (row) => <span>{row.createdAt}</span>,
  },
  {
    name: "updated_at",
    renderHeader: () => (
      <>
        <b>Updated At</b>
      </>
    ),
    renderCell: (row) => <span>{row.updatedAt}</span>,
  },

  {
    name: "created_by",
    renderHeader: () => (
      <>
        <b>Created By</b>
      </>
    ),
    renderCell: (row) => (
      <span>
        {row.createdBy &&
          row.createdBy.overview &&
          row.createdBy.overview.email &&
          row.createdBy.overview.email}
      </span>
    ),
  },
  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row) => <UnitsTableAction unit={row} />,
  },
];

const UnitsPage = () => {
  const dispatch = useDispatch();
  const [initload, setInitload] = useState(true);
  const [filterloading,setFilterLoading] = useState(false)
  const [statusFilter, setStatusFilter] = useState<TOptionType<string>>({
    label: "",
    value: "",
  });
  const [regionFilter, setRegionFilter] = useState("");
  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    rerender1,
  } = useSelector((state: RootState) => state.unit);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}`;
      dispatch(setLoading(true));
      try {
        const response = await getUnit(searchQuery);
        if (response.data.status) {
          dispatch(setUnit(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
        if(filterloading){
          setFilterLoading(false)
        } 
      }
    };
    fn();
  }, [rerender1, dispatch, statusFilter, regionFilter]);


  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, []);

  function handleAfterSearch(
    isSuceess: boolean,
    data: ThandleAfterSearchData,
    value: number
  ) {
    dispatch(setUnit(data.data.data.data));
    dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
  }

  const { doSearch, searchLoading } = useSearchApi(getUnit, handleAfterSearch);

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}&status=${
        statusFilter?.value
      }&region=${regionFilter}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch,regionFilter,statusFilter?.value]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch,regionFilter,statusFilter?.value]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch,regionFilter,statusFilter?.value]
  );

  const handleStatusFilter = useCallback(
    (data: TOptionType<string>) => {
      setStatusFilter(data);
      setFilterLoading(true);
    },
    []
  );

  const handleRegionFilter = useCallback(
    (data: TDataObjectType[]) => {
      let payload = data.map((value) => value.id).join(",");
      setRegionFilter(payload);
      setFilterLoading(true)
    },
    []
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Units",
    searchLoading:searchLoading || filterloading,
    showFilter: true,
    filters: (
      <UnitsFilters
        onStatusFilterChange={handleStatusFilter}
        currentStatusFilter={statusFilter}
        onRegionFilterChange={handleRegionFilter}
        currentRegionFilter={regionFilter}
      />
    ),
  };
  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Units</h2>
        <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate(`add`)}
        >
          Add Unit
        </Button>
      </div>
      <UnitsList {...configuration}/>
    </>
  );
};

export default UnitsPage;
