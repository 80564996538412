import ActiveInActiveFilter from "../components/Filters/ActiveInActiveFilter";
import UnitsFilter from "../components/Filters/UnitsFilter";
import RegionsFilter from "../components/Filters/RegionsFilter";
import CategoryFilter from "../components/Filters/CategoryFilter";
import SubCategoryFilter from "../components/Filters/SubCategoryFilter";
import AccessoryCompatibiltyFilter from "../components/Filters/CompatibilityFilter"

const AccessoryFilters = ({
  onStatusFilterChange,
  currentStatusFilter,
  onRegionFilterChange,
  currentRegionFilter,
  onCategoryFilterChange,
  currentCategoryFilter,
  onSubCategoryFilterChange,
  currentSubCategoryFilter,
  onAccessoryCompatibiltyFilterChange,
  currentAccessoryCompatibilityFilter
}: {
  onStatusFilterChange: Function;
  onRegionFilterChange: Function;
  currentRegionFilter: any;
  currentStatusFilter: any;
  onCategoryFilterChange: Function;
  currentCategoryFilter: any;
  onSubCategoryFilterChange: Function;
  currentSubCategoryFilter: any;
  onAccessoryCompatibiltyFilterChange:Function,
  currentAccessoryCompatibilityFilter:any
}) => {
  return (
    <>
      <ActiveInActiveFilter
        onStatusFilterChange={onStatusFilterChange}
        currentStatusFilter={currentStatusFilter}
      />
      <RegionsFilter
        onRegionFilterChange={onRegionFilterChange}
        currentRegionFilter={currentRegionFilter}
      />
      {/* <CategoryFilter
        onCategoryFilterChange={onCategoryFilterChange}
        currentCategoryFilter={currentCategoryFilter}
      /> */}
      <SubCategoryFilter
        onSubCategoryFilterChange={onSubCategoryFilterChange}
        currentSubCategoryFilter={currentSubCategoryFilter}
      />
      {/* <AccessoryCompatibiltyFilter
        onAccessoryCompatibilityFilterChange={onAccessoryCompatibiltyFilterChange}
        currentAccessoryCompatibilityFilter={currentAccessoryCompatibilityFilter}
      /> */}
    </>
  );
};

export default AccessoryFilters;
