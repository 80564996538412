import ActiveInActiveFilter from "../components/Filters/ActiveInActiveFilter";
import UnitsFilter from "../components/Filters/UnitsFilter";

const AttributeFilters = ({
  onStatusFilterChange,
  currentStatusFilter,
  onUnitFilterChange,
  currentUnitFilter,
}: {
  onStatusFilterChange: Function;
  onUnitFilterChange: Function;
  currentUnitFilter: any;
  currentStatusFilter: any;
}) => {
  return (
    <>
      <UnitsFilter
        onUnitFilterChange={onUnitFilterChange}
        currentUnitFilter={currentUnitFilter}
      />
      <ActiveInActiveFilter
        onStatusFilterChange={onStatusFilterChange}
        currentStatusFilter={currentStatusFilter}
      />
    </>
  );
};

export default AttributeFilters;
