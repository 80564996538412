import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


type TFormSkeletonType = {
  numberOfInput: number;
  size?: string;
};

const FormSkeleton = ({ numberOfInput, size }: TFormSkeletonType) => {
  return (
    <div className="card p-5" style={{ minWidth: size}}>
      <div className=" w-100 w-md-100" id="kt_login_signin_form">
        <div className="d-flex justify-content-between flex-wrap">
          {[...Array(numberOfInput)].map((t: number, index: number) => {
            const cellIndex = index * 2 + index;
            return (
              <div className="mb-3" key={cellIndex}>
                <Skeleton width={220} height={30} />
              </div>
            );
          })}
        </div>

        <div className="d-flex flex-column flex-md-row mt-5 mb-3 w-100 w-md-100 justify-content-end">
          <button className="border-0" style={{ borderRadius: "5px" }}>
            <Skeleton width={120} height={30} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormSkeleton;
