import { useState, useCallback } from "react";
import { TConfig } from "../../../types/TableWrapper";
import Button from "../../../components/Button";
import EmptyState from "../../../components/EmptyState";

const useTableWrapperConfig = (url: string) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [mounted, setMounted] = useState(false);
  const [error, setError] = useState(false);
  const [showPerPage, setShowPerPage] = useState(true);
  const [showPagination, setShowPagination] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [searchParams, setSearchParams] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(5);
  const queryParams = `${searchParams}&currentpage=${currentPage}&perPage=${perPage}`;

  const getColumns = (data: any) => {
    setColumns([
      { name: "id" },
      { name: "name", renderHeader: () => <>Name</> },
      { name: "email",renderHeader: () => <>Email</>},
      {
        name: "Actions",
        renderCell: (rows: any) => (
          <>
            <Button
              className="me-3"
              handleButtonClick={() =>
                config.handleEdit && config.handleEdit(config.url, rows.id)
              }
            >
              Edit
            </Button>
            <Button
              variant="danger"
              handleButtonClick={() =>
                config.handleDelete && config.handleDelete(config.url, rows.id)
              }
            >
              Delete
            </Button>
          </>
        ),
      },
    ]);
  };

  const config: TConfig = {
    url: url,
    rows: rows,
    columns: columns,
    loading: loading,
    loadingMessage: loadingMessage,
    error: error,                                                                                //Error state
    errorComponent: (
      <EmptyState                                                                                //Error Component when Error
        title="Sorry"
        message="Its Not You Its Us"
        buttonTitle="Retry"
        image="/media/misc/bug-Fixing.svg"
        onclick={() => {
          window.location.reload();
        }}
      />
    ),
    mounted: mounted,                                                                          //Mounted Flag controls whether to use table skeleton or normal loader
    handleGet: async (url: string) => {                                                        //Get data from the backend/store via Get 
      try {
        const response = await fetch(`${url}?q=${queryParams}`);
        const { data } = await response.json();
        setTimeout(() => {
          setRows(data);
          if (data.length > 0) {
            getColumns(data);
          }
          if (searchParams || currentPage || perPage) {
            setLoading(false);
          }

          setMounted(true);
        }, 1000);
      } catch (error) {
        setError(true);
      }
    },

    handleEdit: async (url: string, idToEdit: number) => {                                    // Edit data of the backend/store via PUT
      setLoading(true);
      setLoadingMessage("Editing...");
      const payload = { id: idToEdit };
      try {
        const response = await fetch(`${url}`, {
          method: "PUT",
          body: JSON.stringify({ ...payload }),
        });
        const { data } = await response.json();
        setTimeout(() => {
          setRows(data);
          setLoading(false);
        }, 1000);
      } catch (error) {
        setError(true);
      } finally {
      }
    },

    handleDelete: async (url: string, idToDelete: number) => {                              // Delete data to the backend/store via Delete
      setLoading(true);
      setLoadingMessage("Deleting...");
      const payload = { id: idToDelete };
      try {
        const response = await fetch(`${url}`, {
          method: "DELETE",
          body: JSON.stringify({ ...payload }),
        });
        const { data } = await response.json();
        setTimeout(() => {
          setRows(data);
          setLoading(false);
        }, 1000);
      } catch (error) {
        setError(true);
      } finally {
      }
    }, 
    totalpage:totalPage,
    currentPage: currentPage,
    handlePageChange: (currentPageCount: number) => {                                     // Page Change for Pagination
      setCurrentPage(currentPageCount);
      setLoading(true);
      setLoadingMessage("Loading...");
    },

    perPage: perPage,
    handlePerPageChange: (currentPerPageCount: number) => {                               // PerPage Change for Pagination
      setPerPage(currentPerPageCount);
      setLoading(true);
      setLoadingMessage("Loading...");
    },
    showPagination:showPagination,                                                        // Flag for Showing Pagination
    showSearch:showSearch,                                                                // Flag for Showing Search
    showPerPage:showPerPage,                                                              // Flag for Showing Per Page
    searchParams: searchParams,
    handleSearch: useCallback((querySearch: string) => {                                  // Search initialisation and getting sorted data
      setLoading(true);
      setSearchParams(querySearch);
      setLoadingMessage("Searching...");
    }, []),

    searchComponent: (                                                                    //Error Component when Search
      <EmptyState                      
      title="Sorry"
      message="No User Found"
      buttonTitle="Reset"
      image="/media/misc/empty-State.svg"
    />
    ),
  };

  return { config };
};

export default useTableWrapperConfig;
