import React, { useCallback } from 'react'
import { TInputProps } from '../../types/Input';


const InputField = ({ className, size, value, onChange, error, placeholder,type, ...props }: TInputProps) => {
    let _className = `form-control form-control-solid ${className} ${size}`;
    switch (size) {
        case "small": size = "  form-control-sm "
            break;
        case "large": size = "  form-control-lg "
            break;

    }
    const fn=useCallback((e:any)=>{
        typeof onChange === "function" && onChange(e.target.value)
    },[onChange])
    return (
        <>
            <input
                {...props}
                type={type? `${type}`:'text' }
                className={error ? ` border-danger  ${_className} ` : ` ${_className} `}
                value={value}
                onChange={fn}
                placeholder={placeholder}
            />
            {error && <span className='mt-2 text-danger'>{error}</span>}
        </>

    )
}

export default InputField