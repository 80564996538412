import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const MAPPING_GET_URL = BASE_URL + "/compatibility-mapping";
const MAPPING_DELETE_URL = BASE_URL + "/compatibility-mapping/";
const MAPPING_ADD_URL = BASE_URL + "/compatibility-mapping/";
const MAPPING_EDIT_URL = BASE_URL + "/compatibility-mapping/";

export const getMapping = (searchParams?: string) => {
  return axios.get(MAPPING_GET_URL);
};

export const deleteMapping = (Id: string) => {
  return axios.delete(MAPPING_DELETE_URL + Id);
};

export const addMapping = (payload: string) => {
  return axios.post(MAPPING_ADD_URL,payload);
};

export const editMapping = (payload: any, Id?: string) => {
  return axios.patch(MAPPING_EDIT_URL + Id, payload);
};
