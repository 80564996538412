import { useState } from "react";
import Badge from "../../components/Badge";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import Select from "../../components/CustomSelect";
import EmptyState from "../../components/EmptyState";
import InputField from "../../components/Input";
import ProgressBar from "../../components/Progress";
import RadioButton from "../../components/Radio";
import Switch from "../../components/SwitchBox";
import TableSkeleton from "../../components/TableSkeleton";
import TableView from "../../components/TableView";
import { TOptionType } from "../../types/CustomSelect";
import { TagBox, TagBoxItem } from "../../components/TagBox";
import FormSkeleton from "../../components/Skeleton/FormSkeleton";
import CarouselDemo from "./Carousel";
import { Stepper } from "../../components/Stepper/Stepper";
import CustomStepper from "../../components/CustomStepper";

const myOption: TOptionType<string>[] = [
  { value: "in", label: "india" },
  { value: "us", label: "United States" },
  { value: "uk", label: "United Kindom" },
];

export const person = [
  {
    id: 1,
    name: "Satheesh",
    email: "Sat@mail.com",
    address: "South street",
    status: "Active",
  },
  {
    id: 2,
    name: "John",
    email: "john@mail.com",
    address: "North street",
    status: "Inactive",
  },
  {
    id: 3,
    name: "Robert",
    email: "robert@mail.com",
    address: "East street",
    status: "Active",
  },
  {
    id: 4,
    name: "Mani",
    email: "mani@mail.com",
    address: "West street",
    status: "Inactive",
  },
];

const ComponentDemo = () => {
  const [checked, setChecked] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>("");
  const [tags, setTags] = useState<TagBoxItem[]>([
    { name: "Tag 1", id: 1 },
    { name: "Tag 2", id: 2 },
    { name: "Tag 3", id: 3 },
  ]);

  const [option, setOption] = useState<TOptionType<string>>({
    value: "in",
    label: "india",
  });
  const handleButtonClick = () => {
    alert("button clicked");
  };
  const handleCheckbox = () => {
    setChecked(!checked);
  };
  const handleToggleSwitch = () => {
    setChecked(!checked);
  };
  const handleRadioButton = () => {
    // setChecked(!checked);
  };
  const handleInputField = (e: any) => {
    setInputText(e.target.value);
  };

  function handleEmptyState() {}

  const handleNewTag = (tagName: string) => {
    if (tagName) {
      const newTag: TagBoxItem = {
        name: tagName,
        id: Date.now(), // generate a unique ID for the new tag
      };
      setTags([...tags, newTag]);
    }
    // add the new tag to the list of tags
  };

  const handleDeleteTag = (tag: TagBoxItem, index: number) => {
    const newTags = [...tags];
    newTags.splice(index, 1); // remove the tag at the specified index
    setTags(newTags); // update the state with the new list of tags
  };

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <FormSkeleton numberOfInput={10} size={"500"} />
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <CustomStepper/>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Tagbox Component
        </h2>
        <TagBox
          tags={tags}
          handleNew={handleNewTag}
          handleDelete={handleDeleteTag}
        />
      </div>
      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Button Component
        </h2>
        <div className="d-flex justify-content-between">
          <Button
            variant="primary"
            handleButtonClick={handleButtonClick}
            size="small"
          >
            Primary
          </Button>
          <Button
            variant="light-primary"
            handleButtonClick={handleButtonClick}
            size="medium"
          >
            Light Primary
          </Button>
          <Button
            variant="danger"
            handleButtonClick={handleButtonClick}
            size="large"
          >
            Danger
          </Button>
          <Button
            variant="success"
            handleButtonClick={handleButtonClick}
            size="small"
          >
            Success
          </Button>
          <Button
            variant="light"
            handleButtonClick={handleButtonClick}
            size="small"
          >
            Light
          </Button>
          <Button
            variant="secondary"
            handleButtonClick={handleButtonClick}
            size="small"
          >
            Secondary
          </Button>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Badge Component
        </h2>
        <div className="d-flex justify-content-between">
          <Badge variant="primary" className="fs-7 fw-semibold">
            Primary
          </Badge>
          <Badge variant="info" className="fs-7 fw-semibold">
            Info
          </Badge>
          <Badge variant="warning" className="fs-7 fw-semibold">
            Warning
          </Badge>
          <Badge variant="danger" className="fs-7 fw-semibold">
            Danger
          </Badge>
          <Badge variant="success" className="fs-7 fw-semibold">
            Success
          </Badge>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Check Box Component
        </h2>
        <div>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <Checkbox
              label={checked ? "Checked" : "Unchecked"}
              onChange={handleCheckbox}
              size={"small"}
              checked={checked}
            />
          </span>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Radio Button Component
        </h2>
        <div>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <label className="form-check form-check-custom form-check-solid">
              <input
                type="radio"
                className="form-check-input me-2"
                name="gender"
                checked
              />
              Male
            </label>
          </span>
          <span>
            <label className="form-check form-check-custom form-check-solid">
              <input
                type="radio"
                className="form-check-input me-2"
                name="gender"
              />
              Female
            </label>
          </span>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Progress Bar Component
        </h2>
        <div>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <ProgressBar variant="success" value={50} height={5} />{" "}
          </span>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <ProgressBar variant="warning" value={40} height={2} />{" "}
          </span>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <ProgressBar variant="danger" value={70} height={7} />{" "}
          </span>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <ProgressBar variant="info" value={80} height={8} />{" "}
          </span>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <ProgressBar variant="primary" value={20} height={10} />{" "}
          </span>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Input Field Component
        </h2>
        <div>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <InputField
              value={inputText}
              onChange={handleInputField}
              size="large"
              placeholder="enter your name"
            />
          </span>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <InputField
              error={"This field is required"}
              value={inputText}
              onChange={handleInputField}
              size="large"
              placeholder="enter email Address*"
            />
          </span>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Switch Component
        </h2>
        <div>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <Switch
              label={checked ? "Enabled" : "Disabled"}
              onChange={handleToggleSwitch}
              checked={checked}
            />{" "}
          </span>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Dropdown Component
        </h2>
        <div>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <Select
              size="small"
              label={"select"}
              value={option}
              name="Select Region"
              onChange={(v: any) => {
                setOption(v);
              }}
              options={myOption}
            />{" "}
          </span>
          <span style={{ marginBottom: "20px", display: "block" }}>
            <Select
              size="medium"
              error={"This field is required"}
              label={"select"}
              value={option}
              name="Select Region"
              onChange={(v: any) => {
                setOption(v);
              }}
              options={myOption}
            />
          </span>
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Table Component with Searchbar, Pagination with Page selection
        </h2>
        <div>
          <TableView
            rows={person}
            columns={[
              {
                name: "id",
                renderHeader: () => (
                  <>
                    <b>Id</b>
                  </>
                ),
              },
              {
                name: "name",
                renderHeader: () => (
                  <>
                    <b>Name</b>
                  </>
                ),
              },
              {
                name: "email",
                renderHeader: () => (
                  <>
                    <b>Email</b>
                  </>
                ),
                renderCell: (person:any) => <span>{person.email}</span>,
                onClick: () => alert("cell clicked"),
              },
              {
                name: "Actions",
                renderHeader: () => (
                  <>
                    <b>Actions</b>
                  </>
                ),
                renderCell: () => (
                  <>
                    <Button className="me-3">edit</Button>
                    <Button>delete</Button>
                  </>
                ),
              },
            ]}
            showPagination={true}
            showSearch={true}
            showPerPage={true}
            paginationData={{ perPage: 10, currentPage: 1, totalPage: 5 }}
            searchPlaceholder={"Search"}
            showFilter={false}
            searchLoading={false}
          />
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Empty State Component
        </h2>
        <div>
          <EmptyState
            title="Empty State Title"
            message="Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia voluptate accusantium quos, suscipit sapiente nobis dolorem earum beatae exercitationem obcaecati totam iusto nisi reprehenderit maxime ut, accusamus eaque ex vel!
      Nostrum harum optio adipisci quas fuga reiciendis cum dolore, molestiae odit at, dolor repudiandae sit minus excepturi maxime? Quisquam velit qui nesciunt doloribus excepturi modi molestias quod corporis. Facilis, aperiam."
            buttonTitle="Back to Homepage"
            onclick={handleEmptyState}
          />
        </div>
      </div>

      <div className="card p-5" style={{ marginBottom: "30px" }}>
        <h2 className="text-center" style={{ marginBottom: "30px" }}>
          Table Skeleton Component
        </h2>
        <div>
          <TableSkeleton rows={5} columns={5} />
        </div>
      </div>
    </div>
  );
};

export default ComponentDemo;
