import * as Yup from 'yup'

export const setNewPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, 'Minimum 3 characters.')
      .max(50, 'Maximum 50 characters.')
      .required('Password is required.'),
    confirmpassword: Yup.string()
      .required('Confirm password is required.')
      .when('password', {
        is: (val: string) => ((val && val.length > 0 )|| (!val) ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match."),
      }),
  
  })