import React from "react";
import Skeleton from "react-loading-skeleton";

type TStepperSkeletonProp ={
  numberOfInput: number
}

const StepperSkeleton = ({numberOfInput}: TStepperSkeletonProp) => {
  return (
    <>
  {[...Array(numberOfInput)].map((t: number, index: number) => {
    const cellIndex = index * 2 + index;
    return (
      <div key={cellIndex} className="col-lg-6 mb-10">
        <div className="card p-5" style={{ border: "1px solid #e1e3ea" }}>
          <div>
            <Skeleton width={400} height={100} />
          </div>
          <div className="text-center">
            <h3 style={{ fontSize: "28px" }} className="mt-7 fw-bolder">
              <Skeleton width={400} height={30} />
            </h3>
            <p className="mt-7" style={{ fontSize: "20px" }}>
              <Skeleton width={400} height={30} />
            </p>
            <h5 style={{ fontSize: "18px" }} className="mt-5">
              <Skeleton width={400} height={30} />
            </h5>
          </div>
        </div>
      </div>
    );
  })}
</>

    
    
  );
};

export default StepperSkeleton;
