import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import Select from "../components/CustomSelect";
import clsx from "clsx";
import { TagBox, TagBoxItem } from "../components/TagBox";
import {
  TLanguage,
  TRegionProp,
  TStatusOption,
} from "../types/Module/Region/Region";
import { TOptionType } from "../types/CustomSelect";
import { FormikErrors } from "formik";
import "../styles/multiselectStyles.css";
import { toast } from "react-toastify";

const Region = ({
  errors,
  touched,
  isloading,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  alllanguage,
  isEdit,
}: TRegionProp) => {
  const [optionsStatus, setOptionStatus] = useState<TOptionType<string>>({
    value: "active",
    label: "Active",
  });

  const OptionsStatus: TOptionType<string>[] = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const handleSNewTag = (tagName: string) => {
    if (tagName) {
      const newTag: TagBoxItem = {
        name: tagName,
        id: Date.now().toString(), // convert the ID to a string
      };
      setFieldValue("notes", [...values.notes, newTag]);
    }
    // add the new tag to the list of tags
  };

  const handleSDeleteTag = (tag: TagBoxItem, index: number) => {
    const newTags = [...values.notes];
    newTags.splice(index, 1);
    setFieldValue("notes", newTags); // remove the tag at the specified index
  };

  const convertStatusToOptionType = (
    status: TStatusOption | string
  ): TOptionType<string> | undefined => {
    if (status) {
      if (typeof status === "string") {
        return { value: status, label: status };
      } else {
        return status;
      }
    }
    return undefined;
  };

  const BASE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <div className=" w-100 w-md-100 pt-5">
      <div className="d-flex flex-column flex-md-row  mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Name
          </label>
          <input
            placeholder="Name"
            disabled={isloading}
            {...getFieldProps("regionName")}
            className={clsx(
              "form-control bg-transparent ",
              {
                "is-invalid": touched.regionName && errors.regionName,
              },
              {
                "is-valid": touched.regionName && !errors.regionName,
              }
            )}
            type="text"
          />
          {touched.regionName && errors.regionName && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.regionName}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 ">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Url
          </label>
          <input
            type="text"
            disabled={isloading}
            placeholder="https://au.targus.com"
            {...getFieldProps("regionUrl")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": touched.regionUrl && errors.regionUrl,
              },
              {
                "is-valid": touched.regionUrl && !errors.regionUrl,
              }
            )}
          />
          {touched.regionUrl && errors.regionUrl && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.regionUrl}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row w-md-100 mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required ">
            Language
          </label>
          <div onClick={() => setFieldTouched("language")}>
            <Multiselect
              className="multiselect-container multiselect-search-box"
              placeholder={"Select Languages"}
              options={alllanguage}
              customArrow={<CustomArrow />}
              customCloseIcon={<CustomCrossButton />}
              displayValue="languageName"
              selectedValues={values.language}
              onSelect={async (e) => {
                await setFieldValue("language", e);
                setFieldTouched("language");
              }}
              onRemove={async (e) => {
                await setFieldValue("language", e);
                setFieldTouched("language");
              }}
            />
            {touched?.language && errors?.language && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {Array.isArray(errors.language) ? (
                    errors.language.map(
                      (
                        error: FormikErrors<TLanguage> | string,
                        index: number
                      ) => (
                        <span key={index} role="alert">
                          {typeof error === "string"
                            ? error
                            : (error as FormikErrors<TLanguage>).languageName}
                        </span>
                      )
                    )
                  ) : (
                    <span role="alert">
                      {typeof errors.language === "string"
                        ? errors.language
                        : (errors.language as FormikErrors<TLanguage>)
                            .languageName}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark">Status</label>
          <div onClick={() => setFieldTouched("status")}>
            <Select
              value={convertStatusToOptionType(values.status) || optionsStatus}
              name="Select Status"
              onChange={(selectedValue) => {
                if (selectedValue) {
                  setOptionStatus(selectedValue);
                  setFieldValue("status", selectedValue);
                }
              }}
              options={OptionsStatus}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row w-md-100 mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required ">
            Shop Name
          </label>

          <input
            placeholder="Shop Name"
            disabled={isloading}
            {...getFieldProps("shopify_shop")}
            className={clsx(
              "form-control bg-transparent ",
              {
                "is-invalid": touched.shopify_shop && errors.shopify_shop,
              },
              {
                "is-valid": touched.shopify_shop && !errors.shopify_shop,
              }
            )}
            type="text"
          />
          {touched?.shopify_shop && errors?.shopify_shop && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {/* <span role="alert">{errors.shopify_shop}</span> */}
                <span role="alert">
                  {typeof errors?.shopify_shop === "string"
                    ? errors.shopify_shop
                    : ""}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark">
            Access Token
          </label>
          <input
            placeholder="Access Token"
            disabled={isloading}
            {...getFieldProps("shopify_api_access_token")}
            className={clsx(
              "form-control bg-transparent ",
              {
                "is-invalid":
                  touched.shopify_api_access_token &&
                  errors.shopify_api_access_token,
              },
              {
                "is-valid":
                  touched.shopify_api_access_token &&
                  !errors.shopify_api_access_token,
              }
            )}
            type="text"
          />
          {touched?.shopify_api_access_token &&
            errors?.shopify_api_access_token && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {/* <span role="alert">{errors.shopify_api_access_token}</span> */}
                  <span role="alert">
                    {typeof errors?.shopify_api_access_token === "string"
                      ? errors.shopify_api_access_token
                      : ""}
                  </span>
                </div>
              </div>
            )}
        </div>
      </div>
      {/* end::Form group */}

      <div className="d-flex flex-column flex-md-row w-md-100 mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark">Notes</label>
          <TagBox
            tags={values.notes || []}
            handleNew={handleSNewTag}
            handleDelete={handleSDeleteTag}
            placeholder={"Notes"}
          />
        </div>

        {isEdit && (
          <div className="d-flex flex-column flex-md-column w-md-50 w-100">
            <label className="form-label fs-6 fw-bolder text-dark">
              Iframe / Banner URL
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                placeholder=""
                disabled={true}
                value={`${BASE_URL}?regionId=${values?._id}`}
                className={clsx("form-control bg-transparent")}
              />
              {
                <span
                  className="input-group-text p-2"
                  id="basic-addon1"
                  style={{ cursor: "pointer", height: "45px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${BASE_URL}?regionId=${values?._id}`
                    );
                    toast.success("URL Copied");
                  }}
                >
                  {" "}
                  COPY
                </span>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Region;
