import React from 'react'
import SettingFormModal from '../generatedFormModal/SettingFormModal'

const EditSetting=({currentRecord}:{currentRecord?:any})=> {
  return (
    <div>
     <SettingFormModal isEdit={true} currentRecord={{
        name:"",
        id:"",
        email:"",
     }}/> 
    </div>
  )
}

export default EditSetting