import SubCategoryForm from "../generatedForms/SubCategory";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import {
  addSubCategory,
  editSubCategory,
  getSingleSubCategory,
} from "../generatedRequest/subcategory";
import { SubCategorySchema } from "../generatedValidations/SubCategorySchema";
import { useSearchParams } from "react-router-dom";
import { getAttribute } from "../generatedRequest/attribute";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import {
  TData,
  TFilteredAttribute,
  TSubCategoryConfig,
  TSubCategoryFormData,
  TSubCategoryPayload,
  TSupportedValue,
} from "../types/Module/SubCategory/SubCategoryFormModal";

const SubCategoryFormModal = ({ isEdit }: TSubCategoryConfig) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [allAttributes, setAllAttributes] = useState([]);
  const [initload, setInitload] = useState(true);
  const categoryID = queryParameters.get("q") || "";
  const [initialValues, setInitialValues] = useState({
    subcategoryName: "",
    supportedAttributes: [],
    notes: [],
    description: "",
    subcategoryImage: [],
    compatibility: [],
    filteredAttribute: [],
    filteredAttributesWithValue: [],
    isProductSubcategory: false,
    isShowFit:false
  });

  const handleSubmit = async (payload: TSubCategoryPayload) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editSubCategory(payload, categoryID)
        : await addSubCategory(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Subcategory is edited Successfully")
          : toast.success("Subcategory  is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<TSubCategoryFormData>({
    initialValues: initialValues,
    validationSchema: SubCategorySchema,
    onSubmit: (values) => {
      let payload = {
        subcategoryName: values.subcategoryName,
        supportedAttributes: values.supportedAttributes.map(
          (unit: TSupportedValue) => unit.id || unit._id
        ),
        notes: values.notes || [],
        subcategoryImage: values.subcategoryImage[0]["subcategoryImage"],
        description: values.description,
        compatibility: values.compatibility,
        filteredAttribute: values?.filteredAttributesWithValue.map(
          (filteredAttribute: TFilteredAttribute) => ({
            attributeName: filteredAttribute?.attributeName,
            value: filteredAttribute?.value?.map((data: TData) => data?.name),
          })
        ),
        isProductSubcategory: values?.isProductSubcategory,
        isShowFit: values?.isShowFit
      };

      handleSubmit(payload);
    },
  });
  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      try {
        const response = await getSingleSubCategory(categoryID); // Call your API to get user data
        if (response.data.status) {
          formik.setValues({
            ...response.data.data,
            filteredAttribute: response?.data?.data?.filteredAttribute?.map(
              (data: TFilteredAttribute) => data?.attributeName
            ),
            filteredAttributesWithValue:
              response?.data?.data?.filteredAttribute?.map(
                (data: TFilteredAttribute) => ({
                  attributeName: data?.attributeName,
                  value: data.value.map((datavalue: TData) => ({
                    name: datavalue,
                    id: Date.now(),
                  })),
                })
              ),
            subcategoryImage: [
              {
                subcategoryImage: response.data.data.subcategoryImage,
                subcategoryImageURL: response.data.data.subcategoryImageURL,
              },
            ],
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchCategoryData();
    } else {
      setInitload(false);
    }
  }, [isEdit, categoryID]);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getAttribute();
        if (resp.data.status) {
          setAllAttributes(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }

  return (
    <>
      <SubCategoryForm
        allAttributes={allAttributes}
        {...formik}
        isEdit={isEdit}
        isloading={loading}
      />

      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default SubCategoryFormModal;
