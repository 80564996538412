import EmptyState from "../components/EmptyState";
import TableView from "../components/TableView";
import { TTableProps } from "../types/TableView";


const UnitsList = ({ searchLoading,columns, rows, showPagination, showSearch, showPerPage, paginationData, onPageChange, onPerPageChange, handleSearchParams, searchPlaceholder,filters,showFilter }:TTableProps) => {
  return (
    <div className="card">
      <TableView
        columns={columns}
        rows={rows}
        showPagination={showPagination}
        showSearch={showSearch}
        showPerPage={showPerPage}
        showFilter={showFilter}
        filters={filters}
        paginationData={paginationData}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
        handleSearchParams={handleSearchParams}
        searchPlaceholder={searchPlaceholder}
        searchComponent={<EmptyState                      
          title="Sorry"
          message="No Unit Found"
          buttonTitle="Reset"
          image="/media/misc/empty-State.svg"
        />}
        searchLoading={searchLoading}
        cardHeaderStyle ={"30px"}
      />
    </div>
  );
};

export default UnitsList;
