import { useEffect } from "react";
import TableView from "../TableView";
import { TTableWrappperProps } from "../../types/TableWrapper";
import TableSkeleton from "../TableSkeleton";

const TableWrapper = ({ config }: TTableWrappperProps) => {
  useEffect(() => {
    config.handleGet && config.handleGet(config.url);
  }, [config.url, config.searchParams, config.perPage, config.currentPage]);

  if (config.error) {
    return config.errorComponent && config.errorComponent;
  }

  if (config.rows.length === 0 && !config.mounted) {
    return <TableSkeleton rows={config.perPage} columns={3} />;
  }

  return (
    <TableView
      rows={config.rows}
      columns={config.columns}
      showPagination={config.showPagination}
      showSearch={config.showSearch}
      showPerPage={config.showPerPage}
      paginationData={{
        perPage: config.perPage,
        currentPage: config.currentPage,
        totalPage: config.totalpage,
      }}
      handleSearchParams={config.handleSearch}
      onPerPageChange={config.handlePerPageChange}
      onPageChange={config.handlePageChange}
      isLoading={config.loading}
      loadingMessage={config.loadingMessage}
      searchComponent={config.searchComponent}
      showFilter={false}
      searchLoading={false}
    />
  );
};

export default TableWrapper;
