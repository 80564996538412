
    import { TTableProps } from "../types/TableView";
    import TableView from "../components/TableView";

  const SettingList = ({ searchLoading,columns, rows, showPagination, showSearch, showPerPage, paginationData, onPageChange, onPerPageChange, handleSearchParams, searchPlaceholder,showFilter }:TTableProps) => { 
   return (
    <div className='card'>
    <TableView
    columns={columns}
    rows={rows}
    showFilter={showFilter}
    showPagination={showPagination}
    showSearch={showSearch}
    showPerPage={showPerPage}
    paginationData={paginationData}
    onPageChange={onPageChange}
    onPerPageChange={onPerPageChange}
    handleSearchParams={handleSearchParams}
    searchPlaceholder={searchPlaceholder}
    searchLoading={searchLoading}
  />
    </div>
  );
};

export default SettingList;
    