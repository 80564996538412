import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import RoleForm from "./RoleForm";
import { useFormik } from "formik";
import {
  addRole,
  editRole,
  getSingleRole,
} from "../../modules/auth/core/_requests";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setRoleRerender } from "../../../redux/slices/userSlice";
import { RolePermissions } from "./permissionsForRoles";
import { TAddRoleModalProps, TRolePermissions } from "../../../types/Module/Users & Permissons/RoleFormModal";



const RoleFormModal = ({
  onClose,
  isEdit,
  currentUserId,
}: TAddRoleModalProps) => {
  const [loading, setLoading] = useState(false);
  const { roleRender } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<TRolePermissions>(RolePermissions);
  useEffect(() => {
    const fetchRoleData = async () => {
      setLoading(true);
      try {
        const response = await getSingleRole(currentUserId || ""); // Call your API to get user data
        if (response.data.status) {
         
          let temp = RolePermissions.permissions.map((obj1) => {
            let matchingObj = response.data.data.permissions.find((obj2:any) => obj2.moduleName === obj1.moduleName);
            if (matchingObj) {
                return matchingObj;
            } else {
                return obj1;
            }
        });
          formik.setValues(response.data.data);
          formik.setFieldValue("permissions",temp);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
      }
    };

    if (isEdit) {
      fetchRoleData();
    }
  }, [isEdit, currentUserId]);

 

  const formik = useFormik<TRolePermissions>({
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      let payload = formik.values;
      setLoading(true);
      const fn = async () => {
        setSubmitting(true);
        try {
          const { data: res } = isEdit
            ? await editRole(payload, currentUserId || "")
            : await addRole(payload);
          if (res.status) {
            isEdit
              ? toast.success("Role edited successfully")
              : toast.success("New role has been added successfully")
            dispatch(setRoleRerender(!roleRender));
            typeof onClose === "function" && onClose();
          } else {
            toast.error(`${res.message}`);
          }
        } catch (error) {
          //TODO: make error when go live
          toast.error("Network error");
        } finally {
          setSubmitting(false);
          setLoading(false);
        }
      };
      fn();
    },
  });

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      size="lg"
      show={true}
      dialogClassName="modal-md modal-dialog-centered w-100"
      aria-hidden="true"
      onHide={onClose}
    >
      <div className="modal-header">
        <h2 className="modal-title fw-bolder">{isEdit? "Edit" : "Add"} Role</h2>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onClose}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body scroll-y" style={{ maxHeight: "60vh", paddingTop:"0" }}>
        <RoleForm {...formik} />
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <button type="button" className="btn btn-danger me-4" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={formik.isSubmitting || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default RoleFormModal;
