import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export interface UserState {
    users: object[],
    perPage: number,
    currentPage: number,
    totalPage: number,
    searchTerm: string,
    loading: boolean,
    rerender1: boolean,
    roleRender:boolean
}


const initialState: UserState = {
    users: [],
    perPage: 10,
    currentPage: 1,
    totalPage: 1,
    searchTerm: "",
    loading: false,
    rerender1:false,
    roleRender:false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsers: (state:UserState, { payload }: PayloadAction<object[]>) => { },
        setPerPage: (state:UserState, {payload}: PayloadAction<number>) => {
            state.perPage = payload;
        },
        setCurrentPage: (state:UserState, {payload}: PayloadAction<number>) => { 
            state.currentPage = payload;
        },
        setSearchTerm: (state:UserState, {payload}: PayloadAction<string>) => { 
            state.searchTerm = payload;
        },
        setTotalPage: (state:UserState, {payload}: PayloadAction<number>) => { 
            state.totalPage = payload
        },
        setSearch: (state, {payload}: PayloadAction<string>) => { 
            state.searchTerm = payload;
        },
        setLoading: (state:UserState, {payload}: PayloadAction<boolean>) => { 
            state.loading=payload
        },
        setRerender: (state:UserState, {payload}: PayloadAction<boolean>) => {
            state.rerender1 = payload
         },
         setRoleRerender: (state:UserState, {payload}: PayloadAction<boolean>) => {
            state.roleRender = payload
         },
    },
})


export const { setUsers, setPerPage, setCurrentPage, setTotalPage, setSearchTerm, setLoading,setRerender, setRoleRerender} = userSlice.actions
export default userSlice.reducer