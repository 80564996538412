import axios from "axios";
import { TSubCategoryPayload } from "../types/Module/SubCategory/SubCategoryFormModal";

const BASE_URL = process.env.REACT_APP_API_URL;
const SUBCATEGORY_GET_URL = BASE_URL + "/subcategories";
const SUBCATEGORY_DELETE_URL = BASE_URL + "/subcategories/";
const SUBCATEGORY_ADD_URL = BASE_URL + "/subcategories/";
const SUBCATEGORY_EDIT_URL = BASE_URL + "/subcategories/";
const SUBCATEGORY_IMAGE_URL = BASE_URL + "/subcategories/uploadImage";
const SUBCATEGORY_IMAGE_DELETE = BASE_URL + "/subcategories/deleteImageAdd";
const SUBCATEGORY_EDIT_IMAGE_DELETE = BASE_URL + "/subcategories/deleteImage";


export const getSubCategory = (searchParams?: string) => {
  return axios.get(SUBCATEGORY_GET_URL + "?" + searchParams);
};

export const getSingleSubCategory = (subcategoryId: string) => {
  return axios.get(SUBCATEGORY_GET_URL + "/" + subcategoryId);
};

export const deleteSubCategory = (subcategoryId: string) => {
  return axios.delete(SUBCATEGORY_DELETE_URL + subcategoryId);
};

export const addSubCategory = (payload: TSubCategoryPayload) => {
  return axios.post(SUBCATEGORY_ADD_URL, payload);
};

export const editSubCategory = (payload: TSubCategoryPayload, subcategoryId: string) => {
  return axios.patch(SUBCATEGORY_EDIT_URL + subcategoryId, payload);
};

export const ImageUpload = (payload: string[]) => {
  let data = new FormData();
  data.append("subcategoryImage", payload[0]);
  return axios.patch(SUBCATEGORY_IMAGE_URL, data);
};
export const AddDeleteImage = (imageId: any) =>{
  return axios.delete(`${SUBCATEGORY_IMAGE_DELETE}?imageId=${imageId}`);
}
export const EditDeleteImage = (subcategoryId: any) =>{
  return axios.delete( `${SUBCATEGORY_EDIT_IMAGE_DELETE}/${subcategoryId}`);
}