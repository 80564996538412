
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import MappingPage from "../generatedTablePage/MappingPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";

  

  
  
  const MappingRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<MappingPage/>}
  />   
      </Routes>
    );
  };
  export default MappingRoutes
