
      import React from 'react'
      import SubCategoryFormModal from '../../generatedFormModal/SubCategoryFormModal'
      


      const AddSubCategory=()=> {
        return (
          <>
           <SubCategoryFormModal isEdit={false} /> 
          </>
        )
      }
      
      export default AddSubCategory
         