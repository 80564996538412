
      import React from 'react'
      import AttributeFormModal from '../../generatedFormModal/AttributeFormModal'
      
      const initialValues={name:"",supported_units:"",status:""}

      const AddAttribute=({currentRecord}:{currentRecord?:any})=> {
        return (
          <div>
           <AttributeFormModal isEdit={false}/> 
          </div>
        )
      }
      
      export default AddAttribute
         