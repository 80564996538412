import React from 'react'
import { TCheckboxProps } from '../../types/Checkbox';


const Checkbox = ({ className, size,label, onChange,...props }: TCheckboxProps) => {
  switch(size){
    case"small": size = " form-check-sm "
    break;
    case"large": size = " form-check-lg "
    break;

}
  return (

    <label className={'form-check form-check-custom form-check-solid ' + className + size}>
      <input {...props} onChange={onChange} className='form-check-input me-2' type='checkbox'/>{label}
    </label>
  )
}

export default Checkbox