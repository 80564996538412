import SettingForm from "../generatedForms/SettingForm";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { addLanguage } from "../app/request/languageRequest";
import { WishSchema } from "../generatedValidations/WishSchema";
import { getProduct } from "../generatedRequest/product";
import { getCategory } from "../generatedRequest/category";
import { addMapping, MactchingAccessories } from "../generatedRequest/setting";

type TAddLanguageModalProps = {
  currentRecord: any;
  isEdit?: boolean;
};

const SettingFormModal = ({
  currentRecord,
  isEdit,
}: TAddLanguageModalProps) => {
  const [loading, setLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allAccessories, setAllAccessories] = useState([]);
  const [initialValues, setInitialValues] = useState({
    product: [],
    category: [],
    subcategory: [],
    accessories: [],
  });

  let title = isEdit ? "Edit" : "Add";

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const res = await addMapping(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Mapping is edited Successfully")
          : toast.success("Mapping is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getProduct();
        if (resp.data.status) {
          setAllProducts(resp.data.data.data);
          // setAllRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getCategory();
        if (resp.data.status) {
          setAllCategories(resp.data.data.data);
          // setAllRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: WishSchema,

    onSubmit: (values, { setSubmitting }) => {
      // setLoading(true);
      const fn = async () => {
        let payload = {
          productId: values.product?.[0]?.[`_id`],
          mappedCategory: {
            categoryId: values?.category?.[0]?.[`_id`],
            mappedSubcategories: [
              {
                subcategoryId: values?.subcategory?.[0]?.[`_id`],
                mappedAccessories: values?.accessories?.map((data: any) => {
                
                  return { accessoryId: data._id || data.id };
                }),
              },
            ],
          },
        };

        handleSubmit(payload);
      };
      fn();
    },
  });

  useEffect(() => {
    const fn = async () => {
      try {
        const categoryID = formik?.values?.category?.[0]?.[`_id`];
        const subcategoryID = formik?.values?.subcategory?.[0]?.[`_id`];
        if ((categoryID && subcategoryID != null) || undefined) {
          const resp = await MactchingAccessories(categoryID, subcategoryID);
          if (resp.data.status) {
            setAllAccessories(resp.data.data);
          }
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, [formik.values.category, formik.values.subcategory]);

  return (
    <>
      <div className="modal-header">
        <h2 className="modal-title fw-bolder">{title} Mapping</h2>
      </div>
      <div className="card-w-md-50">
        <SettingForm
          allProducts={allProducts}
          allAccessories={allAccessories}
          allCategories={allCategories}
          {...formik}
        />
        <div className="d-flex justify-content-end">
          <button
            type="button"
            id="kt_password_reset_submit"
            disabled={loading}
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
          >
            {!loading && <span className="indicator-label">Save</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SettingFormModal;
