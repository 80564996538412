import React, { useCallback, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { ActionCells } from '../../../components/ActionCells'
import useEdit from '../../../customHooks/useEdit/useEdit'
import { useDelete } from '../../../customHooks/useDelete/useDelete';
import ConfirmationPrompt from '../../../components/ConfirmationPrompt';
import TranslationFormModal from './TranslationFormModal';
import { deleteTranslation } from '../../request/translationRequest';
import { toast } from 'react-toastify';
import { RootState } from '../../../redux/store';
import {setRerender } from '../../../redux/slices/translationSlice'
import { TRowData, TTranslationActionProp } from '../../../types/Module/Translations/TranslationsList';




const TranslationActions = ({ translation }: TTranslationActionProp) => {
    const {rerender1}  = useSelector((state: RootState) => state.translation);
    const dispatch = useDispatch()
    const [showEdit, setShowEdit] = useState(false);
    const handleEdit = useCallback(() => {
        setShowEdit(true);
    }, [])
    const handleClose = () => {
        setShowEdit(false);
        dispatch(setRerender(!rerender1))
    }

    const { doEdit } = useEdit("", handleEdit);


    const handleDelete = useCallback((status: boolean, res: TRowData) => {
        if (status) {
            toast.success("deleted successfully");
            dispatch(setRerender(!rerender1))
        } else {
            //TODO: make error when go live
            toast.success("deleted successfully");
        }
    }, [rerender1,dispatch])

    const { onDelete, showPrompt, loading, deleteRecord, onCancel } = useDelete(deleteTranslation, true, handleDelete)
    let prompt = null;
    if (showPrompt) {
        prompt = <ConfirmationPrompt title='Delete' message='Are you sure you want to delete this item?' onCancel={onCancel} onConfirm={deleteRecord} isloading={loading} />
    }
    let editPrompt = null
    if (showEdit) {
        editPrompt = <TranslationFormModal onClose={handleClose} currentRecord={translation} />
    }
    return (
        <>
            <ActionCells
                title='Actions'
                actions={["Edit", "Delete"]}
                onSelect={(action: string) => {
                    switch (action) {
                        case "Edit": doEdit(translation.id); break;
                        case "Delete": onDelete(translation.id); break;
                    }

                }} />
            {prompt}
            {editPrompt}
        </>

    )
}

export default TranslationActions