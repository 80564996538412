import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Xarrow from "react-xarrows";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteMapping } from "../../generatedRequest/mapping";
import { setMappingRerender, setRerender } from "../../generatedSlice/mappingSlice";
import MappingFormFormModal from "../../generatedFormModal/MappingFormModal";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { useDelete } from "../../customHooks/useDelete/useDelete";
import ConfirmationPrompt from "../ConfirmationPrompt";

const inputWrapperStyle = {
  border: "1px solid black",
  position: "relative",
  padding: "20px 10px",
  margin: "10px",
};

const InputWrapper = ({
  text,
  handler,
  addArrow,
  inputId,
}: {
  text: any;
  handler: any;
  addArrow?: any;
  inputId: any;
}) => {
  const ref0 = useRef();
  return (
    <div
      id={inputId}
      ref={ref0 as any}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        if (e.dataTransfer.getData("arrow") === inputId) {
          console.log(e.dataTransfer.getData("arrow"), inputId);
        } else {
          const refs = { start: e.dataTransfer.getData("arrow"), end: inputId };
          addArrow(refs);
          console.log("dropped!", refs);
        }
      }}
    >
      <input
        type="text"
        placeholder={text}
        style={{
          padding: "8px 15px",
          boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
          border: "1px solid transparent",
          marginBottom: "10px",
          fontSize: "16px",
        }}
      />
    </div>
  );
};

export function CardForMapping({
  productSubcategory,
  accessorySubcategory,
  connections,
  mappingId,
}: {
  productSubcategory: any;
  accessorySubcategory: any;
  connections: any[];
  mappingId: string;
}) {
  const [arrows, setArrows] = useState<any[]>([]);
  const { rerender1 } = useSelector((state: RootState) => state.mapping);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [RecordToEdit, setRecordToEdit] = useState<any>({});

  const handleAfterDelete = useCallback(
    (flag: boolean, response: any) => {
      if (flag) {
        toast.success("Mappings has been deleted successfully");
        dispatch(setRerender(!rerender1));
      } else {
        toast.error("error");
      }
    },
    [rerender1,dispatch]
  );

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteMapping,
    true,
    handleAfterDelete
  );

  useEffect(() => {
    setArrows(connections);
  }, [connections]);

  // const handleDeleteMapping = async (id: string) => {
  //   try {
  //     const response = await deleteMapping(id);
  //     if (response?.data?.status) {
  //       toast.success("Record Deleted");
  //       dispatch(setRerender(!rerender1));
  //     } else {
  //       toast.error(response?.data?.message);
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   }
  // };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(setRerender(!rerender1));
  };

  const handleEditModal = () => {
    let record = {
      productSubcategory: productSubcategory,
      accessorySubcategory: accessorySubcategory,
      connections: connections,
      id: mappingId,
    };
    setRecordToEdit(record);
    setOpenModal(true);
  };

  return (
    <>
      <div className="cardForMapping">
        <div className="infoForMapping">
          <h2>{productSubcategory?.subcategoryName}</h2>
          <h2>{accessorySubcategory?.subcategoryName}</h2>
          <div className="action-icons">
            <Button
              style={{ padding: "5px" }}
              onClick={() => onDelete(mappingId)}
            >
              <img
                src={toAbsoluteUrl("/media/icons/duotune/art/delete-icon.png")}
                alt="delete"
              />
            </Button>
            <Button onClick={handleEditModal} style={{ padding: "5px" }}>
              <img
                src={toAbsoluteUrl("/media/icons/duotune/art/edit-icon.png")}
                alt="edit"
              />
            </Button>
          </div>

          {/* <p>{text}</p>
          <a href="/" className="link">{linkTitle}</a> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px 30px 20px",
            minHeight: "600px",
          }}
        >
          <div>
            {productSubcategory?.supportedAttributes?.map(
              (attribute: any, index: number) => (
                <InputWrapper
                  key={attribute?._id + productSubcategory?._id}
                  text={attribute?.attributeName}
                  {...{
                    handler: "middle",
                    inputId: `${
                      attribute?._id + productSubcategory?._id + mappingId
                    }`,
                  }}
                />
              )
            )}
          </div>
          <div>
            {accessorySubcategory?.supportedAttributes?.map(
              (attribute: any, index: number) => (
                <InputWrapper
                  key={attribute?._id + accessorySubcategory?._id}
                  text={attribute?.attributeName}
                  {...{
                    handler: "middle",
                    inputId: `${
                      attribute?._id + accessorySubcategory?._id + mappingId
                    }`,
                  }}
                />
              )
            )}
          </div>
          {arrows?.map((ar, index) => (
            <Xarrow
              start={ar.start}
              end={ar.end}
              key={`arrow-${index}`}
              color={ar.color}
              labels="test"
              animateDrawing={true}
              // passProps={{
              //   onClick: () => removeArrow(index),
              //   cursor: "pointer",
              // }}
            />
          ))}
        </div>
      </div>
      {openModal && (
        <MappingFormFormModal
          currentRecord={RecordToEdit}
          isEdit={true}
          onClose={handleCloseModal}
        />
      )}
      {showPrompt && (
          <ConfirmationPrompt
            title={"Delete Mappings"}
            message={"Are you sure to delete this mapping?"}
            onConfirm={deleteRecord}
            onCancel={onCancel}
            isloading={loading}
          />
        )}
    </>
  );
}
