import { FC } from "react";
import RegionFormModal from "../../../generatedFormModal/RegionFormModal";

const EditRegion: FC = () => {
  return (
    <>
      <RegionFormModal isEdit={true} />
    </>
  );
};

export default EditRegion;
