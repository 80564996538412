import { TConfig } from "../../types/Forms";
import { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  editUser,
  getRoles,
  getUserData,
} from "../../app/modules/auth/core/_requests";
import moment from "moment";
// import 'react-tabs/style/react-tabs.scss';
import { FiArrowLeft } from "react-icons/fi";
import Overview from "../../app/pages/overview/overview";
import { ToastContainer, toast } from "react-toastify";
import PermissionTab from "../../app/pages/permissions/permissions";
import Security from "../../app/pages/edit/security/securtiy";
import { addUser } from "../../app/modules/auth/core/_requests";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Direction } from "react-toastify/dist/utils";
import { useFormik } from "formik";
import { userSchema } from "../../utils/validationSchema/userSchema";
import { Console } from "console";
import { boolean } from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  TInitialValuesState,
  TModulePermission,
  TRoleData,
  TRoleOption,
  TUserConfig,
  TUserPayload,
} from "../../types/Module/Users & Permissons/FormView1";
import { getRegion } from "../../app/request/regionRequest";
import { getAllRetailerData } from "../../generatedRequest/retailer";
import Multiselect from "multiselect-react-dropdown";
import CustomArrow from "../../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../../utils/CustomStyleComponent/TooltipComponent";

const FormView1 = ({ url, isEdit }: TUserConfig) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState<any>({});
  const [userRole, setUserRole] = useState<TRoleOption[]>([]);
  const [roleData, setRoleData] = useState<TRoleData[]>([]);
  const { roleRender } = useSelector((state: RootState) => state.user);
  const [allRegions, setAllRegions] = useState([]);
  const [allRetailerData, setAllRetailerData] = useState([]);
  const [initialValues, setInitialValues] = useState<TInitialValuesState>({
    overview: {
      firstName: "",
      lastName: "",
      email: "",
      department: { value: "Marketing", label: "Marketing" },
      contact: "",
      password: "",
      confirmpassword: "",
      region: [],
      isUserForRetailer: false,
      retailerForThisUser: "",
      role: { label: "", value: "" },
    },
    permissions: [
      {
        modulename: "user",
        modulevalue: "user",
        add: false,
        addLabel: "Add user",
        edit: false,
        editLabel: "Edit user",
        delete: false,
        deleteLabel: "delete user",
      },
      {
        modulename: "language",
        modulevalue: "language",
        add: false,
        addLabel: "Add language",
        edit: false,
        editLabel: "Edit language",
        delete: false,
        deleteLabel: "Delete language",
      },
      {
        modulename: "translation",
        modulevalue: "translation",
        add: false,
        addLabel: "Add translation",
        edit: false,
        editLabel: "Edit translation",
        delete: false,
        deleteLabel: "Delete translation",
      },
      {
        modulename: "region",
        modulevalue: "region",
        add: false,
        addLabel: "Add region",
        edit: false,
        editLabel: "Edit region",
        delete: false,
        deleteLabel: "Delete region",
      },
      {
        modulename: "unit",
        modulevalue: "unit",
        add: false,
        addLabel: "add unit",
        edit: false,
        editLabel: "edit unit",
        delete: false,
        deleteLabel: "delete unit",
      },
      {
        modulename: "attribute",
        modulevalue: "attribute",
        add: false,
        addLabel: "add attribute",
        edit: false,
        editLabel: "edit attribute",
        delete: false,
        deleteLabel: "delete attribute",
      },
    ],
  });
  const [queryParameters] = useSearchParams();
  const userID = queryParameters.get("id") || "";
  const navigate = useNavigate();

  console.log("userData", userData);

  const handleSubmit = async (payload: TUserPayload) => {
    setLoading(true);
    try {
      const { data: response } = isEdit
        ? await editUser(payload, userID)
        : await addUser(payload);
      if (response.status) {
        isEdit
          ? toast.success("User is edited successfully")
          : toast.success(
              "User is added successfully, please check your email"
            );
        setLoading(false);
      } else {
        isEdit
          ? toast.error(`${response.message}`)
          : toast.error(`${response.message}`);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await getUserData(userID); // Call your API to get user data
        if (response.data.status) {
          setUserData(response.data.data); // Save the data from the API in state
          setLoading(false);
        } else {
          setLoading(false);
          setHasErrors(true);
          toast.error(`user not found`);
        }
      } catch (error) {
        setLoading(false);
        setHasErrors(true);
        toast.error(`Network error`);
      }
    };

    if (isEdit) {
      fetchUserData();
    }
  }, [isEdit]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getRoles();
        // Access the response data
        if (response.data.status) {
          setRoleData(response.data.data.data);
          const roleObject = response.data.data.data.map(
            ({ roleName, _id }: { roleName: string; _id: string }) => ({
              label: roleName,
              value: roleName,
              id: _id,
            })
          );
          setUserRole(roleObject); // Save the data from the API in state
          !isEdit && formik.setFieldValue("overview.role", roleObject[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoles();
  }, [roleRender]);

  // Update initialValues with data from the API if available
  // useEffect(() => {
  //   if (userData && isEdit) {
  //     formik.setValues(userData);
  //     formik.setFieldValue("overview.role", {
  //       value:
  //         userData && userData.overview && userData.overview?.role?.roleName,
  //       label:
  //         userData && userData.overview && userData.overview?.role?.roleName,
  //       id: userData && userData.overview && userData.overview?.role?._id,
  //     });
  //     formik.setFieldValue("overview.department", {
  //       value: userData && userData.overview && userData.overview.department,
  //       label: userData && userData.overview && userData.overview.department,
  //     });
  //     formik.setFieldValue("overview.region", userData.overview?.region);
  //     formik.setFieldValue(
  //       "overview.retailerForThisUser",
  //       userData.overview?.retailerForThisUser?._id
  //     );

  //     formik.setFieldValue(
  //       "permissions",
  //       generatePermissions(
  //         userData && userData.permissions && userData.permissions
  //       )
  //     );
  //   }
  // }, [userData]);

  useEffect(() => {
    if (userData && isEdit) {
      const regionIds = userData?.overview?.region?.map((r: any) => r._id) || [];

      formik.setValues({
        ...formik.values,
        overview: {
          ...formik.values.overview,
          firstName: userData.overview?.firstName,
          lastName: userData.overview?.lastName,
          email: userData.overview?.email,
          department: {
            value: userData.overview?.department,
            label: userData.overview?.department,
          },
          contact: userData.overview?.contact,
          region: regionIds || [],
          isUserForRetailer: userData.overview?.isUserForRetailer || false,
          retailerForThisUser:
            userData.overview?.retailerForThisUser?._id || null,
          role: {
            value: userData.overview?.role?.roleName,
            label: userData.overview?.role?.roleName,
            id: userData.overview?.role?._id,
          },
        },
        permissions: generatePermissions(userData.permissions),
      });
    }
  }, [userData]);
  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };

  const generatePermissions = (permissions: string[]) => {
    if (!permissions || permissions.length <= 0) {
      return [];
    }
    const output: Record<string, any> = {};
    permissions.forEach((item: any) => {
      // const [moduleName, action] = item.split(":");
      // const moduleValue = moduleName;
      const moduleName = item?.moduleName;
      const moduleValue = moduleName;

      if (!output[moduleName]) {
        output[moduleName] = {
          modulename: moduleName,
          modulevalue: moduleValue,
          add: false,
          addLabel: `Add ${moduleValue}`,
          edit: false,
          editLabel: `Edit ${moduleValue}`,
          delete: false,
          deleteLabel: `Delete ${moduleValue}`,
        };
      }
      const actions = ["add", "edit", "delete"];
      actions.forEach((action) => {
        output[moduleName][action] = item[action];
      });
      //output[moduleName][action] = true;
    });

    const finalOutput = Object.values(output);
    return finalOutput;
  };

  const generatePayload = (module: TModulePermission) => {
    const payload = [];
    if (module.add) {
      payload.push(`${module.modulevalue}:add`);
    }
    if (module.edit) {
      payload.push(`${module.modulevalue}:edit`);
    }
    if (module.delete) {
      payload.push(`${module.modulevalue}:delete`);
    }
    return payload;
  };

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("Enter Your Details");

  const formik = useFormik<TInitialValuesState>({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      const response = values.permissions.reduce((acc: any, module: any) => {
        const payload = generatePayload(module);
        return acc.concat(payload);
      }, []);

      let payload = {
        overview: {
          ...(isEdit && values.overview),
          firstName: values.overview.firstName,
          lastName: values.overview.lastName,
          email: values.overview.email,
          department: values.overview.department.value.toLowerCase(),
          contact: values.overview.contact,
          region: values.overview.region,
          isUserForRetailer: values.overview.isUserForRetailer,
          ...(values.overview.isUserForRetailer !== false && {
            retailerForThisUser: values.overview.retailerForThisUser,
          }),
          //retailerForThisUser: values.overview.retailerForThisUser,
          role: roleData
            .filter(
              (data: TRoleData) => data.roleName === values.overview.role.value
            )
            .map((role: TRoleData) => role?._id)[0],
        },
        permissions: response,
      };
      handleSubmit(payload);
    },
  });

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getRegion(`page=1&limit=100`);
        if (resp.data.status) {
          setAllRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getAllRetailerData(
          formik?.values?.overview?.region[0]
        );
        if (resp.data.status) {
          setAllRetailerData(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, formik?.values?.overview?.region);

  return (
    <div>
      <div className="container ms-1">
        <div className="row">
          <div className="col-md-8">
            <div className="card p-5">
              <div>
                <div className="card-header p-0 border-1 cursor-pointer">
                  <div className="card-title m-0">
                    <div
                      role={"button"}
                      className="btn btn-sm btn-light me-5"
                      onClick={() => navigate("/users")}
                    >
                      <i className="fa-solid fa-arrow-left fs-2"></i>
                    </div>
                    <h3 className="fw-bolder m-0">
                      {isEdit ? "Edit" : "Add"} User
                    </h3>
                  </div>
                </div>
                <Overview
                  isEdit={isEdit}
                  isLoading={loading}
                  message={message}
                  allRegions={allRegions}
                  values={formik.values.overview}
                  roles={userRole}
                  roleData={roleData}
                  handleSubmit={handleSubmit}
                  touched={formik.touched}
                  errors={formik.errors}
                  getFieldProps={formik.getFieldProps}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                  isSubmitting={formik.isSubmitting}
                  allRetailers={allRetailerData}
                />
              </div>
              {/* <div className="d-flex flex-column flex-md-row mt-5 mb-3 w-100 w-md-100 justify-content-end">
                <form onSubmit={formik.handleSubmit}>
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        {isEdit ? "Save" : "Continue"}
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </form>
              </div> */}
            </div>
          </div>
          <div className="col-md-4 mt-md-0 mt-5 p-0">
            <div className="container">
              <div className="card p-5">
                <div className="d-flex flex-column flex-md-column w-md-50 w-100">
                  <label className="form-label fs-6 fw-bolder text-dark d-flex cursor-pointer mb-5">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      name={"isUserForRetailer"}
                      disabled={formik.isSubmitting}
                      checked={formik.values.overview.isUserForRetailer}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "overview.isUserForRetailer",
                          e.target.checked
                        );
                      }}
                    />
                    <h6 className="mb-0 fs-6 fw-bolder">
                      Is Regional Retailer
                    </h6>
                  </label>
                </div>
                <div className="d-flex flex-column flex-md-column w-100 me-md-10 ">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Retailer List
                  </label>
                  <div>
                    <Multiselect
                      options={allRetailerData}
                      style={style}
                      disable={!formik.values.overview.isUserForRetailer}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      selectedValues={allRetailerData.filter(
                        (retailer: any) =>
                          retailer._id ==
                          formik.values.overview.retailerForThisUser
                      )}
                      selectionLimit={1}
                      displayValue={"retailerName"}
                      placeholder="Select Retailer"
                      onSelect={async (e) => {
                        await formik.setFieldValue(
                          "overview.retailerForThisUser",
                          e?.[`0`]?._id
                        );
                        formik.setFieldTouched("overview.retailerForThisUser");
                      }}
                      onRemove={async (e) => {
                        await formik.setFieldValue(
                          "overview.retailerForThisUser",
                          e
                        );
                        formik.setFieldTouched("overview.retailerForThisUser");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {/* {formik.touched?.overview?.region && formik.errors?.overview?.region && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.overview?.region}</span>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row mt-5 mb-3 w-100 w-md-100 justify-content-end">
            <form onSubmit={formik.handleSubmit}>
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {isEdit ? "Save" : "Continue"}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormView1;
