import axios  from "axios";
import { TRequestAccessModel } from "../../types/Network_ Request/permissionRequest/requestAccess";

const API_URL = process.env.REACT_APP_API_URL;

//TODO : make it dynamic url when go live
export const REQUEST_PERMISSION_MODULE_URL = `${API_URL}/permissions`;


export function requestAccess(payload: object) {
    //TODO : make it post when go live
    return axios.post<TRequestAccessModel>(REQUEST_PERMISSION_MODULE_URL,{ 
      ...payload
  });
  }