import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import MultipleCheckBoxSkeleton from "../../../components/Skeletons/MultipleCheckBox";
import { TLanguageForm1Props, TLanguageItem } from "../../../types/Module/Language/LanguageForm1";

const LanguageForm1 = ({
 isSubmitting,
 values,
 isLoadingLanguages,
 handleChange,
}: TLanguageForm1Props) => {
  const { searchTermFormGoogleLang } = useSelector(
    (state: RootState) => state.language
  );

  // Filter the languages based on the search term while preserving the original index
  const filteredLanguages: TLanguageItem[] = values
    .map((language: TLanguageItem, index: number) => ({ ...language, index })) // Store the original index
    .filter((language: TLanguageItem) =>
      `${language.languageName}`
        .toLocaleLowerCase()
        .includes(searchTermFormGoogleLang.toLocaleLowerCase())
    );

  const unfilteredLanguages =
    searchTermFormGoogleLang && searchTermFormGoogleLang.split("").length > 1
      ? values
          .map((language: TLanguageItem, index: number) => ({ ...language, index })) // Store the original index
          .filter(
            (language: TLanguageItem) =>
              !`${language.languageName}`
                .toLocaleLowerCase()
                .includes(searchTermFormGoogleLang.toLocaleLowerCase())
          )
      : values.map((language: TLanguageItem, index: number) => ({ ...language, index }));

  if (isLoadingLanguages) {
    return <MultipleCheckBoxSkeleton checkbox={60} />;
  }

  return (
    <>
      {searchTermFormGoogleLang &&
        searchTermFormGoogleLang.split("").length > 0 && (
          <div style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "block" }}>Search Results({filteredLanguages.length})</div>
            <div className="d-flex flex-column flex-md-row flex-md-wrap w-100 w-md-100 pt-1 pe-1">
              {filteredLanguages.map((language: TLanguageItem) => {
                const addLanguage = `[${language.index}].isSelected`; // Use the original index
                return (
                  <div
                    className="d-flex flex-column flex-md-column p-3"
                    key={language.code}
                    style={{ width: "150px" }}
                  >
                    <div>
                      <div className="d-flex card-body flex-column w-100">
                        {language.languageName && (
                          <div className="d-flex flex-row mb-3">
                            <label className="form-check form-check-custom form-check-solid form-label fs-7 cursor-pointer">
                              <input
                                className="form-check-input me-3"
                                type="checkbox"
                                disabled={isSubmitting}
                                name={addLanguage}
                                checked={language.isSelected}
                                onChange={handleChange}
                              />
                              {language.languageName}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

      <div
        className="d-flex flex-column flex-md-row flex-md-wrap w-100 w-md-100 pt-1 pe-1"
        style={{ minHeight: "50vh" }}
      >
        {unfilteredLanguages.map((language: TLanguageItem) => {
          const addLanguage = `[${language.index}].isSelected`; // Use the original index
          return (
            <div
              className="d-flex flex-column flex-md-column p-3"
              key={language.code}
              style={{ width: "150px" }}
            >
              <div>
                <div className="d-flex card-body flex-column w-100">
                  {language.languageName && (
                    <div className="d-flex flex-row mb-3">
                      <label className="form-check form-check-custom form-check-solid form-label fs-7 cursor-pointer">
                        <input
                          className="form-check-input me-3"
                          type="checkbox"
                          name={addLanguage}
                          disabled={isSubmitting}
                          checked={language.isSelected}
                          onChange={handleChange}
                        />
                        {language.languageName}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LanguageForm1;
