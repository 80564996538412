
  import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const PERMISSION_GET_URL = `${BASE_URL}/permissions`;
const PERMISSION_DELETE_URL = BASE_URL +"/Permissions/";
const PERMISSION_ADD_URL = `${BASE_URL}/permissions/`;
const PERMISSION_EDIT_URL = BASE_URL +"/Permissions/";


export const getPermission = (searchParams?: string) => {
  return axios.get(PERMISSION_GET_URL + "?q" + searchParams);
};

export const deletePermission = (Id: string) => {
  return axios.delete(PERMISSION_DELETE_URL +Id);
};

export const addPermission = (Id: string) => {
    return axios.post(PERMISSION_ADD_URL + Id);
  };

  export const editPermission = (Id: string) => {
    return axios.put(PERMISSION_EDIT_URL + Id);
  };


  