import * as Yup from "yup";

export const userSchema = Yup.object().shape({
  overview: Yup.object().shape({
    firstName: Yup.string()
      .min(1, "Minimum 3 characters.")
      .max(50, "Maximum 50 characters.")
      .required("First name is required."),
    region: Yup.array().of(
      Yup.string().required("Each region must be a string")
    ),
    email: Yup.string()
      .email("Wrong email format.")
      .min(3, "Minimum 3 characters.")
      .max(50, "Maximum 50 characters.")
      .required("Email is required."),
    lastName: Yup.string()
      .min(1, "Minimum 3 characters.")
      .max(50, "Maximum 50 characters.")
      .required("Last name is required."),
    contact: Yup.number()
      .typeError("Phone number must be a number.")
      .min(1000000000, "Minimum 10 digits.")
      .max(9999999999, "Maximum 10 digits.")
      .required("Phone number is required."),
    // password: Yup.string()
    // .min(3, 'Minimum 3 characters.')
    // .max(50, 'Maximum 50 characters.')
    // .required('Password is required.'),
    // confirmpassword: Yup.string()
    // .required('Confirm password is required.')
    // .when('overview', {
    //   is: (val: string) => ((val && val.length > 0 )|| (!val) ? true : false),
    //   then: Yup.string().oneOf([Yup.ref('overview')], "Password and Confirm Password didn't match."),
    // }),
  }),
});

// const validationSchema = Yup.object().shape({
//   overview: Yup.object().shape({
//     firstName: Yup.string().required('First name is required'),
//     lastName: Yup.string().required('Last name is required'),
//     email: Yup.string().email('Invalid email').required('Email is required'),
//     department: Yup.string().required('Department is required'),
//     role: Yup.object().shape({
//       value: Yup.string().required('Role value is required'),
//       label: Yup.string().required('Role label is required'),
//     }),
//     contact: Yup.string().required('Contact is required'),
//     password: Yup.string().required('Password is required'),
//   }),
