import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import { MODULE_DELETE_URL } from "../app/request/userRequest";
import { TModuleDelete } from "../types/Network_ Request/moduleWiseApiType/deleteApi";
import { TTableAction } from "../types/TableActions";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { useStatus } from "../customHooks/useStatus/useStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  getScrappingStatusForSingle,
  updateStatus,
} from "../generatedRequest/Scrapping";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/ScrappingSlice";
import { useStatusCheck } from "../customHooks/useStatusCheck/useStatusCheck";

const moduleName = "Scrapping";

const ScrappingTableAction = ({ scrappingManufacturer }: any) => {
  const { doEdit } = useEdit(moduleName);
  const { rerender1 } = useSelector((state: RootState) => state.Scrapping);

  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const { canDo, currentActivity } = usePermissions();

  const actions = ["Change Status", "Check Status"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Change Status":
          onStatus(scrappingManufacturer);
          break;
        case "Check Status":
          onstatusCheck(scrappingManufacturer);
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, scrappingManufacturer]
  );

  const handleAfterStatus = useCallback(
    (status: boolean, res: any) => {
      console.log(status, res);
      if (status) {
        toast.success("status updated successfully");
        dispatch(setRerender(!rerender1));
      } else {
        //TODO: make error when go live
        toast.error("something went wrong");
      }
    },
    [rerender1, dispatch]
  );

  const handleAfterStatusCheck = useCallback(
    (status: boolean, res: any) => {
      console.log(status, res);
      if (status) {
        toast.success("status checked successfully");
        dispatch(setRerender(!rerender1));
      } else {
        //TODO: make error when go live
        toast.error("something went wrong");
      }
    },
    [rerender1, dispatch]
  );

  const {
    onStatus,
    showStatusPrompt,
    statusloading,
    statusToUpdate,
    onstatusCancel,
    ChangeStatus,
  } = useStatus(updateStatus, true, handleAfterStatus);

  const {
    onstatusCheck,
    showstatusCheckPrompt,
    statusCheckloading,
    statusCheckToUpdate,
    onstatusCheckCancel,
    ChangestatusCheck,
  } = useStatusCheck(getScrappingStatusForSingle, true, handleAfterStatusCheck);

  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}

      {showStatusPrompt && (
        <ConfirmationPrompt
          title="Change Status"
          message="Are you sure you want to change the status ?"
          onCancel={onstatusCancel}
          onConfirm={ChangeStatus}
          isloading={statusloading}
        />
      )}

      {showstatusCheckPrompt && (
        <ConfirmationPrompt
          title="Change if Scapping is Working or Not"
          message="Are you sure you want to change the status ?"
          onCancel={onstatusCheckCancel}
          onConfirm={ChangestatusCheck}
          isloading={statusCheckloading}
        />
      )}

      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default ScrappingTableAction;
