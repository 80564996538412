import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { TPermissionDeniedProps } from "../../types/PemissionDenied";
import Button from "../Button";

const PemissionDenied = ({
  title,
  text,
  image,
  buttonText,
  handleButtonClick,
}: TPermissionDeniedProps) => {
  return (
    <>
      <div className="d-flex flex-column ">
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            {/* begin::Title */}
            <h1 className="fw-bolder fs-2qx text-gray-900 mb-4">{title}</h1>

            {/* end::Title */}

            {/* begin::Text */}
            <div className="fw-semibold fs-6 text-red-500 mb-7">{text}</div>
            {/* end::Text */}

            {/* begin::Illustration */}
            <div className="mb-11">
              <img
                src={toAbsoluteUrl(`${image}`)}
                className="mw-100 mh-300px theme-light-show"
                alt=""
              />
            </div>
            {/* end::Illustration */}

            {/* begin::Link */}
            <div className="mb-0">
              <Button
                variant="light-primary"
                handleButtonClick={handleButtonClick}
              >
                {buttonText}
              </Button>
            </div>
            {/* end::Link */}
          </div>
        </div>
      </div>
    </>
  );
};

export { PemissionDenied };
