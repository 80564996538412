import * as Yup from "yup";

export const attributeSchema = Yup.object().shape({
  attributeName: Yup.string()
    .min(2, "Minimum 2 characters.")
    .max(50, "Maximum 50 characters.")
    .required("Name is required."),
    supportedUnits: Yup.array()
    .min(1, "At least one unit is required."),
    status: Yup.object().shape({
      value: Yup.string().oneOf(["active","inactive"], "Invalid status value."),
      label: Yup.string().oneOf(["Active","Inactive"], "Invalid status label.")
    }).required("Status is required."),
});

