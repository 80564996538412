import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const PRODUCT_GET_URL = BASE_URL + "/product";
const PRODUCT_DELETE_URL = BASE_URL + "/product/";
const PRODUCT_ADD_URL = BASE_URL + "/product/";
const PRODUCT_EDIT_URL = BASE_URL + "/product/";
const PRODUCT_IMAGE_UPLOAD = BASE_URL + "/product/uploadImage";
const PRODUCT_SCRAPE_URL = BASE_URL + `/product/scrapping`;
const PRODUCT_EXCEL_UPLOAD_URL = BASE_URL + `/product/document/save/product`;
const ACCESSORY_FILE_UPLOAD = BASE_URL + "/accessory/document/upload";
const PRODUCT_DATA_VERIFY_URL = BASE_URL + "/product/verify/attributeData";
const PRODUCT_IMAGE_DELETE = BASE_URL + "/product/deleteImageAdd";
const PRODUCT_EDIT_IMAGE_DELETE = BASE_URL + "/product/deleteImage";

export const getProduct = (searchParams?: string) => {
  return axios.get(PRODUCT_GET_URL + "?" + searchParams);
};

export const getSingleProduct = (productid: string) => {
  return axios.get(PRODUCT_GET_URL + "/" + productid);
};

export const deleteProduct = (productid: string) => {
  return axios.delete(PRODUCT_DELETE_URL + productid);
};

export const addProduct = (payload: any) => {
  return axios.post(PRODUCT_ADD_URL, payload);
};

export const editProduct = (payload: any, productid: string) => {
  return axios.patch(PRODUCT_EDIT_URL + productid, payload);
};

export const scapeProduct = (payload: any) => {
  return axios.post(PRODUCT_SCRAPE_URL, payload);
};

export const ProductImageUpload = (payload: any) => {
  let data = new FormData();
  payload &&
    payload.forEach((item: any, i: number) => {
      data.append(`productImages`, item);
    });
  return axios.patch(PRODUCT_IMAGE_UPLOAD, data);
};



export const AddDeleteImage = (imageId: any) =>{
  return axios.delete(`${PRODUCT_IMAGE_DELETE}?imageId=${imageId}`);
}
export const EditDeleteImage = (productId: any, imageId:any) =>{
  return axios.delete( `${PRODUCT_EDIT_IMAGE_DELETE}/${productId}?imageId=${imageId}`);
}

export const updateStatus = (product: any) => {
  const url = PRODUCT_EDIT_URL + product._id;
  const newStatus = product.status == "active" ? "inactive" : "active";
  const payload = {
    status: newStatus,
  };

  if (product._id) {
    return axios.patch(url, payload);
  }
};

//upload Product Excel Sheet

export const FileUpload = (payload: any) => {
  let data = new FormData();
  payload &&
    payload.forEach((item: any, i: number) => {
      data.append(`file`, item);
    });
  return axios.post(ACCESSORY_FILE_UPLOAD, data);
};

export const uploadProductExcel = (payload: any) => {
  let data = new FormData();
  data.append(`file`, payload.files[0]);
  data.append(`regions`, payload.regions);
  data.append(`category`, payload.category);
  data.append(`subcategory`, payload.subcategory);
  payload &&
    payload?.attributesArray.forEach((item: any, i: number) => {
      data.append(`attributesArray`, JSON.stringify(item));
    });
  return axios.post(PRODUCT_EXCEL_UPLOAD_URL, data);
};


export const verifyDataUsingGpt = (payload: any) => {
  return axios.post(PRODUCT_DATA_VERIFY_URL, payload);
};