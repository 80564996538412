
      import React from 'react'
      import CategoryFormModal from '../../generatedFormModal/CategoryFormModal'

     
      
      const EditCategory=()=> {
        return (
          <div>
           <CategoryFormModal isEdit={true} 
            /> 
          </div>
        )
      }
      
      export default EditCategory

      