import { FC } from "react";
import UnitFormModal from "../../../generatedFormModal/UnitFormModal";

const AddUnit: FC = () => {
  return (
    <>
      <UnitFormModal isEdit={false} />
    </>
  );
};

export default AddUnit;
