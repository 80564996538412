import { Modal } from "react-bootstrap";
import { KTSVG } from "../../_metronic/helpers";
import "./detailPrompt.css";

type TModalProps = {
  title: string;
  // message: string;
  onCancel?: () => void;
  // onConfirm?: () => void;
  isloading?: boolean;
  data: any;
};

const DetailsPrompt = ({ title, onCancel, isloading, data }: TModalProps) => {
  console.log("data", data);
  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      show={true}
      dialogClassName="modal-md modal-dialog-centered"
      aria-hidden="true"
      onHide={onCancel}
    >
      <div className="modal-header">
        <h2 className="modal-title fw-bolder">{title}</h2>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onCancel}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body scroll-y">
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>
            <strong>Full Name: </strong> {data.fullName}
          </li>
          <li>
            <strong>Email: </strong> {data.email}
          </li>
          <li>
            <strong>Phone: </strong> {data.phone}
          </li>
          <li>
            <strong>Address: </strong> {data.address}
          </li>
          <li>
            <strong>Post Code: </strong>
            {data.postCode}
          </li>
          <li>
            <strong>Country: </strong> {data.country}
          </li>
          <li>
            <strong>State: </strong> {data.state}
          </li>
          <li>
            <strong>Message: </strong> {data.message}
          </li>
          <li>
            <strong>Created At: </strong> {data.createdAt}
          </li>
          <li>
            <strong>Updated At: </strong> {data.updatedAt}
          </li>
        </ul>
      </div>
      {/* <div className='modal-footer'>
                <button type='button' className='btn btn-danger' onClick={onCancel}>
                    Close
                    
                </button>
                <button id='submit' type='button' className='btn btn-primary' onClick={onConfirm} disabled={isloading}>
                {!isloading && <span className="indicator-label">Confirm</span>}
          {isloading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
                </button>
            </div> */}
    </Modal>
  );
};

export default DetailsPrompt;
