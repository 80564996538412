import React from 'react'
import { number } from 'yup';
import { TTableColumn, TTableHeaderProps } from '../../types/TableView';


const TableHeader = ({ columns }: TTableHeaderProps) => {
    return (
        < thead >
            <tr>
                {columns.map((col: TTableColumn,index:number) => {
                    let className = " min-w-125px " + col.className;
                    return <th key={index}  className={col.name ==="name" ? className + " text-start" : className + " text-center"} onClick={() => { typeof col.onClick === "function" && col.onClick() }}>
                        {col.renderHeader && typeof col.renderHeader === "function" ? col.renderHeader(col.name) : col.name.toLocaleUpperCase()}
                    </th>
                })}
            </tr>
        </thead >

    )
}

export default TableHeader