import { useCallback, useEffect } from "react";
import TableView from "../components/TableView";
import PermissionTableAction from "../generatedTableActions/PermissionTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setPermission,
  setSearchTerm,
} from "../generatedSlice/PermissionSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getPermission } from "../generatedRequest/Permission";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import PermissionList from "../generatedTableList/PermissionList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    name: "Requested_By",
    renderHeader: (rows: any) => (
      <>
        <b>Requested_By</b>
      </>
    ),
    renderCell: (row: any) => <>{row.userId}</>,
  },
  {
    name: "Module",
    renderHeader: (rows: any) => (
      <>
        <b>Module</b>
      </>
    ),
    renderCell: (row: any) => <>{row.name}</>,
  },
  {
    name: "Activities",
    renderHeader: (rows: any) => (
      <>
        <b>Activities</b>
      </>
    ),
    renderCell: (row: any) => <>{row.activities}</>,
  },
  {
    name: "Created_At",
    renderHeader: (rows: any) => (
      <>
        <b>Created_At</b>
      </>
    ),
    renderCell: (row: any) => <>{row.createdAt}</>,
  },
  {
    name: "Status",
    renderHeader: (rows: any) => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (row: any) => <>{row.status}</>,
  },
  {
    name: "Actions",
    renderHeader: (rows: any) => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row: any) => <PermissionTableAction id={row._id} />,
  },
];

const PermissionPage = () => {
  const dispatch = useDispatch();

  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
  } = useSelector((state: RootState) => state.Permission);

  useEffect(() => {
    const fn = async () => {
      dispatch(setLoading(true));
      try {
        const response = await getPermission();
        if (response.data.status) {
          setTimeout(() => {
            dispatch(setPermission(response.data.data.data));
          }, 500);
        } else {
          setTimeout(() => {
            dispatch(setPermission([]));
          }, 500);
        }
      } catch (error) {
        toast.error("Network Error");
      }
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500);
    };
    fn();
  }, []);

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `${searchTerm}&currentpage=${currentPage}&perPage=${value}`;
      doSearch(searchQuery);
    },
    [dispatch, currentPage, perPage, searchTerm]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `${searchTerm}&currentpage=${value}&perPage=${perPage}`;
      doSearch(searchQuery);
    },
    [dispatch, currentPage, perPage, searchTerm]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `${value}&currentpage=${currentPage}&perPage=${perPage}`;
      doSearch(searchQuery);
    },
    [currentPage, perPage, dispatch]
  );

  const handleAfterSearch = useCallback((value: string) => {}, []);

  const { doSearch, searchLoading } = useSearchApi(
    getPermission,
    handleAfterSearch
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Permissions",
    searchLoading:searchLoading,
    showFilter:false
  };

  const navigate = useNavigate();

  if (loading) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Permissions</h2>
        <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add Permission
        </Button>
      </div>
      <PermissionList {...configuration} />
      {/* {searchLoading && (
        <TableLoader isLoading={searchLoading} loadingMessage={"loading"} />
      )} */}
    </>
  );
};

export default PermissionPage;
