
    import { TTableProps } from "../types/TableView";
    import TableView from "../components/TableView";
import EmptyState from "../components/EmptyState";

  const LeadList = ({ searchLoading,columns, rows, showPagination, showSearch, showPerPage, paginationData, onPageChange, onPerPageChange, handleSearchParams, searchPlaceholder }:TTableProps) => { 
    return (
    <div className='card'>
    <TableView
    columns={columns}
    rows={rows}
    showPagination={showPagination}
    showSearch={showSearch}
    showPerPage={showPerPage}
    paginationData={paginationData}
    onPageChange={onPageChange}
    onPerPageChange={onPerPageChange}
    handleSearchParams={handleSearchParams}
    searchPlaceholder={searchPlaceholder}
    searchComponent={<EmptyState                      
      title="Sorry"
      message="No Leads Found"
      buttonTitle="Reset"
      image="/media/misc/empty-State.svg"
    />}
    searchLoading={searchLoading}
    searchWidth={"250px"}
    cardHeaderStyle={"30px"}
  />
    </div>
  );
};

export default LeadList;
    