import React from "react";
import RetailerFormModal from "../../generatedFormModal/RetailerFormModal";


const AddRetailer = () => {
  return (
    <div>
      <RetailerFormModal isEdit={false} />
    </div>
  );
};

export default AddRetailer;
