import axios from "axios";


const BASE_URL = process.env.REACT_APP_API_URL;
const ACCESSORY_GET_URL = BASE_URL + "/accessory";
const ACCESSORY_DELETE_URL = BASE_URL + "/accessory/";
const ACCESSORY_ADD_URL = BASE_URL + "/accessory/";
const ACCESSORY_EDIT_URL = BASE_URL + "/accessory/";
const ACCESSORY_IMAGE_UPLOAD = BASE_URL + "/accessory/uploadImage";
const ACCESSORY_IMAGE_DELETE = BASE_URL + "/accessory/deleteImageAdd";
const ACCESSORY_EDIT_IMAGE_DELETE = BASE_URL + "/accessory/deleteImage";
const ACCESSORY_FILE_UPLOAD = BASE_URL + "/accessory/document/upload";
const ACCESSORY_EXCEL_ATTRIBUTE_UPLOAD = BASE_URL + "/accessory/document/save/accesory";
const ACCESSORY_MAPPING_STORE = BASE_URL + "/product/storefront";
const ACCESSORY_COMPATIBLE_URL = BASE_URL + "/accessory/accessories-mapping"
const ACCESSORY_ATTRIBUTE_URL = BASE_URL + "/accessory/convert-specifications"

export const getAccessory = (searchParams?: string) => {
  return axios.get(ACCESSORY_GET_URL + "?" + searchParams);
};

export const getStoreAccessory = (searchParams?: string) => {
  return axios.get(ACCESSORY_MAPPING_STORE + "/" + searchParams);
};

export const getSingleAccessory = (accessoryid: string) => {
  return axios.get(ACCESSORY_GET_URL + "/" + accessoryid);
};

export const deleteAccessory = (accessoryid: string) => {
  return axios.delete(ACCESSORY_DELETE_URL + accessoryid);
};

export const addAccessory = (payload: any) => {
  return axios.post(ACCESSORY_ADD_URL, payload);
};

export const editAccessory = (payload: any, accessoryid: string) => {
  return axios.patch(ACCESSORY_EDIT_URL + accessoryid, payload);
};

export const getMappedAccessory = (payload: any) => {
  return axios.post(ACCESSORY_COMPATIBLE_URL , payload);
};

export const AccessoryImageUpload = (payload: any) => {
  let data = new FormData();
  payload &&
    payload.forEach((item: any, i: number) => {
      data.append(`accessoryImages`, item);
    });
  return axios.patch(ACCESSORY_IMAGE_UPLOAD, data);
};

export const AddDeleteImage = (imageId: any) =>{
  return axios.delete(`${ACCESSORY_IMAGE_DELETE}?imageId=${imageId}`);
}
export const EditDeleteImage = (accessoryId: any, imageId: any) =>{
  return axios.delete( `${ACCESSORY_EDIT_IMAGE_DELETE}/${accessoryId}?imageId=${imageId}`);
}


export const FileUpload = (payload: any) => {
  let data = new FormData();
  payload &&
  payload.forEach((item: any, i: number) => {
    data.append(`file`, item);
  });
  return axios.post(ACCESSORY_FILE_UPLOAD, data);
};

export const uploadAccessoryExcel= (payload: any) => {
  let data = new FormData();

  console.log(payload.attributes,"hererererer")

  data.append(`file`,payload.files[0])
  data.append(`accessoryName`,payload.accessoryName)
  data.append(`region`,payload.regions)
  data.append(`brand`,payload.brand)
  data.append(`model`,payload.model)
  data.append(`modelNumber`,payload.modelNumber)
  data.append(`sku`,payload.sku)
  data.append(`regions`,payload.regions)
  data.append(`category`,payload.category)
  data.append(`subcategory`,payload.subcategory)
  // data.append(`attributes`,payload.attributes)
  payload &&
  payload?.attributes.forEach((item: any, i: number) => {
    console.log(item)
    data.append(`attributes`,JSON.stringify(item));
  });
  data.append(`description`,payload.description)
  

  return axios.post(ACCESSORY_EXCEL_ATTRIBUTE_UPLOAD, data);
};


export const updateStatus = (accessory:any) => {

  const url = ACCESSORY_EDIT_URL + accessory._id;
  const newStatus = accessory.status == "active" ? "inactive" : "active";
  const payload = {
    status: newStatus,
  };
  
  if (accessory._id) {
    return axios.patch(url, payload);
  }
};

export const getConvertedAttributeValue = (payload: any) => {
  return axios.post(ACCESSORY_ATTRIBUTE_URL, payload);
};
