import React, { useCallback, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import DropBox from "../components/DropBox";
import ImageGrid from "../components/ImageGrid";
import { TagBox, TagBoxItem } from "../components/TagBox/index";
import { AddDeleteImage, EditDeleteImage, ImageUpload } from "../generatedRequest/subcategory";
import { toast } from "react-toastify";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import {
  TSubCategoryFormProp,
  TSubcategoryImage,
} from "../types/Module/SubCategory/SubCategoryForm";
import {
  TImage,
  TSupportedValue,
} from "../types/Module/SubCategory/SubCategoryFormModal";

const SubCategoryForm = ({
  errors,
  isloading,
  isEdit,
  touched,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  allAttributes,
}: TSubCategoryFormProp) => {
  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };
  const navigate = useNavigate();
  let defaultImages = [] as TImage[];

  if (values?.images) {
    defaultImages = values?.images.map((t: TImage) => {
      return {
        id: t.id,
        url: t.url,
        type: "uploaded",
        file: null,
      };
    });
  }

  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [selectedFilesURL, setSelectedFilesURL] =
    useState<TImage[]>(defaultImages);


  const handleImageUrlDelete = useCallback(
    (index: number) => {
      const fn = async function () {
        let curRecord = values.images && values.images[index];
        if (curRecord?.type === "uploaded") {
        } else {
          setSelectedFilesURL(
            selectedFilesURL.filter((image, _index) => _index !== index)
          );
        }
      };
      fn();
    },
    [selectedFilesURL, setSelectedFilesURL, values]
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;    
    setFieldValue("isProductSubcategory", checked);
  };

  const handleCheckboxChangeForFit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;    
    setFieldValue("isShowFit", checked);
  };

  const handleDropFiles = useCallback(
    (files: string[]) => {
      const fn = async function () {
        const res = await ImageUpload(files);
        if (res.data.status) {
          let images = [res.data.data];
          setFieldValue("subcategoryImage", images);
          console.log(res, "response payload");
        } else {
          toast.error("Something went wrong");
        }
      };
      fn();
    },
    [selectedFilesURL, selectedFiles, values]
  );
  const handleSNewTag = (tagName: string) => {
    if (tagName) {
      const newTag: TagBoxItem = {
        name: tagName,
        id: Date.now().toString(), // convert the ID to a string
      };
      setFieldValue("notes", [...values.notes, newTag]);
    }
    // add the new tag to the list of tags
  };

  const handleSDeleteTag = (tag: TagBoxItem, index: number) => {
    const newTags = [...values.notes];
    newTags.splice(index, 1);
    setFieldValue("notes", newTags); // remove the tag at the specified index
  };

  const handleDelete = async (index: number) => {
    try {
        const { _id, subcategoryImage } = values;
        const res = isEdit
            ? await EditDeleteImage(_id)
            : await AddDeleteImage(subcategoryImage[0].subcategoryImage);

        if (res.data.status) {
           const temp = subcategoryImage;
           temp.splice(index, 1);
           setFieldValue("subcategoryImage", temp);
           toast.success("Image deleted succcessfully");
        } else {
            toast.error(res.data.message);
        }
    } catch (error:any) {
        console.error("Error during delete operation:", error);
        toast.error((error.response?.data?.message || error.message || "Unknown error"));
    } finally {
        // You can handle any cleanup here if needed, e.g., setLoading(false);
    }
};

  return (
    <div className="container ms-1">
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-1 cursor-pointer">
              <div className="card-title m-0">
                <div
                  role={"button"}
                  className="btn btn-sm btn-light me-5"
                  onClick={() => navigate("/subcategory")}
                >
                  <i className="fa-solid fa-arrow-left fs-2"></i>
                </div>
                <h3 className="fw-bolder m-0">
                  {isEdit ? "Edit" : "Add"} SubCategory
                </h3>
              </div>
            </div>
            <div className="card-body p-9">
              <div className="row mb-6">
                <label className="col-lg-2 col-form-label required fw-bold fs-6">
                  Name
                </label>
                <div className="col-lg-10">
                  <input
                    disabled={isloading}
                    placeholder="SubCategory Name"
                    {...getFieldProps("subcategoryName")}
                    className={clsx("form-control bg-transparent", {
                      "is-invalid":
                        touched?.categoryName && errors?.categoryName,
                    })}
                    type="text"
                  />
                  {touched?.subcategoryName && errors?.subcategoryName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.subcategoryName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-2 col-form-label required fw-bold fs-7">
                  Description
                </label>
                <div className="col-lg-10">
                  <textarea
                    rows={4}
                    disabled={isloading}
                    autoComplete="off"
                    placeholder="Description"
                    {...getFieldProps("description")}
                    className="form-control bg-transparent"
                  />
                  {touched?.description && errors?.description && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.description}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-2 col-form-label fw-bold fs-7">
                  notes
                </label>
                <div className="col-lg-10">
                  <TagBox
                    tags={values.notes || []}
                    handleNew={handleSNewTag}
                    handleDelete={handleSDeleteTag}
                    placeholder={"Notes"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-5 mb-xl-10">
            <div className="card-body">
              <div className="rounded border border-dotted border-primary border-4 p-4">
                <h5 className="fw-bolder mb-5">Images</h5>
                <DropBox onChange={handleDropFiles} multiple={false} />
                <ImageGrid
                  images={values.subcategoryImage.map(
                    (images: TSubcategoryImage) => images.subcategoryImageURL
                  )}
                  handleDelete={handleDelete}
                  name="somname"
                />
                {touched?.subcategoryImage && errors?.subcategoryImage && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {/* <span role="alert">{errors?.subcategoryImage}</span> */}
                      <span role="alert">
                        {typeof errors?.subcategoryImage === "string"
                          ? errors.subcategoryImage
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card p-10 mb-5">
            <div className="container p-0 m-0">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Attributes
                  </label>
                  <div onClick={() => setFieldTouched("supportedAttributes")}>
                    <Multiselect
                      options={allAttributes}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      style={style}
                      displayValue="attributeName"
                      placeholder="Select Attributes"
                      selectedValues={values.supportedAttributes}
                      onSelect={async (e) => {
                        await setFieldValue("supportedAttributes", e);
                        setFieldTouched("supportedAttributes");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("supportedAttributes", e);
                        await setFieldValue(
                          "compatibility",
                          values?.compatibility?.filter(
                            (compatibilityFilter: any) =>
                              e?.filter(
                                (data: any) =>
                                  data?.attributeName == compatibilityFilter
                              ).length > 0
                          )
                        );
                        await setFieldValue(
                          "filteredAttribute",
                          values?.filteredAttribute?.filter(
                            (singlefilterAttribute: any) =>
                              e?.filter(
                                (data: any) =>
                                  data?.attributeName == singlefilterAttribute
                              ).length > 0
                          )
                        );

                        await setFieldValue(
                          "filteredAttributesWithValue",
                          values?.filteredAttributesWithValue?.filter(
                            (filterAttributesWithValue: any) =>
                              e?.filter(
                                (data: any) =>
                                  data?.attributeName ==
                                  filterAttributesWithValue.attributeName
                              ).length > 0
                          )
                        );

                        setFieldTouched("supportedAttributes");
                      }}
                      selectedValueDecorator={(
                        value: string,
                        option: TSupportedValue
                      ) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>

                  {touched?.supportedAttributes && errors?.supportedAttributes && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {/* <span role="alert">{errors?.supportedAttributes}</span> */}
                        <span role="alert">
                          {typeof errors?.supportedAttributes === "string"
                            ? errors.supportedAttributes
                            : ""}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card p-10 mb-5">
            <div className="container p-0 m-0">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Compatibility Attributes
                  </label>
                  <div onClick={() => setFieldTouched("compatibility")}>
                    <Multiselect
                      options={values?.supportedAttributes}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      style={style}
                      displayValue="attributeName"
                      placeholder="Select Compatibility"
                      selectedValues={values?.supportedAttributes?.filter(
                        (data: TSupportedValue) =>
                          values.compatibility.includes(data?.attributeName)
                      )}
                      onSelect={async (e) => {
                        await setFieldValue(
                          "compatibility",
                          e?.map((data: TSupportedValue) => data?.attributeName)
                        );
                        setFieldTouched("compatibility");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue(
                          "compatibility",
                          e?.map((data: TSupportedValue) => data?.attributeName)
                        );
                        setFieldTouched("compatibility");
                      }}
                      selectedValueDecorator={(
                        value: string,
                        option: TSupportedValue
                      ) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {touched?.compatibility && errors?.compatibility && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.compatibility}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card p-10 mb-5">
            <div className="container p-0 m-0">
              <p><strong>Only Accessory Subcategory will be displayed in the website</strong></p>
            <div className="mb-3">
                  <label className="form-label fs-6 fw-bolder text-dark d-flex cursor-pointer">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      name="isProductSubcategory"
                      checked={values.isProductSubcategory}
                      onChange={handleCheckboxChange}
                    />
                    <h6 className="mb-0 fs-6">{"Product Subcategory"}</h6>
                  </label>
                </div>
              {/* <div className="row">
                <div className="col-md-12">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Filter Attributes
                  </label>
                  <div onClick={() => setFieldTouched("filteredAttribute")}>
                    <Multiselect
                      options={values?.supportedAttributes}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      style={style}
                      displayValue="attributeName"
                      placeholder="Select FilterAttributes"
                      selectedValues={values?.supportedAttributes?.filter(
                        (data: TSupportedValue) =>
                          values?.filteredAttribute?.includes(
                            data?.attributeName
                          )
                      )}
                      onSelect={async (e) => {
                        await setFieldValue(
                          "filteredAttribute",
                          e?.map((data: TSupportedValue) => data?.attributeName)
                        );
                        setFieldTouched("filteredAttribute");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue(
                          "filteredAttribute",
                          e?.map((data: TSupportedValue) => data?.attributeName)
                        );
                        setFieldTouched("filteredAttribute");
                      }}
                      selectedValueDecorator={(
                        value: string,
                        option: TSupportedValue
                      ) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {touched?.filteredAttribute && errors?.filteredAttribute && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {typeof errors?.filteredAttribute === "string"
                            ? errors.filteredAttribute
                            : ""}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
            <div className="container p-0 m-0">
              <p><strong>Show Fit For Accessory Falling this Subcategory</strong></p>
            <div className="mb-3">
                  <label className="form-label fs-6 fw-bolder text-dark d-flex cursor-pointer">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      name="isShowFit"
                      checked={values.isShowFit}
                      onChange={handleCheckboxChangeForFit}
                    />
                    <h6 className="mb-0 fs-6">{"Show Fit"}</h6>
                  </label>
                </div>
             
            </div>
            {/* <div className="card p-3 shadow d-flex flex-row flex-wrap mb-3">
              {values?.supportedAttributes
                ?.filter((data: TSupportedValue) =>
                  values?.filteredAttribute?.includes(data?.attributeName)
                )
                .map((attribute: TSupportedValue, index: number) => {
                  const handleNewTag = (tagName: string, attribute: string) => {
                    if (tagName) {
                      const previoustags = values?.filteredAttributesWithValue;
                      if (
                        previoustags?.find(
                          (tags) => tags?.attributeName == attribute
                        )
                      ) {
                        const newState = previoustags?.map((tags) =>
                          tags?.attributeName === attribute
                            ? {
                                ...tags,
                                value: [
                                  ...tags.value,
                                  {
                                    name: tagName,
                                    id: Date.now(),
                                  },
                                ],
                              }
                            : tags
                        );

                        setFieldValue(`filteredAttributesWithValue`, newState);
                      } else {
                        const newTag = {
                          attributeName: attribute,
                          value: [{ name: tagName, id: Date.now() }],
                          // generate a unique ID for the new tag
                        };
                        previoustags?.push(newTag);
                        setFieldValue(
                          `filteredAttributesWithValue`,
                          previoustags
                        );
                      }
                    }
                  };

                  const handleDeleteTag = (
                    tag: TagBoxItem,
                    attribute: string
                  ) => {
                    const previoustags = values?.filteredAttributesWithValue;
                    if (
                      previoustags.find(
                        (tags) => tags.attributeName == attribute
                      )
                    ) {
                      const newState = previoustags.map((tags) =>
                        tags.attributeName === attribute
                          ? {
                              ...tags,
                              value: tags?.value?.filter(
                                (tagdata) => tagdata?.name != tag?.name
                              ),
                            }
                          : tags
                      );

                      setFieldValue(`filteredAttributesWithValue`, newState);
                    }
                  };

                  return (
                    <div
                      key={index}
                      className="row me-2 mb-2"
                      style={{ minWidth: "320px", maxWidth: "320px" }}
                    >
                      <label className="w-100px col-form-label required fw-bold fs-6">
                        {attribute?.attributeName}
                      </label>

                      <div className="w-200px">
                        <TagBox
                          tags={
                            values?.filteredAttributesWithValue?.filter(
                              (attributeData) =>
                                attributeData?.attributeName ==
                                attribute.attributeName
                            )?.[`0`]?.value
                          }
                          handleDelete={(e: TagBoxItem) =>
                            handleDeleteTag(e, attribute?.attributeName)
                          }
                          handleNew={(e: string) =>
                            handleNewTag(e, attribute?.attributeName)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryForm;
