import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TRetailerState = {
  data: [];
  perPage: number;
  currentPage: number;
  totalPage: number;
  searchTerm: string;
  loading: boolean;
  sortBy: string;
  sortColumn: string;
  rerender1: boolean;
};

const initialRetailerState: TRetailerState = {
  data: [],
  perPage: 10,
  currentPage: 1,
  totalPage: 1,
  searchTerm: "",
  loading: false,
  sortBy: "desc",
  sortColumn: "",
  rerender1: false,
};

export const retailerSlice = createSlice({
  name: "retailer",
  initialState: initialRetailerState,
  reducers: {
    setRetailer: (state: TRetailerState, { payload }: PayloadAction<[]>) => {
      state.data = payload;
    },
    setPerPage: (state: TRetailerState, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
    setCurrentPage: (
      state: TRetailerState,
      { payload }: PayloadAction<number>
    ) => {
      state.currentPage = payload;
    },
    setTotalPage: (
      state: TRetailerState,
      { payload }: PayloadAction<number>
    ) => {state.totalPage = payload},
    setSearchTerm: (
      state: TRetailerState,
      { payload }: PayloadAction<string>
    ) => {
      state.searchTerm = payload;
    },
    setSortBy: (state: TRetailerState, { payload }: PayloadAction<string>) => {
      state.sortBy = payload;
    },
    setSortColumn: (
      state: TRetailerState,
      { payload }: PayloadAction<string>
    ) => {
      state.sortColumn = payload;
      state.sortBy = "desc";
    },
    setLoading: (
      state: TRetailerState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.loading = payload;
    },
    setRerender: (
      state: TRetailerState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.rerender1 = payload;
    },
  },
});

export const {
  setRetailer,
  setPerPage,
  setCurrentPage,
  setSortColumn,
  setSortBy,
  setTotalPage,
  setSearchTerm,
  setLoading,
  setRerender
} = retailerSlice.actions;
export default retailerSlice.reducer;
