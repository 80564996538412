import React from "react";
import ConfirmationPrompt from "../../components/ConfirmationPrompt";
import { TableLoadingProps } from "../../types/TableLoader";
import { Modal } from "react-bootstrap";
const TableLoader: React.FC<TableLoadingProps> = ({
  isLoading,
  loadingMessage,
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <>
      <Modal
        className="modal fade"
        id="kt_modal_select_location"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        show={true}
        dialogClassName="modal-sm modal-dialog-centered"
        aria-hidden="true"
      >
      <div className="modal-header d-flex justify-content-center" style={{padding:"10px"}}>
          <h3 className="modal-title">{loadingMessage}</h3>
      </div> 
      </Modal>
    </>
  );
};

export default TableLoader;
