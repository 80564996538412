import * as Yup from "yup";

export const SubCategorySchema = Yup.object().shape({
  subcategoryName: Yup.string()
    .min(2, "Minimum 2 characters.")
    .max(50, "Maximum 50 characters.")
    .required("Name is required."),
    supportedAttributes: Yup.array()
    .min(1, "At least one feature is required."),
    description: Yup.string()
    .min(2, "Minimum 2 characters.")
    .max(1000, "Maximum 50 characters.")
    .required("Description is required."),
    subcategoryImage: Yup.array()
    .min(1, "Minimum 1 SubCategory Image is Required")
    .max(1, "Maximum 1 SubCategory Image is Required")
    .required("SubCategory Image is required."),
});
      