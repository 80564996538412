import Button from "../../../components/Button";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import TableWrapper from "../../../components/TableWrapper/TableWrapper";
import useTableWrappperConfig from "../../../utils/Customhooks/tableWrapper/tablewrapperhook";


const UserManagementWrapper: FC = () => {
  const Base_Url = process.env.REACT_APP_API_URL;
  const {  config } = useTableWrappperConfig(`${Base_Url}/usermanagement`);
  const intl = useIntl();
  const[show,setshow]= useState(false)
  

  return (
    <>
      <TableWrapper config={config} />
    </>
  );
};

export { UserManagementWrapper };
