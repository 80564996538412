import { useState, useCallback } from "react";
import { toast } from "react-toastify";

// Define the hook function with three parameters
export const useStatusCheck = ( makeRequest:Function, askConfirmation:boolean = true, afterstatusCheck?:Function ) => {
    // Define three states using the useState hook
    const [statusCheckToUpdate, setstatusCheckToUpdate] = useState({});
    const [showstatusCheckPrompt, setstatusCheckShowPrompt] = useState<boolean>(false);
    const [statusCheckloading, setstatusCheckLoading] = useState<boolean>(false);
    // Define the onDelete function, which is called when the user clicks the delete button
    const onstatusCheck = useCallback((id: any) => {
        // Set the recordIdToDelete state to the ID of the record to be deleted
        setstatusCheckToUpdate(id);
        // If askConfirmation is false, call the deleteRecord function directly
        if (!askConfirmation) {
                
        } else {
            // Otherwise, set the showPrompt state to true to show the confirmation dialog
            setstatusCheckShowPrompt(true);
        }
    },[askConfirmation])

    // Define the deleteRecord function using the useCallback hook to memoize the function
    const ChangestatusCheck = useCallback( async () => {
         
       
        // Initialize a flag to indicate whether the delete operation was successful
        let isSuccessful = false
        let response : any ={};
        try {
            // Set the loading state to true to indicate that the delete operation is in progress
            setstatusCheckLoading(true);
            
        
            // Define the payload object with the ID of the record to be deleted
            const payload = statusCheckToUpdate

            console.log(payload)
            // Call the makeRequest function with the payload object to delete the record
            response = await makeRequest(payload);
            // If the server returns a successful response statusCheck, set the isSuccessful flag to true
            if (response?.data?.status) {
                isSuccessful = true
            }
          }
        catch (error) {
            toast.error("Network error")
        }
        finally  {
            // Set the loading state to false to indicate that the delete operation has completed
            setstatusCheckLoading(false);
            // Reset the recordIdToDelete state to an empty string
            setstatusCheckToUpdate('');
            setstatusCheckShowPrompt(false);
            // Call the afterDelete callback function with the isSuccessful flag as an argument
            // (if the afterDelete function is provided as a parameter)
            if(typeof makeRequest === 'function') {
            afterstatusCheck && afterstatusCheck(isSuccessful, response);
            }
        }
    }, [afterstatusCheck,statusCheckToUpdate,makeRequest])

    // Define the onCancel function, which is called when the user clicks the cancel button
       const onstatusCheckCancel = useCallback(() => {
        // Reset the recordIdToDelete state to an empty string
        setstatusCheckToUpdate('');
        // Set the showPrompt state to false to hide the confirmation dialog
        setstatusCheckShowPrompt(false);
    },[])

    // Return an object containing the onDelete, showPrompt, loading, deleteRecord, and onCancel functions
    return { onstatusCheck, showstatusCheckPrompt, statusCheckloading, statusCheckToUpdate, onstatusCheckCancel,ChangestatusCheck };
}
