import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { TOptionType } from "../../../types/CustomSelect";
import Multiselect from "multiselect-react-dropdown";
import CustomArrow from "../../../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../../../utils/CustomStyleComponent/CrossButtonComponent";
import { getLanguage } from "../../../app/request/languageRequest";
import { toast } from "react-toastify";
import { TLanguageFilterProps } from "../../../types/Filters/LanguageFilter";
import CustomTooltip1 from "../../../utils/CustomStyleComponent/TooltipNewComponent";
import "../../../app/pages/Units/UnitsForm.css";

const LanguageFilter = ({
  onLanguageFilterChange,
  currentLanguageFilter,
}: TLanguageFilterProps) => {
  const [alllanguage, setAllLanguage] = useState([]);

  const style = {
    multiSelectContainer: {
      minheight: "44px",

      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontWeight: "500",
      minwidth: "40px",
      maxwidth: "40px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      width: "350px",

      // styles for the search wrapper
    },
    chips: {
      // To change css chips(Selected options)
      marginBottom: "0",
    },
  };

  useEffect(() => {
    const fn = async () => {
      try {
        const response = await getLanguage();
        if (response.data.status) {
          setAllLanguage(response.data.data.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
      }
    };
    fn();
  }, []);

  const handleLanguageFilter = useCallback((data: any) => {
    onLanguageFilterChange(data);
  }, []);

  return (
    <Multiselect
      options={alllanguage}
      displayValue="languageName"
      placeholder="Select Language"
      hidePlaceholder={true}
      selectionLimit={3}
      style={style}
      showArrow={true}
      customArrow={<CustomArrow />}
      customCloseIcon={<CustomCrossButton />}
      closeOnSelect={false}
      avoidHighlightFirstOption={true}
      showCheckbox={true}
      onSelect={handleLanguageFilter}
      onRemove={handleLanguageFilter}
      selectedValueDecorator={(value: string, option: any) => {
        return <CustomTooltip1 value={value} option={option} />;
      }}
    />
  );
};

export default React.memo(LanguageFilter);
