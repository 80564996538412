
      import React from 'react'
      import PermissionFormModal from '../../generatedFormModal/PermissionFormModal'
      
      const initialValues={id:"",name:""}

      const AddPermission=({currentRecord}:{currentRecord?:any})=> {
        return (
          <div>
           <PermissionFormModal isEdit={false} currentRecord={
            initialValues 
           }/> 
          </div>
        )
      }
      
      export default AddPermission
         