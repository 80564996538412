import HelpForm from "../generatedForms/Help";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { addHelp, editHelp, getSingleHelp } from "../generatedRequest/help";
import { HelpSchema } from "../generatedValidations/HelpSchema";
import { useSearchParams } from "react-router-dom";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import AllModules from "../permission.json";

type THelpModalProps = {
  currentRecord: any;
  isEdit: boolean;
};

const HelpFormModal = ({ currentRecord, isEdit }: THelpModalProps) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [initload, setInitload] = useState(true);
  const [allModules, setAllModules] = useState<any[]>(AllModules.modules);
  const helpId = queryParameters.get("q") || "";
  const [initialValues, setInitialValues] = useState({
    moduleName: "",
    descriptions: "",
    faqs: [],
  });

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editHelp(payload, helpId)
        : await addHelp(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Quiz is edited Successfully")
          : toast.success("Quiz is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: HelpSchema,
    onSubmit: (values, { setSubmitting }) => {
      let payload = {
        moduleName: values?.moduleName,
        descriptions: values.descriptions,
        faqs: values.faqs,
      };

      handleSubmit(payload);
    },
  });

  useEffect(() => {
    const fetchQuizData = async () => {
      setLoading(true);
      try {
        const response = await getSingleHelp(helpId); // Call your API to get user data
        if (response.data.status) {
          formik.setValues({
            ...response.data.data,
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchQuizData();
    } else {
      setInitload(false);
    }
  }, [isEdit, helpId]);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }

  return (
    <>
      <HelpForm
        {...formik}
        isloading={loading}
        isEdit={isEdit}
        allModules={allModules}
      />

      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default HelpFormModal;
