/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext, FormikProps } from "formik";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ICreateAccount } from "../StepperHelper";
import { Step1Props } from "./step";

const Step1: React.FC<Step1Props> = ({ formik, submitStep, isLoading }: Step1Props) => {

  const [isSelected, setIsSelected] = useState(1);

  // Function to handle radio button selection
  const handleRadioButtonChange = async (
    e: React.ChangeEvent<HTMLInputElement>, value:number
  ) => {
    setIsSelected(value);
    // resetting the form to get new fields in formik
    formik.resetForm();
    await formik.setFieldValue("accessoryType", e.target.value);

    // Call submitStep to move to the next step
    submitStep(formik.values, formik);
  };

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2
          className="fw-bolder d-flex align-items-center justify-content-center text-dark"
          style={{ fontSize: "27px" }}
        >
          What type of accessory are you looking for?
        </h2>
      </div>

      <div className="fv-row">
        <div className="row">
          <div className="col-lg-3">
            <Field
              type="radio"
              className="btn-check"
              name="accessoryType"
              value="docking station"
              id="kt_docking_station"
              onChange={(event:any)=>handleRadioButtonChange(event, 1)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
              htmlFor="kt_docking_station"
            >
              <span className="d-flex justify-content-center flex-column w-100">
                <span
                  style={{ height: "100px", width: "100px", margin: "0 auto" }}
                >
                  <img
                    src={toAbsoluteUrl("/media/misc/docking-station1.png")}
                    alt="Docking Station"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
                <span className="text-dark fw-bolder d-block fs-4 mt-5">
                  Docking Station {isLoading && isSelected === 1 ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : ""}
                </span>
              </span>
            </label>
          </div>

          <div className="col-lg-3">
            <Field
              type="radio"
              className="btn-check"
              name="accessoryType"
              value="charger"
              id="kt_charger"
              onChange={(event:any)=>handleRadioButtonChange(event, 2)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
              htmlFor="kt_charger"
            >
              <span className="d-flex justify-content-center flex-column w-100">
                <span>
                  <img
                    src={toAbsoluteUrl("/media/misc/charger.png")}
                    alt="Charger"
                  />
                </span>
                <span className="text-dark fw-bolder d-block fs-4 mt-5">
                  Charger {isLoading && isSelected === 2 ?  <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : ""}
                </span>
              </span>
            </label>
          </div>

          <div className="col-lg-3">
            <Field
              type="radio"
              className="btn-check"
              name="accessoryType"
              value="privacy screen"
              id="kt_privacy_screen"
              onChange={(event:any)=>handleRadioButtonChange(event, 3)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
              htmlFor="kt_privacy_screen"
            >
              <span className="d-flex justify-content-center flex-column w-100">
                <span>
                  <img
                    src={toAbsoluteUrl("/media/misc/privacy-screen.png")}
                    alt="Privacy Screen"
                  />
                </span>
                <span className="text-dark fw-bolder d-block fs-4 mt-5">
                  Privacy Screen {isLoading && isSelected === 3 ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : ""}
                </span>
              </span>
            </label>
          </div>

          <div className="col-lg-3">
            <Field
              type="radio"
              className="btn-check"
              name="accessoryType"
              value="cable lock"
              id="kt_cable_lock"
              onChange={(event:any)=>handleRadioButtonChange(event, 4)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
              htmlFor="kt_cable_lock"
            >
              <span className="d-flex justify-content-center flex-column w-100">
                <span
                  style={{ height: "100px", width: "100px", margin: "0 auto" }}
                >
                  <img
                    src={toAbsoluteUrl("/media/misc/cable-lock.png")}
                    alt="Cable Lock"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
                <span className="text-dark fw-bolder d-block fs-4 mt-5">
                  Cable Lock {isLoading && isSelected === 4 ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : ""}
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
