import React, { useCallback, useMemo } from "react";
import { TSearchProps } from "../../types/Search";
import _ from 'lodash';


const Search = ({ placeholder, handleSearchParams,searchWidth }: TSearchProps) => {

  const onSearchChange = useCallback((e: any) => {
    typeof handleSearchParams === 'function' && handleSearchParams(e.target.value);
  }, [handleSearchParams])

  const debouncedSearch = useMemo(() => {
    return _.debounce(onSearchChange, 500)
  }, [handleSearchParams])


  return (
    <>
      <span className="svg-icon svg-icon-1 position-absolute ms-6">
        <i className="bi bi-search"></i>
      </span>
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid ps-14'
        style={searchWidth ? { width: searchWidth } : { width: '250px' }}
        placeholder={placeholder}
        onChange={debouncedSearch}
      />
    </>
  );
};

export default Search;
