import React, { useState, useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import FrontendPage from "../app/pages/FrontendDemo/FrontendPage";


const FrontendRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FrontendPage />} />
    </Routes>
  );
};
export default FrontendRoutes;
