import React, { useState, useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import UnitsList from "../generatedTableList/UnitsList";
import ModalComponent from "../components/Modal";
import { Outlet } from "react-router-dom";
import UnitsPage from "../generatedTableList/UnitsPage";
import AddUnit from "../app/pages/Units/AddUnit";
import EditUnit from "../app/pages/Units/EditUnit";

const UnitsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UnitsPage />} />
      <Route path="/edit" element={<EditUnit />} />
      <Route path="/add" element={<AddUnit />} />
    </Routes>
  );
};
export default UnitsRoutes;
