import { useCallback, useEffect, useState } from "react";
import CategoryTableAction from "../generatedTableActions/CategoryTableAction";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setCategory,
  setSearchTerm,
  setTotalPage,
} from "../generatedSlice/categorySlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getCategory } from "../generatedRequest/category";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import CategoryList from "../generatedTableList/CategoryList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import {
  TColumn,
  ThandleAfterSearchData,
} from "../types/Module/Category/CategoryPage";
import { NotesDisplay } from "../components/Reusable/NotesDisplay";

const columns: TColumn[] = [
  {
    name: "name",
    renderHeader: () => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row) => <>{row.categoryName}</>,
  },
  {
    name: "SubCategory",
    renderHeader: () => (
      <>
        <b>Sub Category</b>
      </>
    ),
    renderCell: (row) => (
      <>
        {Array.prototype.map.call(row && row.subcategory, function (item) {
          return (
            <span>
              <a
                href={"/subcategory/edit?q=" + item._id || item.id}
                style={{ textDecoration: "underline" }}
              >
                {item.subcategoryName}
              </a>
              {"  "}
            </span>
          );
        })}
      </>
    ),
  },
  
  // {
  //   name: "notes",
  //   renderHeader: () => (
  //     <>
  //       <b>Notes</b>
  //     </>
  //   ),
  //   renderCell: (row) => {
  //    return (
  //       <span>
  //        <NotesDisplay notes={row.notes}/>
  //       </span>
  //     );
  //   },
  // },
  
  
  {
    name: "createdby",
    renderHeader: () => (
      <>
        <b>createdby</b>
      </>
    ),
    renderCell: (row) => <>{row.createdAt}</>,
  },
  {
    name: "createdat",
    renderHeader: () => (
      <>
        <b>Created At</b>
      </>
    ),
    renderCell: (row) => <>{row.createdAt}</>,
  },
  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row) => <CategoryTableAction category={row} />,
  },
];

const CategoryPage = () => {
  const dispatch = useDispatch();
  const [initload, setInitload] = useState(true);
  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
    rerender1,
  } = useSelector((state: RootState) => state.category);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
      dispatch(setLoading(true));
      try {
        const response = await getCategory(searchQuery);
        if (response.data.status) {
          dispatch(setCategory(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
      }
    };
    fn();
  }, [rerender1, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, []);

  function handleAfterSearch(
    isSuceess: boolean,
    data: ThandleAfterSearchData,
    value: number
  ) {
    if (isSuceess) {
      dispatch(setCategory(data.data.data.data));
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }
  const { doSearch, searchLoading } = useSearchApi(
    getCategory,
    handleAfterSearch
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch]
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Category",
    searchLoading: searchLoading,
    showFilter: false,
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Categories</h2>
        <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add Category
        </Button>
      </div>
      <CategoryList {...configuration} />
    </>
  );
};

export default CategoryPage;
