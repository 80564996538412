import { useCallback, useEffect, useState } from "react";
import TableView from "../components/TableView";
import QuizTableAction from "../generatedTableActions/QuizTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setQuiz,
  setSearchTerm,
  setTotalPage,
} from "../generatedSlice/quizSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getQuiz } from "../generatedRequest/quiz";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import QuizList from "../generatedTableList/QuizList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    name: "name",
    renderHeader: (rows: any) => (
      <>
        <b>Quiz For</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.subcategory?.subcategoryName}</>,
  },
  {
    name: "total questions",
    renderHeader: (rows: any) => (
      <>
        <b>Total Questions</b>
      </>
    ),
    renderCell: (row: any) => (
      <>{row && row.questions && row.questions.length}</>
    ),
  },
  {
    name: "Created By",
    renderHeader: (rows: any) => (
      <>
        <b>Created By</b>
      </>
    ),
    renderCell: (row: any) => (
      <>{row && row?.createdBy?.overview?.email}</>
    ),
  },
  {
    name: "createdat",
    renderHeader: (rows: any) => (
      <>
        <b>Created At</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.createdAt}</>,
  },
  {
    name: "updatedat",
    renderHeader: (rows: any) => (
      <>
        <b>Updated At</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.updatedAt}</>,
  },
  {
    name: "Actions",
    renderHeader: (rows: any) => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row: any) => <QuizTableAction quiz={row} />,
  },
];

const QuizPage = () => {
  const dispatch = useDispatch();
  const [initload, setInitload] = useState(true);

  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
    rerender1,
  } = useSelector((state: RootState) => state.quiz);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
      dispatch(setLoading(true));
      try {
        const response = await getQuiz(searchQuery);
        if (response.data.status) {
          dispatch(setQuiz(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
      }
    };
    fn();
  }, [rerender1, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, [])

  function handleAfterSearch(isSuceess: boolean, data: any, value: any) {
    if (isSuceess) {
      dispatch(setQuiz(data.data.data.data));
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }
  const { doSearch, searchLoading } = useSearchApi(getQuiz, handleAfterSearch);

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch]
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Quiz",
    showFilter: false,
    searchLoading:searchLoading
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Quiz</h2>
        <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add Quiz
        </Button>
      </div>
      <QuizList {...configuration} />
      
    </>
  );
};

export default QuizPage;
