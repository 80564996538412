import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import * as authHelper from "./AuthHelpers";
import { getUserByToken, isSessionExpired } from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import { TUserPermissions } from "../../../../types/User/userPermissions";
import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router";
import { TUserModel, defaultUser } from "../../../../types/Auth/user";
import { TLoginModel, defaultLogin } from "../../../../types/Auth/login";

type AuthContextProps = {
  auth: TLoginModel;
  saveAuth: Function;
  currentUser: TUserModel;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: defaultUser,
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<TLoginModel | undefined>(
    authHelper.getAuth()
  );
  //const navigate = useNavigate();

  useEffect(() => {
    //set the authorization header for all the requests
    if (auth && auth?.token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth?.token}`;
    }
  }, [auth]);

  const saveAuth = (auth: TLoginModel) => {
    setAuth(auth);
    if (auth.status) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(defaultLogin);
    authHelper.removeAuth();
    //window.location.href = "/login";
    //navigate("/login");
  };

  let currentUser = auth ? auth.data : defaultUser;
  return (
    <AuthContext.Provider
      value={{ auth: auth as any, saveAuth, currentUser: currentUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { logout, saveAuth } = useAuth();
  const location = useLocation();

  //TODO: set process.env.NODE_ENV!== "development"  when go live
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  //this is to check the login session status of current user
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (location.pathname === "/auth/reset-password") {
          logout(); // Log out the user if on the reset-password page
          setShowSplashScreen(false); // Hide splash screen after logout
        } else {
          let res: any = await isSessionExpired();

          //in case session is expired
          if (res.data.status !== true) {
            //toast.error("Session Expired");
            logout();
          } else {
            saveAuth(res.data);
          }
        }
      } catch (error: any) {
        toast.error("Something went wrong, please refresh the page");
      }
      setShowSplashScreen(false);
    };

    requestUser();
  }, []);

  return showSplashScreen  ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
