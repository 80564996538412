import axios from "axios";
import {
  CreateRegionPayload,
  UpdateMakeDefaultPayload,
  UpdateRegionPayload,
  UpdateStatusPayload,
} from "../../types/Module/Region/RegionRequest";

const BASE_URL = process.env.REACT_APP_API_URL;
const REGION_GET_URL = BASE_URL + "/regions";
const DELETE_Region_URL = BASE_URL + "/regions/";
const REGION_ADD_URL = BASE_URL + "/regions";

export const getRegion = (searchParams?: string) => {
  return axios.get(REGION_GET_URL + "?" + searchParams);
};
export const getSingleRegion = (searchParams: string) => {
  return axios.get(REGION_GET_URL + "/" + searchParams);
};

export const createRegion = (payload: CreateRegionPayload) => {
  return axios.post(REGION_ADD_URL, payload);
};

export const updateRegion = (payload: UpdateRegionPayload, id: string) => {
  const url = REGION_ADD_URL + "/" + id;
  return axios.patch(url, payload);
};

export const updateStatus = ({ status, id }: UpdateStatusPayload) => {
  const url = REGION_ADD_URL + "/" + id;
  const newStatus = status === "active" ? "inactive" : "active";
  const payload = {
    status: newStatus,
  };

  if (id) {
    return axios.patch(url, payload);
  }
};

export const changeDefaultSelection = ({
  id,
  isDefault,
}: UpdateMakeDefaultPayload) => {
  const url = REGION_ADD_URL + "/" + id;
  const payload = {
    isDefault: isDefault === true ? false : true,
  };
  if (id) {
    return axios.patch(url, payload);
  }
};

export const deleteRegion = (language: string) => {
  return axios.delete(DELETE_Region_URL + language);
};
