import React from 'react'
import { TOptionType } from '../../types/CustomSelect';
import { TPerPageProps } from '../../types/PerPage';
import Select from '../CustomSelect';

const perPageOption: TOptionType<number>[] = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" }
]


const PerPage = ({onPerPageChange, perPage}: TPerPageProps) => {
  return (
    <Select size='small' label={"select"} value={perPageOption.find(t => t.value === perPage)} name="Select per page" onChange={(v: any) => {
       onPerPageChange && onPerPageChange(v.value)
    }} options={perPageOption} />
  )
}

export default React.memo(PerPage)