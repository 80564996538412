import React from "react";
import ProductFormModal from "../../generatedFormModal/ProductFormModal";

const EditProduct = () => {
  return (
    <div>
      <ProductFormModal isEdit={true} />
    </div>
  );
};

export default EditProduct;
