/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginSchema } from "../../../../utils/validationSchema/loginSchema";
import _ from "lodash";

const initialValues = {
  email: "",
  password: "",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth } = useAuth();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const abortController = useRef(new AbortController());

  useEffect(() => {
    return () => {
      abortController.current.abort();
    };
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const payload = { email: values.email, password: values.password };
      setLoading(true);

      try {
        const res = await login(payload, abortController.current);

        if (res?.data?.status && !res.data?.data?.is2FA) {
          toast.success("Login Successfully done");
          console.log(res.data);
          saveAuth(res.data);
        } else if (res?.data?.status && res.data?.data?.is2FA) {
          let userid = res.data?.data?.userId;
          toast.success("Please enter your authentication code ..Check mail");
          navigate(`verify-code?userid=${userid}`);
        } else {
          toast.error("Invalid Login Credentials");
          setError(true);
        }
      } catch (error: any) {
        setError(true);
        if (error.message === "canceled") {
          return;
        }
        toast.error("Network Error");
      }
      setSubmitting(false);
      setLoading(false);
    },
  });

  return (
    <div className="p-5">
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-5">Sign In</h1>
        </div>
        {/* begin::Heading */}

        {error && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Invalid Login Credentials
            </div>
          </div>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Email
          </label>
          <input
            placeholder="Email"
            disabled={formik.isSubmitting}
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
            type="email"
            name="email"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-3">
          <label className="form-label fw-bolder text-dark fs-6 mb-0 required">
            Password
          </label>
          <input
            type="password"
            autoComplete="off"
            disabled={formik.isSubmitting}
            placeholder="Password"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />

          {/* begin::Link */}
          <Link to="/auth/forgot-password" className="link-primary">
            Forgot Password ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className="d-grid">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </div>
  );
}
