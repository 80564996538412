import React, { useCallback, useState } from "react";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { deleteSubCategory } from "../generatedRequest/subcategory";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/subcategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { TRowData, TSubCategoryActionProps } from "../types/Module/SubCategory/SubCategoryPage";

const moduleName = "Subcategory";

const SubCategoryTableAction = ({ subcategory }: TSubCategoryActionProps) => {
  const { rerender1 } = useSelector((state: RootState) => state.subcategory);
  const dispatch = useDispatch();
  const { doEdit } = useEdit(moduleName);
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const handleAfterDelete = useCallback((flag: boolean, response: TRowData) => {
    if (flag) {
      toast.success("Record deleted");
      dispatch(setRerender(!rerender1));
      // do whatever you want with response
    } else {
      toast.error(response.data.message);
    }
  }, [rerender1,dispatch]);

  const { canDo, currentActivity } = usePermissions();

  const { onDelete, deleteRecord, showPrompt, onCancel,loading } = useDelete(
    deleteSubCategory,
    true,
    handleAfterDelete
  );
  const actions = ["Edit", "Delete"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  
  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          // if (canDo(moduleName, "Edit")) {
          doEdit(subcategory._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          // if (canDo(moduleName, "Delete")) {
          onDelete(subcategory._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, subcategory, onDelete]
  ); 




  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default SubCategoryTableAction;
