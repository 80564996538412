import { TPaginationButtonProps } from '../../types/Pagination';
import './style.css'


const PaginationButton = ({ children, onClick,disabled }: TPaginationButtonProps) => {
    let _className = "page-link cursor-pointer";
    return (
        <li className='page-item'>
            <span className={disabled? `pe-none ${_className}` : `pe-auto ${_className}`} onClick={onClick}>{children}</span>
        </li>
    )
}

export default PaginationButton