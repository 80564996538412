import { useState, useCallback } from "react";
import { toast } from "react-toastify";

// Define the hook function with three parameters
export const useMakeDefault = (
  makeRequest: Function,
  askConfirmation = true,
  afterRegionChange?: Function
) => {
  // Define three states using the useState hook
  const [defaultRegion, setDefaultRegion] = useState("");
  const [showRegionPrompt, setRegionShowPrompt] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);

  // Define the setDefaultRegion function, which is called when the user wants to set the default region
  const onSetDefaultRegion = useCallback(
    (region: any) => {
      // Set the defaultRegion state to the selected region
      setDefaultRegion(region);
      // If askConfirmation is false, call the changeDefaultRegion function directly
      if (!askConfirmation) {
        changeDefaultRegion();
      } else {
        // Otherwise, set the showRegionPrompt state to true to show the confirmation dialog
        setRegionShowPrompt(true);
      }
    },
    [askConfirmation]
  );

  // Define the changeDefaultRegion function using the useCallback hook to memoize the function
  const changeDefaultRegion = useCallback(async () => {
    // Initialize a flag to indicate whether the region change was successful
    let isSuccessful = false;
    let response: any = {};
    try {
      // Set the loading state to true to indicate that the region change operation is in progress
      setRegionLoading(true);

      // Define the payload object with the selected default region
      const payload = defaultRegion;
      // Call the makeRequest function with the payload object to change the default region
      response = await makeRequest(payload);
      // If the server returns a successful response status, set the isSuccessful flag to true
      if (response?.data?.status) {
        isSuccessful = true;
      }
    } catch (error) {
      toast.error("Network error");
    } finally {
      // Set the loading state to false to indicate that the region change operation has completed
      setRegionLoading(false);
      // Reset the defaultRegion state to an empty string
      setDefaultRegion("");
      setRegionShowPrompt(false);
      // Call the afterRegionChange callback function with the isSuccessful flag as an argument
      // (if the afterRegionChange function is provided as a parameter)
      if (typeof afterRegionChange === "function") {
        afterRegionChange(isSuccessful, response);
      }
    }
  }, [afterRegionChange, defaultRegion, makeRequest]);

  // Define the onCancel function, which is called when the user clicks the cancel button
  const onRegionCancel = useCallback(() => {
    // Reset the defaultRegion state to an empty string
    setDefaultRegion("");
    // Set the showRegionPrompt state to false to hide the confirmation dialog
    setRegionShowPrompt(false);
  }, []);

  // Return an object containing the setDefaultRegion, showRegionPrompt, regionLoading, defaultRegion, and onRegionCancel functions
  return {
    onSetDefaultRegion,
    showRegionPrompt,
    regionLoading,
    defaultRegion,
    onRegionCancel,
    changeDefaultRegion,
  };
};
