import { useCallback, useEffect } from "react";
import JatinTableAction from "../generatedTableActions/JatinTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setJatin,
} from "../generatedSlice/jatinSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getRegion } from "../app/request/regionRequest";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import JatinList from "../generatedTableList/JatinList";
import { TTableProps } from "../types/TableView";

const columns = [
  {
    name: "id",
    renderHeader: (rows: any) => (
      <>
        <b>id</b>
      </>
    ),
    renderCell: (row: any) => <>{row.id}</>,
  },
  {
    name: "created_at",
    renderHeader: (rows: any) => (
      <>
        <b>created_at</b>
      </>
    ),
    renderCell: (row: any) => <>{row.created_at}</>,
  },
  {
    name: "name",
    renderHeader: (rows: any) => (
      <>
        <b>name</b>
      </>
    ),
    renderCell: (row: any) => <>{row.name}</>,
  },
  {
    name: "Actions",
    renderHeader: (rows: any) => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row: any) => <JatinTableAction id={row.id} />,
  },
];
const rows = [
  { id: "3", created_at: "5fc2k", name: "3py3d" },
  { id: "8", created_at: "2x337", name: "cn1um" },
  { id: "7", created_at: "kfkg0", name: "wz7sk" },
  { id: "3", created_at: "00bkr", name: "gh2mr" },
  { id: "7", created_at: "46c5j", name: "p4bcd" },
];

const JatinPage = () => {
  const dispatch = useDispatch();

  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
  } = useSelector((state: RootState) => state.jatin);

  useEffect(() => {
    const fn = async () => {
      dispatch(setLoading(true));
      try {
        const response = await getRegion(`page=1&limit=100`);
        if (response.data.status) {
          setTimeout(() => {
            dispatch(setJatin(response.data.data));
          }, 500);
        } else {
          setTimeout(() => {
            dispatch(setJatin(response.data.data));
          }, 500);
        }
      } catch (error) {
        toast.error("Network Error");
      }
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500);
    };
    fn();
  }, []);

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
    },
    [dispatch]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
    },
    [dispatch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      const searchQuery = value;
      doSearch(searchQuery);
    },
    [currentPage, perPage]
  );

  const handleAfterSearch = useCallback((value: string) => {}, []);

  const { doSearch ,searchLoading} = useSearchApi(getRegion, handleAfterSearch);

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Jatins",
    searchLoading:searchLoading,
    showFilter:false
  };

  if (loading) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Jatins</h2>
        <Button variant="primary" size="medium">
          Add Jatin
        </Button>
      </div>
      <JatinList {...configuration} />
    </>
  );
};

export default JatinPage;
