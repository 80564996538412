
export const defaultUser= {
    id:"",
    firstName:"",
    lastName:"",
    departMent:"",
    phone:"",
    email:"",
    status:"",
    createdAt:"",
    lastLoginDate:"",
    password:"",
    permissions:[]
  
  }
  export type TUserModel = any;
  
  