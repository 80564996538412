import { FC } from "react";
import RegionFormModal from "../../../generatedFormModal/RegionFormModal";

const AddRegion: FC = () => {
  return (
    <>
      <RegionFormModal isEdit={false} />
    </>
  );
};

export default AddRegion;
