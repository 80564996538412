import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import './SelectComponent.css';

interface SelectComponentProps<T> {
  options: T[];
  selectedKey: keyof T;
  onSelect: (selectedOption: T | null) => void;
  onInputChange: (value: string) => void;
  selectedOption?: string | null; // Optional prop for the initial value
  onFocusChange?: (isFocused: boolean) => void; // Optional prop for onFocus event
}

const SelectComponent = <T extends Record<string, any>>(props: SelectComponentProps<T>) => {

  const {
    options,
    selectedKey,
    onSelect,
    onInputChange,
    selectedOption: initialSelectedOption,
    onFocusChange,
  } = props;



  const [selectedOption, setSelectedOption] = useState<string | null>(initialSelectedOption || null);
  const [filteredOptions, setFilteredOptions] = useState<T[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSelectedOption(inputValue);
    const filtered = options.filter(option =>
      option[selectedKey].toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
    onInputChange(inputValue);
  };

  const handleOptionClick = (option: T) => {
    setSelectedOption(option[selectedKey]);
    setFilteredOptions([]);
    onSelect(option);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    setFilteredOptions(options);
    if (onFocusChange) {
      onFocusChange(true);
    }
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    if (onFocusChange) {
      onFocusChange(false);
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
      setFilteredOptions([]);
    }
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current === document.activeElement) {
      setFilteredOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (selectedOption === null) {
      const selectedOption: any = options.find(option => option[selectedKey] === props.selectedOption);
      if (selectedOption) {
        setSelectedOption(selectedOption[selectedKey]);
      }
    }
  }, [selectedKey, options, selectedOption, props.selectedOption]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOption(initialSelectedOption || null);
  }, [initialSelectedOption]);

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <input
        type="text"
        className="form-select form-select-white"
        value={selectedOption || ''}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder="Enter value"
        style={{ width: '100%' }}
        ref={inputRef}
      />
      {filteredOptions.length > 0 && (
        <ul
          ref={dropdownRef}
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            zIndex: 99999999,
            backgroundColor: 'white',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            maxHeight: '200px',
            overflowY: 'auto',
            border: '1px solid #ccc',
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              className="select-component-list-item"
            >
              {option[selectedKey]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectComponent;
