/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { MenuComponent } from '../../_metronic/assets/ts/components'

type TActionsCellProps = {
  title?: string,
  actions: string[],
  onSelect: Function,
  buttonStyle?:string,
  iconStyle?:string
  
}

const ActionCells = ({ title, actions, onSelect,buttonStyle,iconStyle }: TActionsCellProps) => {
    useEffect(() => {
      MenuComponent.reinitialization()
    }, [])

  return (
    <>
      <a
        href='#'
        className={buttonStyle ||'btn btn-light btn-active-light-primary btn-sm'}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {title || "Actions"}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className={iconStyle ||'svg-icon-5 m-0'} />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {
          actions.map((action: string, index: number) => {
            return <div className='menu-item px-3' key={index}>
              <span className='menu-link px-2'
                onClick={() => {
                  typeof onSelect ==="function" && onSelect(action)
                }}
              >
                {action}
              </span>
            </div>
          })
        }
      </div>
    </>
  )
}

export { ActionCells }
