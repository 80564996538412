import axios from "axios";
import { TPasswordForgotModel } from "../../../../types/Network_ Request/forgotPasswordType";
import { TResetPasswordModel } from "../../../../types/Network_ Request/resetPasswordType";
import { TGetUsersModel } from "../../../../types/Network_ Request/getUsers";
import { TAddUserModel } from "../../../../types/Network_ Request/addUserType";

//Mock Api JSON

import loginresponse from "../../../../mock-api/login.json";
import forgetPasswordresponse from "../../../../mock-api/forgotPassword.json";
import resetPasswordresponse from "../../../../mock-api/resetPassword.json";
import userjson from "../../../../mock-api/getuserdata.json";
import { TRequestAccessModel } from "../../../../types/Network_ Request/permissionRequest/requestAccess";
import { TUserModel } from "../../../../types/Auth/user";
import ApexCharts from "apexcharts";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/register`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`;
export const GET_USERS_URL = `${API_URL}/getUsers`;
export const GET_ROLES = `${API_URL}/roles`;
export const GET_USER_URL = `${API_URL}/user`;
export const EDIT_USER_URL = `${API_URL}/user`;
export const ADD_USER_URL = `${API_URL}/add-user`;
export const UPDATE_2FA_USER = `${API_URL}/2FAuser`;
export const Verify_2FA_USER = `${API_URL}/verify-2FA`;

export const CHECK_SESSION_STATUS_URL = `${API_URL}/checkSession`;
export const LOGIN_URL = `${API_URL}/admin/login`;

export const GET_REGION_URL = `/static-response/regions/getRegion.json`;

export const GET_UNIT_URL = `/static-response/units/getUnit.json`;

//TODO : make it dynamic url when go live
export const REQUEST_PERMISSION_MODULE_URL = `/static-response/requestAccess.json`;

// Server should return AuthModel
export function login(payload: object, abort: AbortController) {
  //TODO : make it post when go live
  return axios.post(LOGIN_URL, payload);

  // return axios.post<TLoginModel>(
  //   LOGIN_URL,
  //   {
  //     ...payload,
  //   },
  //   { signal: abort.signal }
  // );
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axios.get<TUserModel>(`${REGISTER_URL}/${token}`);
}

export function forgotPassword(payload: object) {
  // return new Promise<TPasswordForgotModel>((resolve) => {
  // const fakeResponse = forgetPasswordresponse
  // Simulate a delay to make it more realistic
  //   setTimeout(() => {
  //     return resolve(fakeResponse.response.data);
  //   }, 1000);
  // });
  return axios.post<TPasswordForgotModel>(REQUEST_PASSWORD_URL, payload);
}

export function resetPassword(payload: object, queryParams: any) {
  return axios.post<TResetPasswordModel>(
    RESET_PASSWORD_URL +
      "?email=" +
      queryParams.email +
      "&token=" +
      queryParams.token,
    payload
  );
}

export function getUsers() {
  return axios.get<TGetUsersModel>(`${GET_USERS_URL}`);
}

// export function getUserData (payload:object) {
//   return axios.post<any>(EDIT_USERS_URL,{...payload});
// }

export function getRegionData(payload: object) {
  //TODO : make it post when go live
  return axios.get<any>(GET_REGION_URL, payload);
}

export function getUnitData(payload: object) {
  //TODO : make it post when go live
  return axios.get<any>(GET_UNIT_URL, payload);
}

export function getUserData(payload: any) {
  return axios.get<TGetUsersModel>(`${GET_USER_URL}/${payload}`);
}

export function getRoles() {
  return axios.get<any>(`${GET_ROLES}`);
}

export function addRole(payload: object) {
  return axios.post<any>(GET_ROLES, payload);
}

export function editRole(payload: object, userId: string) {
  return axios.patch<any>(`${GET_ROLES}/${userId}`, payload);
}

export const getSingleRole = (userId: string) => {
  return axios.get(GET_ROLES + "/" + userId);
};

export const deleteRole = (userId: string) => {
  return axios.delete(GET_ROLES + "/" + userId);
};

export function editUser(payload: object, userId: string) {
  return axios.patch<any>(`${EDIT_USER_URL}/${userId}`, payload);
}

export function addUser(payload: object) {
  return axios.post<any>(ADD_USER_URL, payload);
}

export function isSessionExpired() {
  return axios.get(`${CHECK_SESSION_STATUS_URL}`);
}

// export function toggle2FA(user: any) {
//   console.log("here", user);
//   let payload = {
//     is2FA: !user.is2FA,
//   };
//   let currentUserid = user.id || user._id;
//   return axios.patch<any>(`${UPDATE_2FA_USER}/${currentUserid}`, payload);
// }

export function Verify2FA(verficationCode: string,userId?: string, ) {
  let payload = {
    userId: userId,
    code: verficationCode,
  };
  return axios.post<any>(`${Verify_2FA_USER}`, payload);
}
