import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import { MODULE_DELETE_URL } from "../app/request/userRequest";
import { TModuleDelete } from "../types/Network_ Request/moduleWiseApiType/deleteApi";
import { TTableAction } from "../types/TableActions";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { useDispatch, useSelector } from "react-redux";
import { setRerender } from "../generatedSlice/unitSlice";
import { RootState } from "../redux/store";
import { deleteUnit } from "../app/request/unitRequest";
import { TRowData, TUnitProps } from "../types/Module/Unit/UnitPage";

const moduleName = "Unit";



const UnitsTableAction = ({ unit }: TUnitProps) => {
  const { rerender1 } = useSelector((state: RootState) => state.unit);
  const { doEdit } = useEdit(moduleName);
  const dispatch = useDispatch();
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const handleAfterDelete = useCallback(
    (flag: boolean, response: TRowData) => {
      if (flag) {
        toast.success("record deleted successfully");
   
        dispatch(setRerender(!rerender1));
        // do whatever you want with response
      } else {
        toast.error(response.data.message);
      }
    },
    [rerender1, dispatch]
  );
  const { canDo, currentActivity } = usePermissions();

  const { onDelete, deleteRecord, showPrompt, onCancel,loading } = useDelete(
    deleteUnit,
    true,
    handleAfterDelete
  );
  const actions = ["Edit", "Delete"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          doEdit(unit.id);
          // if (canDo(moduleName, "Edit")) {
          //   doEdit(unit.id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          onDelete(unit.id);
          // if (canDo(moduleName, "Delete")) {
          //   onDelete(unit.id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, unit, onDelete]
  );
  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default UnitsTableAction;
