/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { person } from "../../../../app/pages/component-demo";
import { toAbsoluteUrl } from "../../../helpers";
import { use2Fa } from "../../../../customHooks/use2Fa/use2Fa";
import ConfirmationPrompt from "../../../../components/ConfirmationPrompt";
// import { toggle2FA } from "../../../../app/modules/auth/core/_requests";
import { toast } from "react-toastify";

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();

  // const handleAfterStatus = (status: any, response: any) => {
  //   if (status) {
  //     toast.success("2fa status changed");
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //   }
  // };

  // const {
  //   on2fa,
  //   show2faPrompt,
  //   twofaloading,
  //   twofaToUpdate,
  //   on2faCancel,
  //   Change2fa,
  // } = use2Fa(toggle2FA, true, handleAfterStatus);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl("/media/avatars/300-1.jpg")} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser.overview?.firstName} {currentUser.overview?.lastName}
              {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                Pro
              </span> */}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {currentUser.overview?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link
          to={"/edituser?id=" + `${currentUser.id || currentUser._id}`}
          className="menu-link px-5"
        >
          My Profile
        </Link>
      </div>

      {/* <div className="menu-item px-5">
        <span
          className="menu-link px-5"
          onClick={() => {
            on2fa(currentUser);
          }}
        >
          {currentUser?.is2FA ? "Disable 2FA" : "Enable 2Fa"}
        </span>
      </div> */}

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>

      {/* {show2faPrompt && (
        <ConfirmationPrompt
          title="Change 2fa Status"
          message="Are you sure you want to change the 2FA status ?"
          onCancel={on2faCancel}
          onConfirm={Change2fa}
          isloading={twofaloading}
        />
      )} */}
    </div>
  );
};

export { HeaderUserMenu };
