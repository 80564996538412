import { FC } from "react";
import FormView1 from "../../../components/Forms/index1";

const AddUser: FC = () => {
  const Base_Url = process.env.REACT_APP_API_URL;

  return (
    <>
      <FormView1 url={`${Base_Url}`} />
    </>
  );
};

export { AddUser };
