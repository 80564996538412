import React from 'react'
import { TSwitchProps } from '../../types/SwitchBox'


const Switch = ({label,onChange, ...props}:TSwitchProps) => {
    return (
        <>
            <div className='form-check form-switch form-switch-lg form-check-custom form-check-solid'>
                <label className='form-check-label d-flex align-items-center'>
                    <input
                        {...props}
                        onChange={onChange}
                        className='form-check-input me-2'
                        type='checkbox'
                        defaultChecked={true}
                    />{label}
                </label>
            </div>
        </>

    )
}

export default Switch