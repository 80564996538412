
  import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const SETTING_GET_URL = "/static-response/setting/setting.json";
const SETTING_DELETE_URL = BASE_URL +"/settings/";
const SETTING_ADD_URL = BASE_URL +"/settings/";
const SETTING_EDIT_URL = BASE_URL +"/settings/";
const SETTING_ALLMATCHED_ACCESSORIES_EDIT_URL = BASE_URL +"/accessory/matchedAccessory";
const SETTING_MAPPING_PRODUCTS_ACCESSORIES = BASE_URL + "/mapping"


export const getSetting = (searchParams?: string) => {
  return axios.get(SETTING_GET_URL + "?q" + searchParams);
};

export const deleteSetting = (Id: string) => {
  return axios.delete(SETTING_DELETE_URL +Id);
};

export const addMapping = (payload:any) => {
    return axios.post(SETTING_MAPPING_PRODUCTS_ACCESSORIES,payload);
  };

export const MactchingAccessories = (categoryid:string,subcategoryid:string) => {
  return axios.get(SETTING_ALLMATCHED_ACCESSORIES_EDIT_URL  + `?category=${categoryid}&subcategory=${subcategoryid}`);
};  

  export const editSetting = (Id: string) => {
    return axios.put(SETTING_EDIT_URL + Id);
  };


  