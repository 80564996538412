import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getAllGoogleLanguage } from "../../request/languageRequest";
import LanguageForm1 from "./LanguagesForm1";
import { addBulkLanguage } from "../../request/languageRequest";
import Search from "../../../components/Search";
import { setSearchTermForGoogleLang } from "../../../redux/slices/languageSlice";
import { useDispatch } from "react-redux";
import {
  TAddLanguageModalProps,
  TFormikValues,
} from "../../../types/Module/Language/LanguageFormModal";

const LanguageFormModal = ({
  onClose,
  currentRecord,
}: TAddLanguageModalProps) => {
  const [gl, setgl] = useState<TFormikValues>([]);
  const [loading, setLoading] = useState(false);
  const [loadingLanguages, setLoadingLanguages] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const fn = async () => {
      setLoadingLanguages(true);
      try {
        const res = await getAllGoogleLanguage();
        if (res.data.status) {
          setgl(res.data.data);
        } else {
          toast.error("Something went wrong");
          typeof onClose === "function" && onClose();
        }
      } catch (error) {
        toast.error("Network Error");
        typeof onClose === "function" && onClose();
      } finally {
        setLoadingLanguages(false);
      }
    };
    fn();
  }, []);

  useEffect(() => {
    formik.setValues(gl);
  }, [gl]);

  const formik = useFormik<TFormikValues>({
    initialValues: gl,
    onSubmit: (values, { setSubmitting }) => {
      let payload = values
        .filter((value) => {
          return value.isSelected == true;
        })
        .map((language) => {
          return {
            languageName: language.languageName,
            languageCode: language.languageCode,
            languageId: language.id,
          };
        });
      setLoading(true);
      const fn = async () => {
        setSubmitting(true);
        try {
          const res = await addBulkLanguage(payload);
          if (res.data.status) {
            toast.success("Record saved successfully");
            typeof onClose === "function" && onClose();
            formik.resetForm();
            dispatch(setSearchTermForGoogleLang(""));
          } else {
            toast.error(`${res.data.message}`);
          }
        } catch (error) {
          //TODO: make error when go live
          toast.error("Network error");
        } finally {
          setSubmitting(false);
          setLoading(false);
        }
      };
      fn();
    },
  });
  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      size="xl"
      show={true}
      dialogClassName="modal-md modal-dialog-centered w-100"
      aria-hidden="true"
      onHide={onClose}
    >
      <div className="modal-header">
        <Search
          placeholder={"Search Languages"}
          handleSearchParams={(value: string) =>
            dispatch(setSearchTermForGoogleLang(value))
          }
        />
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onClose}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body scroll-y" style={{ maxHeight: "60vh" }}>
        <LanguageForm1 {...formik} isLoadingLanguages={loadingLanguages} />
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <button type="button" className="btn btn-danger me-4" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={formik.isSubmitting || !formik.isValid || loadingLanguages}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default LanguageFormModal;
