import React from 'react'
import './Loader.css'
const Loader = ({rows}:any) => {
    const rowsPerPage = rows.length;
  return (
    <div className="d-flex justify-content-center ">
    <div>
      <div className="table-loading-overlay" style={{ top: `${(rowsPerPage / 2) * rowsPerPage}px` }}>
        <div className="newtons-cradle">
          <div className="newtons-cradle__dot"></div>
          <div className="newtons-cradle__dot"></div>
          <div className="newtons-cradle__dot"></div>
          <div className="newtons-cradle__dot"></div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Loader
