
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import PermissionPage from "../generatedTablePage/PermissionPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddPermission from "../generatedAddEdit/Permission/AddPermission";
  import EditPermission from "../generatedAddEdit/Permission/EditPermission";
  

  
  
  const PermissionRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<PermissionPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditPermission/>
    }
  />
  <Route
    path="/add"
    element={
      <AddPermission/>
    }
  />      
         
      </Routes>
    );
  };
  export default PermissionRoutes
