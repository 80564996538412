import { Modal } from "react-bootstrap";
import { KTSVG } from "../_metronic/helpers";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getStoreAccessory } from "../generatedRequest/accessory";
import MappedAccessoryForm from "../generatedForms/MappedAccessoryForm";
import Search from "../components/Search";
import { setMappedAccessorySearchTerm } from "../generatedSlice/accessorySlice";
import { useDispatch,useSelector } from "react-redux";
import { RootState } from "../redux/store";

type TMappedAccessoryModalProps = {
  onClose?: () => void;
  currentRecord: any;
  currentregion: any;
  currentMappedAccessory:any;
  onChange:any
};

const MappedAccessoryFormModal = ({
  onClose,
  currentRecord,
  currentregion,
  currentMappedAccessory,
  onChange
}: TMappedAccessoryModalProps) => {
  const [loading, setLoading] = useState(false);
  const [MapAccessories, setMapAccessories] = useState([]);
  const [loadingMappedAccessories, setLoadingMappedAccessories] = useState(false);
  const [initload,setInitload] = useState(true)
  const dispatch = useDispatch();
  const { searchMappedAccessories} = useSelector((state: RootState) => state.accessory);


  useEffect(() => {
    const fn = async () => {
      setLoadingMappedAccessories(true);
      try {
        const res = await getStoreAccessory(currentregion+`?search=${searchMappedAccessories}`);
        if (res.data.status) {
          setMapAccessories(res.data.data);
        } else {
          toast.error(res.data.message);
          setMapAccessories([])
        }
      } catch (error) {
        toast.error("Network Error");
        typeof onClose === "function" && onClose();
      } finally {
        setLoadingMappedAccessories(false);
        setInitload(false)
      }
    };
    fn();
  }, [searchMappedAccessories]);


  const handleSearchParams = (value:any) => {
    dispatch(setMappedAccessorySearchTerm(value))
  }

  const handleSubmit = () => {};

  //   useEffect(() => {
  //     formik.setValues(gl);
  //   }, [gl]);

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      size="xl"
      show={true}
      dialogClassName="modal-md modal-dialog-centered w-100"
      aria-hidden="true"
      onHide={onClose}
    >
      <div className="modal-header">
        {/* <Search
          placeholder={"Search Products"}
          handleSearchParams={(value: string) =>
            dispatch(setMappedAccessorySearchTerm(value))
          }
        /> */}
        <h2 className="modal-title fw-bolder">Manage Products</h2>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onClose}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body scroll-y" style={{ maxHeight: "60vh" , minHeight:"60vh" }}>
        <MappedAccessoryForm
          MapAccessories={MapAccessories}
          initload={initload}
          onChange={onChange}
          currentMappedAccessory={currentMappedAccessory}
          isLoadingMappedAccessories={loadingMappedAccessories}
          handleSearchParams={handleSearchParams}
        />
      </div>

    </Modal>
  );
};

export default MappedAccessoryFormModal;
