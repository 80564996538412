
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import AccessoryPage from "../generatedTablePage/AccessoryPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddAccessory from "../generatedAddEdit/accessory/AddAccessory";
  import EditAccessory from "../generatedAddEdit/accessory/EditAccessory";
  

  
  
  const AccessoryRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<AccessoryPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditAccessory/>
    }
  />
  <Route
    path="/add"
    element={
      <AddAccessory/>
    }
  />      
         
      </Routes>
    );
  };
  export default AccessoryRoutes
