
      import React from 'react'
      import HelpFormModal from '../../generatedFormModal/HelpFormModal'
      
      const initialValues={name:""}

      const AddHelp=({currentRecord}:{currentRecord?:any})=> {
        return (
          <div>
           <HelpFormModal isEdit={false} currentRecord={
            initialValues 
           }/> 
          </div>
        )
      }
      
      export default AddHelp
         