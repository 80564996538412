import ProductForm from "../generatedForms/Product";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { ProductSchema } from "../generatedValidations/ProductSchema";
import { useSearchParams } from "react-router-dom";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import { getRegion } from "../app/request/regionRequest";
import { getCategory } from "../generatedRequest/category";
import {
  getSingleProduct,
  addProduct,
  editProduct,
  scapeProduct,
  verifyDataUsingGpt,
} from "../generatedRequest/product";
import axios from "axios";

type TAccessoryModalProps = {
  isEdit: boolean;
};

const ProductFormModal = ({ isEdit }: TAccessoryModalProps) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [allRegions, setAllRegions] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  let pdfurl = "";
  const [isScrapping, setisScrapping] = useState(false);
  const [initload, setInitload] = useState(true);
  const [searchparams, setSearchParams] = useState("");
  const [productToScrape, setProductToScrape] = useState<any>({});
  const [secondaryLoading,setSecondaryLoading] = useState(false)
  const [ternaryLoading,setTernaryLoading] = useState(false)
  const [attributetobefetched,setattributetobefetched] =useState ("")
  const [fetchedValue,setFetchedValue]= useState("")
  const [secondarySourceToScrape, setsecondarySourceToScrape] = useState<any>(
    {}
  );
  const [primarySourceData,setPrimarySourceData] =  useState ([])
  const [secodnarySourceData,setSecondarySourceData] =  useState ([])
  const [chatGptData,setChatGptData] =  useState ([])
  const [products, setProducts] = useState([]);
  const productID = queryParameters.get("q") || "";
  const [initialValues, setInitialValues] = useState({
    productName: "",
    regions: [] as any[],
    category: [] as any[],
    subcategory: [] as any[],
    description: "",
    productImages: [] as any[],
    attributes: [] as any[],
    brand: "",
    model: "",
    modelNumber: "",
    // sku: ""
  });

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editProduct(payload, productID)
        : await addProduct(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Product is edited Successfully")
          : toast.success("Product is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchParams = (searchString: string) => {
    console.log("sdasdasd")
    setSearchParams(searchString);
  };

  const handleScrapeProduct = async (payload: string) => {
    setisScrapping(true);
    setPrimarySourceData([])
    let primaryData:any = []
    if (productToScrape?.[`0`]?.source == "noteb") {
      try {
        let temp2 = {
          url: productToScrape?.[`0`].url,
        };
        const res = await axios.post(
          "https://gafadminback.inpixelinc.com/api/v1/product/scrapingNoteb",
          temp2
        );
        if (res.data.status) {
          pdfurl = res.data.data;
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
      }
    } else if (productToScrape?.[`0`]?.source == "hp") {
      // try {
      //   let temp2 = {
      //     productName: productToScrape?.[`0`]?.product_id,
      //   };
      //   const res = await axios.post(
      //     "http://localhost:3001/api/v1/product/scrapingHPPDF",
      //     temp2
      //   );
      //   if (res.data.status) {
      //     pdfurl = res.data.data;
      //   } else {
      //     toast.error(res.data.message);
      //   }
      // } catch (error) {
      //   toast.error("Network Error");
      // } finally {
      // }
      pdfurl = productToScrape?.[`0`]?.product_id;
    }else if (productToScrape?.[`0`]?.source == "dell") {
      pdfurl = productToScrape?.[`0`]?.product_id;
    } else if (productToScrape?.[`0`]?.source == "asus") {
      pdfurl = productToScrape?.[`0`]?.product_id;
    }
    
    else if (productToScrape?.[`0`]?.source == "lenovo") {
      pdfurl = productToScrape?.[`0`]?.product_id;
    } else if (productToScrape?.[`0`]?.source == "acer") {
      pdfurl = productToScrape?.[`0`]?.product_id;
    } 
    else if (productToScrape?.[`0`]?.source == "microsoft") {
      pdfurl = productToScrape?.[`0`]?.product_id;
    } 
    
    else {
      pdfurl = productToScrape?.["0"]?.url;
    }

    let temp: { productName: any; attributesArray: any; source: string } = {
      productName: pdfurl,
      attributesArray: formik?.values?.subcategory?.[
        `0`
      ]?.supportedAttributes?.map(
        (attribute: { supportedUnits: any; attributeName: any }) => ({
          attributeName: attribute?.attributeName,
          unit: attribute?.supportedUnits?.map((temp: any) => {
            return {
              _id: temp?._id,
              name: temp?.name,
            };
          })?.[`0`],
        })
      ),
      source: productToScrape?.[`0`]?.source,
    };

    try {
      const res = await scapeProduct(temp);
      if (res.data.status) {
        formik.setValues({
          ...formik.values,
          ...res.data.data,
        });   
        setPrimarySourceData(res?.data?.data?.attributes)
      primaryData = res?.data?.data?.attributes
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setisScrapping(false);
      handleSecondaryScrapeProduct(primaryData)
    }
  };

  const handleSecondaryScrapeProduct = async (dataFromPrimary:any) => {
    let secondaryData:any = []
    setSecondarySourceData([])
    setSecondaryLoading(true)
    if (secondarySourceToScrape?.[`0`]?.source == "noteb") {
      try {
        let temp2 = {
          url: secondarySourceToScrape?.[`0`].url,
        };
        const res = await axios.post(
          "https://gafadminback.inpixelinc.com/api/v1/product/scrapingNoteb",
          temp2
        );
        if (res.data.status) {
          pdfurl = res.data.data;
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
      }
    } else if (secondarySourceToScrape?.[`0`]?.source == "hp") {
      pdfurl = secondarySourceToScrape?.[`0`]?.product_id;
  
    } else if (secondarySourceToScrape?.[`0`]?.source == "lenovo") {
      pdfurl = secondarySourceToScrape?.[`0`]?.product_id;
    }else if (secondarySourceToScrape?.[`0`]?.source == "dell") {
      pdfurl = secondarySourceToScrape?.[`0`]?.product_id;
    }
    else if (secondarySourceToScrape?.[`0`]?.source == "asus") {
      pdfurl = secondarySourceToScrape?.[`0`]?.product_id;
    }else if (secondarySourceToScrape?.[`0`]?.source == "acer") {
      pdfurl = secondarySourceToScrape?.[`0`]?.product_id;
    } 
    else if (secondarySourceToScrape?.[`0`]?.source == "microsoft") {
      pdfurl = secondarySourceToScrape?.[`0`]?.product_id;
    } 

    
    else {
      pdfurl = secondarySourceToScrape?.["0"]?.url;
    }

    let temp: { productName: any; attributesArray: any; source: string } = {
      productName: pdfurl,
      // attributesArray: [
      //   {
      //     attributeName: payload?.attributeName,
      //     unit: payload?.supportedUnits?.map((temp: any) => {
      //       return {
      //         _id: temp?._id,
      //         name: temp?.name,
      //       };
      //     })?.[`0`],
      //   },
      // ],
      attributesArray: formik?.values?.subcategory?.[
        `0`
      ]?.supportedAttributes?.map(
        (attribute: { supportedUnits: any; attributeName: any }) => ({
          attributeName: attribute?.attributeName,
          unit: attribute?.supportedUnits?.map((temp: any) => {
            return {
              _id: temp?._id,
              name: temp?.name,
            };
          })?.[`0`],
        })
      ),
      source: secondarySourceToScrape?.[`0`]?.source,
    };
   
    try {
      const res = await scapeProduct(temp);
      if (res.data.status) {
        // formik.setValues({
        //   ...formik.values,
        //   ...res.data.data,
        // });
        // if(modifyData) {
        //   const updatedValues: Partial<typeof formik.values> = Object.keys(res.data.data).reduce((acc:any, key) => {
        //     // Check if the value in formik.values is empty before updating
        //     if (!formik.values[key as keyof typeof formik.values]) {
        //       acc[key as keyof typeof formik.values] = res.data.data[key];
        //     }
        //     return acc;
        //   }, {});
          
        //   const updatedAttributes = res.data.data.attributes;
          
        //   formik.setValues({
        //     ...formik.values,
        //     ...updatedValues,
        //     attributes: formik.values.attributes
        //       ? formik.values.attributes.map((existingAttribute) => {
        //           const matchingAttribute = updatedAttributes.find(
        //             (newAttribute:any) => newAttribute.attributeName === existingAttribute.attributeName
        //           );
          
        //           return matchingAttribute || existingAttribute;
        //         })
        //       : updatedAttributes,
        //   });

        // }
        //  else {
        //    return res
           
        // }
      
        setSecondarySourceData(res?.data?.data?.attributes)

        secondaryData = res?.data?.data?.attributes
        
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setSecondaryLoading(false)
      // setattributetobefetched("")
      VerifyAttributeData(dataFromPrimary,secondaryData)
    }
  };

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await axios.get(
          // `https://icecat.biz/search/rest/sug-two?query=${searchparams}&tmp=64e8872f9efaf5.49506927`
          `https://gafadminback.inpixelinc.com/api/v1/product/productListIcecat/${searchparams}/${formik.values.brand}`
        );

        if (resp.data.status) {
          setProducts(resp?.data?.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

   searchparams && formik?.values?.brand && fn();
  }, [searchparams]);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getRegion(`page=1&limit=100`);
        if (resp.data.status) {
          setAllRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getCategory();
        if (resp.data.status) {
          setAllCategory(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ProductSchema,
    onSubmit: (values, { setSubmitting }) => {
      let payload = {
        productName: values.productName,
        attributes: values.attributes,
        // reduce((result:any, obj) => {
        //   const key = Object.keys(obj)[0]; // Assuming each object contains only one key-value pair
        //   const value = obj[key];
        //   return { ...result, [key]: value };
        // }, {}),
        productImages: values.productImages,
        description: values.description,
        subcategory: values.subcategory.map((data: any) => data._id),
        regions: values.regions.map((region: any) => region.id || region._id),
        category: values.category.map((data: any) => data._id)[0],
        brand: values.brand,
        model: values.model,
        modelNumber: values.modelNumber,
        // sku:values.sku
      };

      handleSubmit(payload);
    },
  });

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);

      try {
        const response = await getSingleProduct(productID); // Call your API to get user data
        if (response.data.status) {
          formik.setValues({
            ...response.data.data,
            category: [response.data.data?.category],
            subcategory: [response.data.data?.subcategory],
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchProductData();
    } else {
      setInitload(false);
    }
  }, [isEdit, productID]);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }

  const VerifyAttributeData = async (dataFromPrimary:any,dataFromSecondary:any) => {
    setTernaryLoading(true)
    setChatGptData([])
    //  const res:any = await handleSecondaryScrapeProduct()
  
     let payload = {attributeArray: [dataFromPrimary,dataFromSecondary]}
  

    try {
      const res =  await verifyDataUsingGpt(payload)
      
      if (res.data.status) {
           setChatGptData(res.data.data)
          // if(res.data?.data?.isSimilar) {          
          //   toast.success(res.data?.data?.message)
          //   setFetchedValue('')
          // } else {
          //     toast.warn (`Changes Suggested`)
          //     setFetchedValue(res?.data?.data?.value)           
          // }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setTernaryLoading(false)
    }
  };


  return (
    <>
      <ProductForm
        allCategory={allCategory}
        isloading={loading}
        allRegions={allRegions}
        handleScrapeProduct={handleScrapeProduct}
        handleSecondaryScrapeProduct={handleSecondaryScrapeProduct}
        {...formik}
        isEdit={isEdit}
        isScrapping={isScrapping}
        handleSearchParams={handleSearchParams}
        products={products}
        setProductToScrape={setProductToScrape}
        setsecondarySourceToScrape={setsecondarySourceToScrape}
        secondaryLoading={secondaryLoading}
        attributetobefetched={attributetobefetched}
        ternaryLoading={ternaryLoading}
        VerifyAttributeData= {VerifyAttributeData}
        fetchedValue={fetchedValue}
        primarySourceData={primarySourceData}
        secondarySourceData={secodnarySourceData}
        chatGptData={chatGptData}
        productToScrape={productToScrape}
        secondarySourceToScrape={secondarySourceToScrape} 

      />

      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default ProductFormModal;



