import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers/components/KTSVG";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Badge from "../../../components/Badge";

type TCarouselDemoProps = {
  onCancel: any;
  Images: string[];
  title: string;
  categoryName: string;
  subCategoryName: string;
  regionName: string;
  sku: string;
  description: string;
  price: number;
};

const CarouselDemo = ({
  onCancel,
  Images,
  title,
  categoryName,
  subCategoryName,
  regionName,
  sku,
  description,
  price,
}: TCarouselDemoProps) => {
  const imagesToShow = 5; // Number of images to show at a time
  const [startIndex, setStartIndex] = useState(0);
  const handlePrevious = () => {
    const newIndex = (startIndex - 1 + Images.length) % Images.length;
    setStartIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (startIndex + 1) % Images.length;
    setStartIndex(newIndex);
  };

  let visibleImages = Images.slice(startIndex, startIndex + imagesToShow);
  if (visibleImages.length < imagesToShow) {
    const remainingImages = imagesToShow - visibleImages.length;
    const additionalImages = Images.slice(0, remainingImages);
    visibleImages = [...visibleImages, ...additionalImages];
  }

  return (
    <div className="card p-5">
      <Modal
        className="modal fade"
        id="kt_modal_select_location"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        size="xl"
        show={true}
        dialogClassName="modal-md modal-dialog-centered"
        aria-hidden="true"
        onHide={onCancel}
      >
        <div className="modal-header">
          <h2 className="modal-title fw-bolder">{"Preview"}</h2>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            onClick={onCancel}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon-2x"
            />
          </div>
        </div>

        <div className="d-flex justify-content-between p-5">
          <div className="my-carousel">
            <Carousel showArrows={true} selectedItem={startIndex}>
              {visibleImages.map((image: any, index: number) => (
                <div className="image-container" key={index}>
                  <img
                    src={`${process.env.REACT_APP_API_URL_FOR_IMAGE}${image}`}
                    alt="images1"
                  />
                </div>
              ))}
            </Carousel>
            <div className="nav-btns">
              <button className="prev-btn" onClick={handlePrevious}>
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/icons/duotune/arrows/top-arrow.png"
                  )}
                />
              </button>
              <button className="next-btn" onClick={handleNext}>
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/icons/duotune/arrows/bottom-arrow.png"
                  )}
                />
              </button>
            </div>
          </div>
          <div className="carousel-content">
            <h3 className="mb-4">
              {title.charAt(0).toUpperCase() + title.slice(1) || ""}
            </h3>
            <h4 className="mb-4">
              <span className="fw-bold">Category Name: </span>
              {categoryName || ""}
            </h4>
            <h5 className="mb-4">
              <span className="fw-bold">SubCategory Name: </span>
              {subCategoryName || ""}
            </h5>

            <h6 className="mb-4">
              <span className="fw-bold">Region Name: </span>{" "}
              {(
                <Badge variant="info" className="fs-7 fw-semibold">
                  {regionName}
                </Badge>
              ) || ""}
            </h6>
            <p className="mb-4">
              <span>SKU : </span>
              {sku || ""}
            </p>
            
            <p className="mb-4 description">{description || ""}</p>
            <h2>{price || "99"}</h2>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CarouselDemo;
