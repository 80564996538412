
    import { createSlice, PayloadAction } from '@reduxjs/toolkit'
    
    type TSubCategoryState =  {
        data: [],
        perPage: number,
        currentPage: number,
        totalPage: number,
        searchTerm: string,
        loading: boolean,
        sortBy: string,
        sortColumn:string,
        rerender1:boolean
    }
    
    
    const initialCategoryState: TSubCategoryState = {
        data: [],
        perPage: 10,
        currentPage: 1,
        totalPage: 1,
        searchTerm: "",
        loading: false,
        sortBy: "desc",
        sortColumn:"",
        rerender1:false
    
        
    }
    
    export const subcategorySlice = createSlice({
        name: 'subcategory',
        initialState:initialCategoryState,
        reducers: {
            setSubCategory: (state:TSubCategoryState, { payload }: PayloadAction<[]>) => {
              state.data=payload
             },
            setPerPage: (state:TSubCategoryState, {payload}: PayloadAction<number>) => {
                state.perPage = payload;
            },
            setCurrentPage: (state:TSubCategoryState, {payload}: PayloadAction<number>) => { 
                state.currentPage = payload;
            },
            setTotalPage: (state:TSubCategoryState, {payload}: PayloadAction<number>) => { 
                state.totalPage = payload
             },
            setSearchTerm: (state:TSubCategoryState, {payload}: PayloadAction<string>) => { 
                state.searchTerm = payload;
            },
            setSortBy: (state:TSubCategoryState, {payload}: PayloadAction<string>) => { 
              state.sortBy = payload;
          },
          setSortColumn: (state:TSubCategoryState, {payload}: PayloadAction<string>) => { 
            state.sortColumn = payload;
            state.sortBy = "desc";
        },
            setLoading: (state:TSubCategoryState, {payload}: PayloadAction<boolean>) => { 
              state.loading = payload
            },
            setRerender: (state:TSubCategoryState, {payload}: PayloadAction<boolean>) => {
                state.rerender1 = payload
             },
        },
    })
    
    
    export const { setSubCategory, setPerPage, setCurrentPage, setSortColumn,setSortBy, setTotalPage, setSearchTerm, setLoading,setRerender } = subcategorySlice.actions
    export default subcategorySlice.reducer
    