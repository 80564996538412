import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { changeStatus } from "../../app/request/languageRequest";

// Define the hook function with three parameters
export const useStatus = ( makeRequest:Function, askConfirmation:boolean = true, afterStatus?:Function ) => {
    // Define three states using the useState hook
    const [statusToUpdate, setStatusToUpdate] = useState({});
    const [showStatusPrompt, setStatusShowPrompt] = useState<boolean>(false);
    const [statusloading, setstatusLoading] = useState<boolean>(false);
    // Define the onDelete function, which is called when the user clicks the delete button
    const onStatus = useCallback((id: any) => {
        // Set the recordIdToDelete state to the ID of the record to be deleted
        setStatusToUpdate(id);
        // If askConfirmation is false, call the deleteRecord function directly
        if (!askConfirmation) {
                
        } else {
            // Otherwise, set the showPrompt state to true to show the confirmation dialog
            setStatusShowPrompt(true);
        }
    },[askConfirmation])

    // Define the deleteRecord function using the useCallback hook to memoize the function
    const ChangeStatus = useCallback( async () => {
         
     
        // Initialize a flag to indicate whether the delete operation was successful
        let isSuccessful = false
        let response : any ={};
        try {
            // Set the loading state to true to indicate that the delete operation is in progress
            setstatusLoading(true);
            
        
            // Define the payload object with the ID of the record to be deleted
            const payload = statusToUpdate

            console.log(payload)
            // Call the makeRequest function with the payload object to delete the record
            response = await makeRequest(payload);
            // If the server returns a successful response status, set the isSuccessful flag to true
            if (response?.data?.status) {
                isSuccessful = true
            }
          }
        catch (error) {
            toast.error("Network error")
        }
        finally  {
            // Set the loading state to false to indicate that the delete operation has completed
            setstatusLoading(false);
            // Reset the recordIdToDelete state to an empty string
            setStatusToUpdate('');
            setStatusShowPrompt(false);
            // Call the afterDelete callback function with the isSuccessful flag as an argument
            // (if the afterDelete function is provided as a parameter)
            if(typeof makeRequest === 'function') {
            afterStatus && afterStatus(isSuccessful, response);
            }
        }
    }, [afterStatus,statusToUpdate,makeRequest])

    // Define the onCancel function, which is called when the user clicks the cancel button
       const onstatusCancel = useCallback(() => {
        // Reset the recordIdToDelete state to an empty string
        setStatusToUpdate('');
        // Set the showPrompt state to false to hide the confirmation dialog
        setStatusShowPrompt(false);
    },[])

    // Return an object containing the onDelete, showPrompt, loading, deleteRecord, and onCancel functions
    return { onStatus, showStatusPrompt, statusloading, statusToUpdate, onstatusCancel,ChangeStatus };
}
