
      import React from 'react'
      import SubCategoryFormModal from '../../generatedFormModal/SubCategoryFormModal'

     
      
      const EditSubCategory=()=> {
        return (
          <div>
           <SubCategoryFormModal isEdit={true}/> 
          </div>
        )
      }
      
      export default EditSubCategory

      