import React from 'react'
import { TProgressBarProps } from '../../types/Progress';


const ProgressBar = ({ value, variant, height, ...props }: TProgressBarProps) => {
    let _className = "";
    _className = ` bg-${variant} h-${height}px`;

    return (
        <div className={' progress bg-opacity-50 ' + _className}>
            <div
                className={' rounded ' + _className}
                role='progressbar'
                style={{ width: `${value}%` }}
            ></div>
        </div>
    )
}

export default ProgressBar