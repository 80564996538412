import React, { useCallback, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import { TCategoryFormProp } from "../types/Module/Category/CategoryForm";
import { TSubcategory } from "../types/Module/Category/CategoryFormModal";
import { TagBox, TagBoxItem } from "../components/TagBox";

const CategoryForm = ({
  errors,
  isEdit,
  isloading,
  isSubmitting,
  touched,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  allSubCategories,
}: TCategoryFormProp) => {
  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };
  const navigate = useNavigate();
  const handleSNewTag = (tagName: string) => {
    if (tagName) {
      const newTag: TagBoxItem = {
        name: tagName,
        id: Date.now().toString(), // convert the ID to a string
      };
      setFieldValue("notes", [...values.notes, newTag]);
    }
    // add the new tag to the list of tags
  };

  const handleSDeleteTag = (tag: TagBoxItem, index: number) => {
    const newTags = [...values.notes];
    newTags.splice(index, 1);
    setFieldValue("notes", newTags); // remove the tag at the specified index
  };
  return (
    <div className="container ms-1">
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-1 cursor-pointer">
              <div className="card-title m-0">
                <div
                  role={"button"}
                  className="btn btn-sm btn-light me-5"
                  onClick={() => navigate("/category")}
                >
                  <i className="fa-solid fa-arrow-left fs-2"></i>
                </div>
                <h3 className="fw-bolder m-0">
                  {isEdit ? "Edit" : "Add"} Category
                </h3>
              </div>
            </div>
            <div className="card-body p-9">
              <div className="row mb-6">
                <label className="col-lg-2 col-form-label required fw-bold fs-6">
                  Name
                </label>
                <div className="col-lg-10">
                  <input
                    disabled={isloading}
                    placeholder="Category Name"
                    {...getFieldProps("categoryName")}
                    className={clsx("form-control bg-transparent", {
                      "is-invalid":
                        touched?.categoryName && errors?.categoryName,
                    })}
                    type="text"
                  />
                  {touched?.categoryName && errors?.categoryName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.categoryName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card p-10 mb-5">
            <div className="container p-0 m-0">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    SubCategory
                  </label>
                  <div onClick={() => setFieldTouched("subcategory")}>
                    <Multiselect
                      options={allSubCategories}
                      style={style}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="subcategoryName"
                      placeholder="Select SubCategory"
                      selectedValues={values.subcategory}
                      onSelect={async (e) => {
                        await setFieldValue("subcategory", e);
                        setFieldTouched("subcategory");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("subcategory", e);
                        setFieldTouched("subcategory");
                      }}
                      selectedValueDecorator={(
                        value: string,
                        option: TSubcategory
                      ) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {touched?.subcategory && errors?.subcategory && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {/* <span role="alert">{errors?.subcategory}</span> */}
                        <span role="alert">
                          {typeof errors?.subcategory === "string"
                            ? errors.subcategory
                            : ""}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card p-10 mb-5">
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex flex-column flex-md-column w-md-100">
                <div className="row mb-6">
                  <label className="col-lg-2 col-form-label fw-bold fs-6">
                    Notes
                  </label>
                  <div className="col-lg-10">
                    <TagBox
                      tags={values.notes || []}
                      handleNew={handleSNewTag}
                      handleDelete={handleSDeleteTag}
                      placeholder={"Notes"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;
