import * as Yup from 'yup'

export interface ICreateAccount {
  accessoryType: string
}

const createAccountSchemas = [
  Yup.object({
    accessoryType: Yup.string().required().label('Accessory Type'),
  }),
  Yup.object({
    deviceType: Yup.string().required().label('Device Type'),
  }),
  Yup.object({
    monitorType: Yup.string().required().label('Monitor Type'),
  }),
  Yup.object({
    affirmation: Yup.string().required().label('Affirmation'),
  }),
]

const inits: ICreateAccount = {
  accessoryType: '',
}

export {createAccountSchemas, inits}
