import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import clsx from "clsx";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import Select from "../components/CustomSelect";
import { TOptionType } from "../types/CustomSelect";
import { TagBox, TagBoxItem } from "../components/TagBox";
import {
  TAttributeFormProp,
  TStatusOption,
} from "../types/Module/Attribute/Attribute";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";

const AttributeForm = ({
  errors,
  touched,
  isloading,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  allunits,
}: TAttributeFormProp) => {
  const OptionsStatus: TOptionType<string>[] = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const [optionsStatus, setOptionStatus] = useState<TOptionType<string>>({
    value: "active",
    label: "Active",
  });
  const handleSNewTag = (tagName: string) => {
    if (tagName) {
      const newTag: TagBoxItem = {
        name: tagName,
        id: Date.now().toString(),
      };
      setFieldValue("notes", [...values.notes, newTag]);
    }
  };

  const handleSDeleteTag = (tag: TagBoxItem, index: number) => {
    const newTags = [...values.notes];
    newTags.splice(index, 1);
    setFieldValue("notes", newTags); // remove the tag at the specified index
  };
  const convertStatusToOptionType = (
    status: TStatusOption | string
  ): TOptionType<string> | undefined => {
    if (status) {
      if (typeof status === "string") {
        return { value: status, label: status };
      } else {
        return status;
      }
    }
    return undefined;
  };
  return (
    <div className="w-100 w-md-100 pt-5">
      <div className="d-flex flex-column flex-md-row  mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Name
          </label>
          <input
            placeholder="Attribute Name"
            disabled={isloading}
            {...getFieldProps("attributeName")}
            className={clsx("form-control bg-transparent", {
              "is-invalid": touched?.attributeName && errors?.attributeName,
            })}
            type="text"
          />
          {touched?.attributeName && errors?.attributeName && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.attributeName}</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10 ">
          <label className="form-label fs-6 fw-bolder text-dark required ">
            Units
          </label>
          <div onClick={() => setFieldTouched("supportedUnits")}>
            <Multiselect
              className="multiselect-container multiselect-search-box"
              options={allunits}
              disable={isloading}
              customArrow={<CustomArrow />}
              customCloseIcon={<CustomCrossButton />}
              displayValue="name"
              placeholder="Select Units"
              selectedValues={values.supportedUnits}
              onSelect={async (e) => {
                await setFieldValue("supportedUnits", e);
                setFieldTouched("supportedUnits");
              }}
              onRemove={async (e) => {
                await setFieldValue("supportedUnits", e);
                setFieldTouched("supportedUnits");
              }}
              selectedValueDecorator={(value: string, option: any) => {
                return <CustomTooltip value={value} option={option} />;
              }}
            />
          </div>
          {touched?.supportedUnits && errors?.supportedUnits && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.supportedUnits}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row mb-3 w-100">
        <div className="d-flex flex-column flex-md-column  w-md-50 w-100 me-md-10 ">
          <label className="form-label fs-6 fw-bolder text-dark">Notes</label>

          <TagBox
            tags={values.notes || []}
            handleNew={handleSNewTag}
            handleDelete={handleSDeleteTag}
            placeholder={"Notes"}
          />
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10 ">
          <label className="form-label fs-6 fw-bolder text-dark required ">
            Status
          </label>
          <div onClick={() => setFieldTouched("status")}>
            <Select
              value={convertStatusToOptionType(values.status) || optionsStatus}
              name="Select Status"
              onChange={(selectedValue) => {
                if (selectedValue) {
                  setOptionStatus(selectedValue);
                  setFieldValue("status", selectedValue);
                }
              }}
              options={OptionsStatus}
            />
          </div>
          {touched?.status && errors?.status && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.status}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row mb-3 w-100">
        <div className="d-flex flex-column flex-md-column  w-md-50 w-100 me-md-10 ">
          <label className="form-label fs-6 fw-bolder text-dark">
            IceCat Attribute
          </label>

          <input
            placeholder="IcecatAttributeName"
            disabled={isloading}
            {...getFieldProps("icecatName")}
            className={clsx("form-control bg-transparent", {})}
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

export default AttributeForm;
