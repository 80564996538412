import React from "react";
import AccessoryFormModal from "../../generatedFormModal/AccessoryFormModal";

const EditAccessory = () => {
  return (
    <div>
      <AccessoryFormModal isEdit={true} />
    </div>
  );
};

export default EditAccessory;
