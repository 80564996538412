import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format.")
    .min(3, "Minimum 3 characters.")
    .max(50, "Maximum 50 characters.")
    .required("Email is required."),
  password: Yup.string()
    .min(3, "Minimum 3 characters.")
    .max(50, "Maximum 50 characters.")
    .required("Password is required."),
});
