
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import ProductPage from "../generatedTablePage/ProductPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddProduct from "../generatedAddEdit/product/AddProduct";
  import EditProduct from "../generatedAddEdit/product/EditProduct";
  

  
  
  const ProductRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<ProductPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditProduct/>
    }
  />
  <Route
    path="/add"
    element={
      <AddProduct/>
    }
  />      
         
      </Routes>
    );
  };
  export default ProductRoutes
