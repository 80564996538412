import { useCallback, useEffect, useState } from "react";
import TableView from "../components/TableView";
import RetatilerdataTableAction from "../generatedTableActions/RetatilerdataTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setRetatilerdata,
  setSearchTerm,
  setRerender,
} from "../generatedSlice/retatilerDataSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getRetatilerdata } from "../generatedRequest/retatilerData";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import RetatilerdataList from "../generatedTableList/RetatilerdataList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import { getRetailerMappedAccessories } from "../generatedRequest/retailer";

const columns = [
  {
    name: "name",
    renderHeader: (rows: any) => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row: any) => (
      <>{row?.retailerData?.accessory?.accessoryName}</>
    ),
  },

  {
    name: "ModelNumber",
    renderHeader: (rows: any) => (
      <>
        <b>ModelNumber</b>
      </>
    ),
    renderCell: (row: any) => (
      <>{row?.retailerData?.accessory?.accessoryName}</>
    ),
  },

  {
    name: "sku",
    renderHeader: (rows: any) => (
      <>
        <b>SKU</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.retailerData?.accessory?.sku}</>,
  },
  {
    name: "brand",
    renderHeader: (rows: any) => (
      <>
        <b>Brand</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.retailerData?.accessory?.brand}</>,
  },
  // {
  //   name: "created",
  //   renderHeader: (rows: any) => (
  //     <>
  //       <b>Created At</b>
  //     </>
  //   ),
  //   renderCell: (row: any) => <>{row?.createdAt}</>,
  // },
  {
    name: "modified",
    renderHeader: (rows: any) => (
      <>
        <b>Modified At</b>
      </>
    ),
    renderCell: (row: any) => <>{row?.updatedAt}</>,
  },

  {
    name: "status",
    renderHeader: (rows: any) => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (row: any) => (
      <Badge
        variant={row?.statusForRetailer == "active" ? "success" : "danger"}
        className="fs-7 fw-bolder"
      >
        {row?.statusForRetailer}
      </Badge>
    ),
  },
  {
    name: "Actions",
    renderHeader: (rows: any) => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row: any) => <RetatilerdataTableAction retailer={row} />,
  },
];

const RetatilerdataPage = ({
  retailerId,
  regionId,
}: {
  retailerId: string;
  regionId: string;
}) => {
  const dispatch = useDispatch();

  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
    rerender1,
  } = useSelector((state: RootState) => state.retatilerData);

  const [initload, setInitload] = useState(true);

  useEffect(() => {
    const fn = async () => {
      dispatch(setLoading(true));
      let query = `${retailerId}?retailerRegionId=${regionId}`;
      try {
        const response = await getRetailerMappedAccessories(query);
        if (response.data.status) {
          dispatch(setRetatilerdata(response?.data?.data?.data));
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
      }
    };
    retailerId && regionId && fn();
  }, [rerender1, dispatch]);

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `${searchTerm}&currentpage=${currentPage}&perPage=${value}`;
      doSearch(searchQuery);
    },
    [dispatch, currentPage, perPage, searchTerm]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `${searchTerm}&currentpage=${value}&perPage=${perPage}`;
      doSearch(searchQuery);
    },
    [dispatch, currentPage, perPage, searchTerm]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `${value}&currentpage=${currentPage}&perPage=${perPage}`;
      doSearch(searchQuery);
    },
    [currentPage, perPage, dispatch]
  );

  const handleAfterSearch = useCallback((value: string) => { }, []);

  const { doSearch, searchLoading } = useSearchApi(
    getRetatilerdata,
    handleAfterSearch
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Retailer Accessory",
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        {/* <h2>Retatilerdatas</h2> */}
        {/* <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add Retatilerdata
        </Button> */}
      </div>
      <RetatilerdataList {...configuration} />
      {searchLoading && (
        <TableLoader isLoading={searchLoading} loadingMessage={"loading"} />
      )}
    </>
  );
};

export default RetatilerdataPage;
