import React, { useEffect, useRef, useState } from "react";
import Xarrow from "react-xarrows";
import Modal from "react-modal";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";

const connectPointStyle = {
  position: "absolute",
  width: 15,
  height: 15,
  borderRadius: "50%",
  background: "black",
};
const connectPointOffset = {
  middle: { right:"0", top: "50%", transform: "translate(-50%, -50%)" },
};

const ConnectPointsWrapper = ({ inputId, handler, ref0 }: any) => {
  const ref1 = useRef();
  const [position, setPosition] = useState<any>({});
  const [beingDragged, setBeingDragged] = useState(false);
  return (
    <>
      <div
        className="connectPoint"
        style={{
          ...connectPointStyle,
          ...(connectPointOffset as { [key: string]: React.CSSProperties })[handler],
          ...position,
        }}
        draggable
        onDragStart={(e) => {
          setBeingDragged(true);
          e.dataTransfer.setData("arrow", inputId);
        }}
        onDrag={(e) => {
          setPosition({
            position: "fixed",
            left: e.clientX,
            top: e.clientY,
            transform: "none",
            opacity: 0,
          });
        }}
        ref={ref1 as any}
        onDragEnd={(e) => {
          setPosition({});
          setBeingDragged(false);
        }}
      />
      {beingDragged ? <Xarrow start={ref0} end={ref1} /> : null}
    </>
  );
};

const inputWrapperStyle = {
  border: "1px solid transparent",
  position: "relative",
  padding: "10px",
  marginBottom: "15px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  borderRadius: "5px"
};

const InputWrapper = ({
  text,
  handler,
  addArrow,
  inputId,
}: {
  text: any;
  handler: any;
  addArrow: any;
  inputId: any;
}) => {
  const ref0 = useRef();
  return (
    <div
      id={inputId}
      style={inputWrapperStyle as any}
      ref={ref0 as any}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        if (e.dataTransfer.getData("arrow") === inputId) {
          console.log(e.dataTransfer.getData("arrow"), inputId);
        } else {
          const refs = { start: e.dataTransfer.getData("arrow"), end: inputId };
          addArrow(refs);
          console.log("dropped!", refs);
        }
      }}
    >
      <input type="text" placeholder={text} style={{border:"none", fontSize:"16px"}} />
      <ConnectPointsWrapper {...{ inputId, handler, ref0 }} />
    </div>
  );
};

const MappingForm = ({
  subCategories,
  loading,
  setFieldValue,
  values,
  isEdit,
  idofMapping,
}: {
  subCategories: any[];
  loading: boolean;
  setFieldValue: Function;
  values: any;
  isEdit: boolean;
  idofMapping?: string;
}) => {
  const [arrows, setArrows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productSubcategory, setProductSubcatgory] = useState<any[]>([]);
  const [accessorySubcategory, setAccessorySubcatgory] = useState<any[]>([]);
  const [currentConnection, setCurrentConnection] = useState<any>(null);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const addArrow = async ({ start, end }: { start: any; end: any }) => {
    if (arrows.some((arrow) => arrow.start === start || arrow.end === end)) {
      toast.error("Starting/Ending Attribute already mapped");
      return;
    }
    const newArrow = { start, end, color: getRandomColor() };
    let tempArrowConnections = [...values.connections];
    tempArrowConnections.push(newArrow);
    await setFieldValue("connections", tempArrowConnections);
    setArrows([...arrows, newArrow]);
    setCurrentConnection(newArrow);
    setIsModalOpen(true);
  };

  const removeArrow = async (arrowIndex: number) => {
    const updatedArrows = arrows.filter((_, index) => index !== arrowIndex);
    setArrows(updatedArrows);
    await setFieldValue("connections", updatedArrows);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentConnection(null);
  };

  useEffect(() => {
    isEdit && setArrows(values.connections);
  }, [values, isEdit]);

  // const leftInputs = ["Sceen Size", "Charging required", "Usb ports"];
  // const rightInputs = ["display Diagonal", "no of ports", "charging capacity"];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom:"20px" }}>
        <div onClick={() => console.log("here")}>
          <Multiselect

            options={subCategories}
            disable={loading || isEdit}
            selectionLimit={1}
            selectedValues={
              Object.values(values?.productSubcategory)?.length > 0
                ? [values?.productSubcategory]
                : []
            }
            displayValue={"subcategoryName"}
            placeholder="Select Product Subcategory"
            onSelect={async (e) => {
              await setFieldValue("productSubcategory", e?.[0]);
            }}
            onRemove={async (e) => {}}
          />
        </div>
        <div onClick={() => console.log("here")}>
          <Multiselect
            options={subCategories}
            disable={loading || isEdit}
            selectionLimit={1}
            selectedValues={
              Object.values(values?.accessorySubcategory).length > 0
                ? [values?.accessorySubcategory]
                : []
            }
            displayValue={"subcategoryName"}
            placeholder="Select Accessory Subcategory"
            onSelect={async (e) => {
              await setFieldValue("accessorySubcategory", e?.[0]);
            }}
            onRemove={async (e) => {}}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div>
          {values?.productSubcategory?.supportedAttributes?.map(
            (attribute: any, index: number) => (
              <InputWrapper
                key={attribute?._id + values?.productSubcategory?._id}
                text={attribute?.attributeName}
                {...{
                  addArrow,
                  handler: "middle",
                  inputId: `${
                    attribute?._id +
                    values?.productSubcategory?._id +
                    (isEdit ? idofMapping + "edit" : "")
                  }`,
                }}
              />
            )
          )}
        </div>
        <div>
          {values?.accessorySubcategory?.supportedAttributes?.map(
            (attribute: any, index: number) => (
              <InputWrapper
                key={attribute?._id + values?.accessorySubcategory?._id}
                text={attribute?.attributeName}
                {...{
                  addArrow,
                  handler: "middle",
                  inputId: `${
                    attribute?._id +
                    values?.accessorySubcategory?._id +
                    (isEdit ? idofMapping + "edit" : "")
                  }`,
                }}
              />
            )
          )}
        </div>
        {arrows?.map((ar, index) => (
          <Xarrow
            start={ar.start}
            end={ar.end}
            key={`arrow-${index}`}
            color={ar.color}
            labels="test"
            animateDrawing={true}
            passProps={{
              onClick: () => removeArrow(index),
              cursor: "pointer",
            }}
          />
        ))}
        {/* <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Connection Details"
        >
          <h2>Connection Details</h2>
          {currentConnection && (
            <div>
              <p>Start: {currentConnection.start}</p>
              <p>End: {currentConnection.end}</p>
              <p>
                Color:{" "}
                <span style={{ color: currentConnection.color }}>
                  {currentConnection.color}
                </span>
              </p>
            </div>
          )}
          <button onClick={closeModal}>Close</button>
        </Modal> */}
      </div>
    </>
  );
};

export default MappingForm;
