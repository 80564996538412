import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  createUnit,
  getAllUnit,
  getSingleUnit,
  updateUnit,
} from "../app/request/unitRequest";
import { getRegion } from "../app/request/regionRequest";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import { toast } from "react-toastify";
import { unitSchema } from "../utils/validationSchema/unitSchema";
import UnitForm from "../generatedForms/UnitForm";
import "./UnitFormModal.css";
import {
  TPayloadSubmit,
  TRegions,
  TUnitConfig,
  TUnitFormData,
  TUserData,
} from "../types/Module/Unit/Unit";
import { TagBoxItem } from "../components/TagBox";

// Define a function to convert supported values between tag box format and API format
const convertToAPIFormat = (supportedValues: TagBoxItem[]) => {
  return supportedValues.map((supportedValue) => ({
    value: supportedValue.name,
 
  }));
};

const convertToTagBoxFormat = (
  supportedValues: { value: string; id: string }[]
) => {
  return supportedValues.map(
    (supportedValue: { value: string; id: string }) => ({
      name: supportedValue.value,
      id: supportedValue.id,
    })
  );
};

const UnitFormModal = ({ isEdit }: TUnitConfig) => {
  const [queryParameters] = useSearchParams();
  const unitID = queryParameters.get("q") || "";
  const [loading, setLoading] = useState(false);
  const [predefinedUnit, setPredefinedUnits] = useState([]);
  const [activeRegions, setActiveRegions] = useState([]);
  const [initload, setInitload] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    regions: [] as string[],
    supportedValues: [] as TagBoxItem[],
    status: { value: "active", label: "Active" },
    unitType: "",
    symbol: "",
  });
  const [userData, setUserData] = useState<TUserData | undefined>();
  const navigate = useNavigate();

  const handleSubmit = async (payload: TPayloadSubmit) => {
    setLoading(true);
    try {
      const response = isEdit
        ? await updateUnit(payload, unitID)
        : await createUnit(payload);
      if (response.data.status) {
        isEdit
          ? toast.success("Unit is edited Successfully")
          : toast.success("Unit is Added Successfully");
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
      !isEdit && formik.resetForm();
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setInitload(true);
      try {
        const response = await getSingleUnit(unitID); // Call your API to get user data
        if (response.data.status) {
          setUserData(response.data.data); // Save the data from the API in state
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchUserData();
    }
  }, [isEdit, unitID]);

  // Update initialValues with data from the API if available
  useEffect(() => {
    if (userData && isEdit) {
      setInitialValues((prevState) => ({
        ...prevState,
        name: userData?.name,
        status: userData?.status,
        regions: userData?.regions,
        supportedValues: convertToTagBoxFormat(userData?.supportedValues),
        unitType: userData?.unitType,
        symbol: userData?.symbol,
      }));
    }
  }, [userData, isEdit]);

  useEffect(() => {
    if (isEdit) {
      formik.setValues(initialValues);
    }
  }, [initialValues, isEdit]);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getAllUnit();
        if (resp.data.status) {
          setPredefinedUnits(resp.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getRegion(`page=1&limit=100`);

        if (resp.data.status) {
          setActiveRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  const formik = useFormik<TUnitFormData>({
    initialValues,
    validationSchema: unitSchema,
    onSubmit: async (values) => {
      const payload = {
        name: values.name,
        status:
          typeof values.status === "string"
            ? values.status
            : values.status.value,
        unitType: values.unitType,
        symbol: values.symbol,
        regions: values.regions.map((region: string | TRegions) =>
          typeof region === "string" ? region : region.id || region._id
        ),
        supportedValues: convertToAPIFormat(values.supportedValues),
      };

      handleSubmit(payload);
    },
  });

  if (loading && initload) {
    return <FormSkeleton numberOfInput={10} />;
  }

  return (
    <div className="card p-5 max-width-800">
      <div className="card-header p-0 border-1 cursor-pointer">
        <div className="card-title m-0">
          <div
            role={"button"}
            className="btn btn-sm btn-light me-5"
            onClick={() => navigate("/unit")}
          >
            <i className="fa-solid fa-arrow-left fs-2"></i>
          </div>
          <h3 className="fw-bolder m-0">{isEdit ? "Edit" : "Add"} Units</h3>
        </div>
      </div>

      <UnitForm
        activeRegions={activeRegions}
        predefinedUnit={predefinedUnit}
        {...formik}
        isloading={loading}
      />

      {/* begin::Action */}
      <div className="d-flex flex-column flex-md-row mt-5 mb-3 w-100 w-md-100 justify-content-end">
        <button
          onClick={() => formik.handleSubmit()}
          className="btn btn-primary"
          disabled={loading || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">{isEdit ? "Save" : "Save"}</span>
          )}
          {loading && (
            <span className="indicator-progress display-block">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </div>
  );
};

export default UnitFormModal;
