import React from "react";
import { TTableBodyProps } from "../../types/TableView";
import TableRow from "./TableRow";
import Loader from "../Loader/Loader";

const TableBody = ({ rows, columns, searchComponent, searchLoading }: TTableBodyProps) => {
  return (
    <tbody>
      {rows && rows.length > 0 && searchLoading && <Loader rows={rows} />}
      {rows && rows.length === 0 ? (
        <tr>
          <td colSpan={columns.length}>{searchComponent}</td>
        </tr>
      ) : (
        rows?.map((row: any, index: number) => (
          <tr key={index}>
            <TableRow row={row} columns={columns} />
          </tr>
        ))
      )}
    </tbody>
  );
};

export default TableBody;
